<template>
  <el-row :gutter="20">
    <el-col :span="11">
      <el-card class="user-card" shadow="never">
        <div slot="header">
          <span>1. User</span>
        </div>
        <user-selection :id="currentUserId" :orgId="currentOrgId" :useOrg="true" @select="selectUserId" />
      </el-card>
    </el-col>
    <el-col :span="13">
      <el-row v-loading="brandsLoading">
        <el-card class="user-card" shadow="never">
          <div class="user-brand-card-header" slot="header">
            <span>2. User Brands</span>
            <el-button :disabled="currentUserId === 0 && currentOrgId === 0" type="primary" size="small"
              @click="saveUserBrand" icon="el-icon-check">Save</el-button>
          </div>
          <span v-if="currentUserId === 0 && currentOrgId === 0">Please select user or organization first!</span>
          <el-collapse v-if="currentUserId !== 0 || currentOrgId !== 0" :value="['1', '2']">
            <el-collapse-item title="Subjects" name="1">
              <el-form ref="form" :model="subjectsData" size="small" label-width="auto" label-position="left">
                <el-form-item label="Best10" prop="code">
                  <el-select v-model="subjectsData.best10" filterable multiple style="width: 100%">
                    <el-option v-for="item in brandList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="Trend Map" prop="code">
                  <el-select v-model="subjectsData.tps" filterable multiple style="width: 100%">
                    <el-option v-for="item in brandList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="Basic Data" prop="code">
                  <el-select v-model="subjectsData.image" filterable multiple style="width: 100%">
                    <el-option v-for="item in brandList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                  </el-select>
                </el-form-item>
              </el-form>
            </el-collapse-item>
            <el-collapse-item title="Types" name="2">
              <el-row class="types-row" v-for="(item, index) of typesData" :key="index" :gutter="10">
                <el-col class="types-row-label" :span="1">
                  <el-button icon="el-icon-minus" size="mini" type="danger" plain circle
                    style="margin-top: 2px; display: block" @click="removeTypes(index)"></el-button>
                </el-col>
                <el-col class="types-row-label" :span="2">Type</el-col>
                <el-col :span="5">
                  <el-input placeholder="Type" v-model="item.type" size="small"></el-input>
                </el-col>
                <el-col class="types-row-label" :span="2">Primary</el-col>
                <el-col class="types-row-select" :span="2">
                  <el-radio v-model="item.primary" :label="true" @change="selectPrimary(index)">{{ "" }}</el-radio>
                </el-col>
                <el-col class="types-row-label" :span="2">Brands</el-col>
                <el-col :span="10">
                  <el-select v-model="item.brands" filterable multiple size="small" style="width: 100%">
                    <el-option v-for="t in brandList" :key="index + '-' + t.id" :label="t.name"
                      :value="t.id"></el-option>
                  </el-select>
                </el-col>
              </el-row>
              <el-row class="types-row" key="plus">
                <el-col class="types-row-label" :span="1">
                  <el-button icon="el-icon-plus" size="mini" type="primary" plain circle
                    style="margin-top: 2px; display: block" @click="addTypes"></el-button>
                </el-col>
              </el-row>
            </el-collapse-item>
          </el-collapse>
        </el-card>
      </el-row>
      <el-row v-loading="itemsLoading">
        <el-card class="user-card" shadow="never">
          <div class="user-brand-card-header" slot="header">
            <span>3. User Items</span>
            <el-button :disabled="currentUserId === 0 && currentOrgId === 0" type="primary" size="small"
              @click="saveUserItem" icon="el-icon-check">Save</el-button>
          </div>
          <span v-if="currentUserId === 0 && currentOrgId === 0">Please select user or organization first!</span>
          <el-form v-if="currentUserId !== 0 || currentOrgId !== 0" ref="form" size="small" label-width="auto"
            label-position="left">
            <el-form-item label="Item">
              <el-radio-group v-model="itemType">
                <el-radio-button label="custom">Custom</el-radio-button>
                <el-radio-button label="rank">Rank</el-radio-button>
              </el-radio-group>
              <br />
            </el-form-item>
            <el-form-item label="Rank" v-if="itemType === 'rank'">
              <el-input-number v-model="itemRank" controls-position="right" :min="1" :max="30"></el-input-number>
            </el-form-item>
            <el-form-item label="Custom" v-else>
              <el-select v-model="itemList" filterable multiple style="width: 100%">
                <el-option v-for="item in t2List" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </el-card>
      </el-row>
      <el-row v-loading="nlpLoading">
        <el-card class="user-card" shadow="never">
          <div class="user-brand-card-header" slot="header">
            <span>4. NLP</span>
            <el-button :disabled="currentUserId === 0 && currentOrgId === 0" type="primary" size="small"
              @click="saveNlpAnalysisUser" icon="el-icon-check">Save</el-button>
          </div>
          <span v-if="currentUserId === 0 && currentOrgId === 0">Please select user or organization first!</span>
          <el-form v-if="currentUserId !== 0 || currentOrgId !== 0" ref="form" size="small" label-width="auto"
            label-position="left">
            <el-form-item label="Bussiness Type">
              <el-select v-model="businessTypes" filterable multiple style="width: 100%">
                <el-option v-for="item in businessTypeList" :key="item" :label="item" :value="item"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </el-card>
      </el-row>
      <el-row v-loading="globalTrendsLoading">
        <el-card class="user-card" shadow="never">
          <div class="user-brand-card-header" slot="header">
            <span>5. GlobalTrend</span>
            <el-button :disabled="currentUserId === 0" type="primary" size="small" @click="saveGlobalTrendsUser"
              icon="el-icon-check">Save</el-button>
          </div>
          <span v-if="currentUserId === 0 && currentOrgId === 0">Please select user or organization first!</span>
          <el-form v-if="currentUserId !== 0 || currentOrgId !== 0" ref="form" size="small" label-width="auto"
            label-position="left">
            <el-transfer ref="globalTrendTransfer" filterable v-model="selectedGlobalTrends" :data="globalTrendList"
              :titles="['Available', 'Select']">
            </el-transfer>
          </el-form>
        </el-card>
      </el-row>
    </el-col>
  </el-row>
</template>

<script>
import UserSelection from "./UserSelection.vue";
import {
  fetchBrand,
  fetchUserBrand,
  updateUserBrand,
  fetchUserItem,
  updateUserItem,
  fetchUserBrandOrg,
  updateUserBrandOrg,
  fetchUserItemOrg,
  updateUserItemOrg,
  fetchUserNlp,
  updateUserNlp,
  fetchUserGlobalTrend,
  updateUserGlobalTrend,
} from "@/api/admin";

export default {
  components: { UserSelection },
  data() {
    return {
      currentUserId: 0,
      currentOrgId: 0,
      subjectsData: {
        best10: [],
        tps: [],
        image: [],
      },
      typesData: [],
      brandList: [],
      t2List: [],
      itemType: "custom", // rank
      itemList: [],
      itemRank: 0,
      businessTypeList: ["W", "M", "C", "U", "I", "S", "B", "L"],
      businessTypes: [],
      brandsLoading: false,
      itemsLoading: false,
      nlpLoading: false,
      globalTrendsLoading: false,
      selectedGlobalTrends: [],
      globalTrendList: [],
      originalGlobalTrendList: []
    };
  },
  methods: {
    fetchUserBrand() {
      this.brandsLoading = true;
      this.typesData = [];
      this.subjectsData = {
        best10: [],
        tps: [],
        image: [],
      };
      let handleResult = (result) => {
        result &&
          result.items &&
          result.items.forEach((t) => {
            if (t.types && t.types.length > 0) {
              t.types.forEach((type) => {
                let match = false;
                this.typesData.forEach((td) => {
                  if (td.type === type.type) {
                    td.brands.push(t.brandId);
                    match = true;
                    return;
                  }
                });
                if (!match) {
                  this.typesData.push({
                    type: type.type,
                    primary: type.primary,
                    brands: [t.brandId],
                  });
                }
              });
            }
            if (t.subjects && t.subjects.length > 0) {
              t.subjects.forEach((s) => {
                if (s === "Best10") {
                  this.subjectsData.best10.push(t.brandId);
                } else if (s === "TPS") {
                  this.subjectsData.tps.push(t.brandId);
                } else if (s === "Image") {
                  this.subjectsData.image.push(t.brandId);
                }
              });
            }
          });
      };
      if (this.currentUserId > 0) {
        fetchUserBrand(this.currentUserId)
          .then((response) => {
            if (response.success) {
              handleResult(response.result);
            }
          })
          .finally(() => {
            this.brandsLoading = false;
          });
      } else if (this.currentOrgId > 0) {
        fetchUserBrandOrg(this.currentOrgId)
          .then((response) => {
            if (response.success) {
              handleResult(response.result);
            }
          })
          .finally(() => {
            this.brandsLoading = false;
          });
      }
    },
    fetchUserItem() {
      this.itemsLoading = true;
      this.itemList = [];
      this.itemRank = 0;
      let handleResult = (result) => {
        this.itemType = result.type;
        if (this.itemType === "rank") {
          this.itemRank = parseInt(result.value);
        } else {
          this.itemList = result.value.split(",");
        }
      };
      if (this.currentUserId > 0) {
        fetchUserItem(this.currentUserId)
          .then((response) => {
            if (response.success && response.result) {
              handleResult(response.result);
            }
          })
          .finally(() => {
            this.itemsLoading = false;
          });
      } else if (this.currentOrgId > 0) {
        fetchUserItemOrg(this.currentOrgId)
          .then((response) => {
            if (response.success && response.result) {
              handleResult(response.result);
            }
          })
          .finally(() => {
            this.itemsLoading = false;
          });
      }
    },
    fetchNlpAnalysisUser() {
      this.nlpLoading = true;
      this.businessTypes = [];
      fetchUserNlp(this.currentUserId)
        .then((response) => {
          if (response.success) {
            this.businessTypes = response.result;
          }
        })
        .finally(() => {
          this.nlpLoading = false;
        });
    },
    active() {
      this.$store.dispatch("category/fresh").then(() => {
        this.t2List = this.$store.getters.t2AdditionalFlatten;
        this.fetchBrandList();
      });
    },
    saveUserBrand() {
      let body = [];
      this.typesData.forEach((td) => {
        if (!td.type || !td.brands) {
          return;
        }
        td.brands.forEach((tb) => {
          let match = false;
          body.forEach((v) => {
            if (v.brandId === tb) {
              match = true;
              v.types.push({
                type: td.type,
                primary: td.primary,
              });
              return;
            }
          });
          if (!match) {
            body.push({
              brandId: tb,
              types: [
                {
                  type: td.type,
                  primary: td.primary,
                },
              ],
              subjects: [],
            });
          }
        });
      });
      let processSubjects = (o, name) => {
        o.forEach((t) => {
          let match = false;
          body.forEach((ub) => {
            if (ub.brandId === t) {
              ub.subjects.push(name);
              match = true;
            }
          });
          if (!match) {
            body.push({
              brandId: t,
              subjects: [name],
            });
          }
        });
      };
      processSubjects(this.subjectsData.best10, "Best10");
      processSubjects(this.subjectsData.tps, "TPS");
      processSubjects(this.subjectsData.image, "Image");
      if (this.currentUserId > 0) {
        this.brandsLoading = true;
        updateUserBrand(this.currentUserId, { userBrands: body })
          .then((response) => {
            if (response.success) {
              this.$notify({
                title: "Update successful",
                type: "success",
              });
              this.fetchUserBrand();
            } else {
              this.brandsLoading = false;
            }
          })
          .catch(() => {
            this.brandsLoading = false;
          });
      } else if (this.currentOrgId > 0) {
        let applyAll = false;
        this.$confirm("Apply to all users in this organization ?", "Prompt", {
          type: "info",
          cancelButtonText: "No",
          confirmButtonText: "Yes",
        })
          .then(() => {
            applyAll = true;
          })
          .catch(() => { })
          .finally(() => {
            this.brandsLoading = true;
            updateUserBrandOrg(this.currentOrgId, {
              userBrands: body,
              applyAll,
            })
              .then((response) => {
                if (response.success) {
                  this.$notify({
                    title: "Update successful",
                    type: "success",
                  });
                  this.fetchUserBrand();
                } else {
                  this.brandsLoading = false;
                }
              })
              .catch(() => {
                this.brandsLoading = false;
              });
          });
      }
    },
    saveUserItem() {
      let p = { type: this.itemType };
      if (this.itemType === "rank") {
        p.value = this.itemRank.toString();
      } else {
        p.value =
          this.itemList && this.itemList.length > 0
            ? this.itemList.join(",")
            : "";
      }
      if (this.currentUserId > 0) {
        this.itemsLoading = true;
        updateUserItem(this.currentUserId, { userItem: p })
          .then((response) => {
            if (response.success) {
              this.$notify({
                title: "Update successful",
                type: "success",
              });
              this.fetchUserItem();
            } else {
              this.itemsLoading = false;
            }
          })
          .catch(() => {
            this.itemsLoading = false;
          });
      } else if (this.currentOrgId > 0) {
        let applyAll = false;
        this.$confirm("Apply to all users in this organization ?", "Prompt", {
          type: "info",
          cancelButtonText: "No",
          confirmButtonText: "Yes",
        })
          .then(() => {
            applyAll = true;
          })
          .catch(() => { })
          .finally(() => {
            this.itemsLoading = true;
            updateUserItemOrg(this.currentOrgId, {
              userItem: p,
              applyAll,
            })
              .then((response) => {
                if (response.success) {
                  this.$notify({
                    title: "Update successful",
                    type: "success",
                  });
                  this.fetchUserItem();
                } else {
                  this.itemsLoading = false;
                }
              })
              .catch(() => {
                this.itemsLoading = false;
              });
          });
      }
    },
    saveNlpAnalysisUser() {
      this.nlpLoading = true;
      updateUserNlp(this.currentUserId, { businessTypes: this.businessTypes })
        .then((response) => {
          if (response.success) {
            this.$notify({
              title: "Update successful",
              type: "success",
            });
            this.fetchNlpAnalysisUser();
          } else {
            this.nlpLoading = false;
          }
        })
        .catch(() => {
          this.nlpLoading = false;
        });
    },
    fetchGlobalTrendsUser() {
      this.globalTrendsLoading = true;
      this.globalTrendList = [];
      this.originalGlobalTrendList = [];
      fetchUserGlobalTrend(this.currentUserId)
        .then((response) => {
          if (response.success) {
            this.originalGlobalTrendList = response.result ? response.result : [];
            this.globalTrendList = this.originalGlobalTrendList.map(e => {
              return {
                key: e.year + e.season + "_" + e.sex,
                label: e.year + e.season + "_" + e.sex,
              }
            });
            this.selectedGlobalTrends = this.originalGlobalTrendList.
              filter(e => e.selected).map(e => {
                return e.year + e.season + "_" + e.sex;
              });
          }
        })
        .finally(() => {
          this.globalTrendsLoading = false;
        });
    },
    saveGlobalTrendsUser() {
      this.globalTrendsLoading = true;
      let values = this.selectedGlobalTrends.map(name => {
        for (let i = 0; i < this.originalGlobalTrendList.length; i++) {
          const e = this.originalGlobalTrendList[i];
          let currentName = e.year + e.season + "_" + e.sex;
          if (currentName === name) {
            return {
              year: e.year,
              season: e.season,
              sex: e.sex,
            };
          } else {
            this.globalTrendsLoading = false;
          }
        }
      });
      updateUserGlobalTrend(this.currentUserId, { "items": values }).then(response => {
        if (response.success) {
          this.$notify({
            title: "Update successful",
            type: "success",
          });
          this.fetchGlobalTrendsUser();
        }
      }).catch(() => {
        this.globalTrendsLoading = false;
      });
    },
    fetchBrandList() {
      fetchBrand({ limit: -1 }).then((response) => {
        if (response.success) {
          this.brandList = response.result.items;
        }
      });
    },
    selectUserId(userId, orgId) {
      this.currentUserId = userId;
      this.currentOrgId = orgId;
      this.total = 0;
      if (userId > 0 || orgId > 0) {
        this.fetchUserBrand();
        this.fetchUserItem();
        this.fetchNlpAnalysisUser();
        this.fetchGlobalTrendsUser();
        let gt = this.$refs.globalTrendTransfer;
        if (gt) {
          gt.clearQuery();
        }
      }
    },
    addTypes() {
      this.typesData.push({ type: "", brands: [] });
    },
    removeTypes(index) {
      this.typesData.splice(index, 1);
    },
    selectPrimary(index) {
      this.typesData.forEach((_, i) => {
        if (i !== index) {
          this.typesData[i].primary = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.user-card {
  margin-bottom: 10px;

  .user-brand-card-header .el-button {
    margin-left: 10px;
    float: right;
    margin-top: -7px;
  }

  .edit-tools {
    margin-left: 5px;
  }

  .types-row {
    line-height: 32px;
    margin-bottom: 10px;

    .types-row-label {
      text-align: right;
      padding-right: 12px;
      font-weight: bold;
      vertical-align: middle;
    }

    .types-row-select {
      margin-left: 10px;
      margin-right: -10px;
    }
  }
}

.sync-tools {
  padding: 10px 0;
  line-height: 20px;
  float: right;

  span {
    margin-right: 20px;
    vertical-align: middle;
    font-weight: bold;
  }
}
</style>