<template>
  <div
    class="nlp-analysis"
    v-loading.fullscreen.lock="loading"
    v-loading="dataLoading"
  >
    <div class="toolbar">
      <div class="new-btn">
        <el-button
          icon="el-icon-plus"
          type="primary"
          size="small"
          @click="dialogNewVisible = true"
          >Import</el-button
        >
      </div>
      <div class="search-bar">
        <el-select
          v-model="listQuery.source"
          size="small"
          :placeholder="$t('common.all')"
          clearable
        >
          <el-option label="SW" value="SW"></el-option>
          <el-option label="PN" value="PN"></el-option>
          <el-option label="PE" value="PE"></el-option>
          <el-option label="PR" value="PR"></el-option>
          <el-option label="SN" value="SN"></el-option>
        </el-select>
        <el-input v-model="listQuery.q" size="small">
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="fetchData"
          ></el-button>
        </el-input>
      </div>
    </div>
    <el-table :data="tableData" row-key="id">
      <el-table-column
        prop="date"
        label="Date"
        min-width="65"
      ></el-table-column>
      <el-table-column
        prop="source"
        label="Source"
        min-width="65"
      ></el-table-column>
      <el-table-column
        prop="rows"
        label="Rows"
        min-width="65"
      ></el-table-column>
      <el-table-column label="Create At" min-width="140">
        <template slot-scope="scope">
          <i class="el-icon-time"></i>
          <span style="margin-left: 10px">{{
            scope.row.createdAt | displayTime()
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Update At" min-width="140">
        <template slot-scope="scope">
          <i class="el-icon-time"></i>
          <span style="margin-left: 10px">{{
            scope.row.updatedAt | displayTime()
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Download" min-width="55" align="center">
        <template slot-scope="scope">
          <a :href="scope.row.fileUrl | ossUrl">
            <el-button
              :disabled="scope.row.fileUrl === ''"
              class="excel-dl-btn"
              icon="el-icon-download"
              circle
              size="small"
            ></el-button>
          </a>
        </template>
      </el-table-column>
      <el-table-column label="Delete" min-width="55" align="center">
        <template slot-scope="scope">
          <el-popconfirm
            title="Confirm delete?"
            @confirm="deleteAll(scope.row.id)"
          >
            <el-button
              type="danger"
              icon="el-icon-delete"
              circle
              size="small"
              slot="reference"
            ></el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-show="total > 0"
      :total="total"
      :offset.sync="listQuery.offset"
      :limit.sync="listQuery.limit"
      layout="sizes, prev, pager, next, total "
      @pagination="fetchData"
    />
    <el-dialog
      :visible.sync="dialogNewVisible"
      @close="closeDialog"
      title="Create NLP Analysis Report"
      class="new-dialog"
    >
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="120px"
        size="small"
      >
        <el-form-item label="Date" prop="date" required>
          <el-date-picker v-model="form.date" type="date"></el-date-picker>
        </el-form-item>
        <el-form-item label="Source" prop="source" required>
          <el-select v-model="form.source" size="small">
            <el-option label="SW" value="SW"></el-option>
            <el-option label="PN" value="PN"></el-option>
            <el-option label="PE" value="PE"></el-option>
            <el-option label="PR" value="PR"></el-option>
            <el-option label="SN" value="SN"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Upload Excel" required>
          <el-upload
            :data="{
              date: formatDate,
              source: form.source,
            }"
            class="upload-btn"
            :action="aiApiRoot + '/v1/admins/nlp-analysis'"
            accept=".csv, .xlsx, .xls"
            :headers="{ Authorization: this.token }"
            ref="upload"
            :auto-upload="false"
            :before-upload="beforeUpload"
            :on-error="onError"
            :on-success="onSuccess"
            :on-change="onChange"
            :before-remove="beforeRemove"
            :limit="1"
          >
            <el-button slot="trigger" icon="el-icon-upload2"
              >Click to Upload</el-button
            >
          </el-upload>
          <el-link href="/example-nlp-analysis-sw.xlsx">Example file</el-link>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" :disabled="!enableSubmit" @click="onSubmit"
            >Submit</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { fetchNlpAnalysis, deleteNLPAnalysis } from "@/api/admin";
import Pagination from "@/components/Pagination";
import { aiApiRoot } from "@/utils/server";
import { getToken } from "@/utils/auth";
import dayjs from "dayjs";

export default {
  components: { Pagination },
  data() {
    return {
      token: "Bearer " + getToken(),
      aiApiRoot,
      dialogNewVisible: false,
      form: {
        date: "",
        source: "SW",
      },
      rules: {
        date: [{ required: true, trigger: "change" }],
      },
      loading: false,
      dataLoading: false,
      enableSubmit: false,
      tableData: [],
      listQuery: {
        q: "",
        source: "",
        offset: 0,
        limit: 10,
      },
      total: 0,
      timer: null,
    };
  },
  methods: {
    active() {
      this.fetchData();
    },
    closeDialog() {
      this.dialogNewVisible = false;
    },
    onSubmit() {
      this.enableSubmit = false;
      if (this.form.date) {
        this.$refs.upload.submit();
      }
    },
    onSuccess(response) {
      this.$refs.form.resetFields();
      this.$refs.upload.clearFiles();
      if (response.success) {
        this.timer = setTimeout(() => {
          this.fetchById(response.result);
        }, 2000);
      } else {
        this.$message({
          showClose: false,
          message: response && response.error ? response.error.message : "",
          type: "error",
        });
      }
      this.fetchData();
    },
    onError(err) {
      this.$refs.form.resetFields();
      this.$refs.upload.clearFiles();
      if (err && err.message) {
        let res = JSON.parse(err.message);
        if (res && res.error && res.error.message) {
          this.$message({
            showClose: false,
            message: res.error.message,
            type: "error",
          });
          return;
        }
      }
      this.$message({
        showClose: false,
        message: err,
        type: "error",
      });
    },
    onChange(file, fileList) {
      if (fileList && fileList.length > 0) {
        this.enableSubmit = true;
      } else {
        this.enableSubmit = false;
      }
    },
    beforeRemove() {
      this.enableSubmit = false;
      return true;
    },
    fetchById(id) {
      fetchNlpAnalysis({ id }).then((response) => {
        if (
          response.success &&
          response.result.items &&
          response.result.items[0].fileUrl
        ) {
          this.$refs.form.resetFields();
          clearTimeout(this.timer);
          this.dialogNewVisible = false;
          this.fetchData();
          this.loading = false;
        } else {
          this.timer = setTimeout(() => {
            this.fetchById(id);
          }, 2000);
        }
      });
    },
    beforeUpload() {
      this.loading = true;
      return true;
    },
    fetchData() {
      this.dataLoading = true;
      fetchNlpAnalysis(this.listQuery)
        .then((response) => {
          if (response.success) {
            this.tableData = response.result.items || [];
            this.total = response.result.totalCount;
          }
        })
        .finally(() => {
          this.dataLoading = false;
        });
    },
    deleteAll(id) {
      this.dataLoading = true;
      deleteNLPAnalysis(id)
        .then((response) => {
          this.dataLoading = false;
          if (response.success) {
            this.$notify({
              title: "Delete successful",
              type: "success",
            });
            this.fetchData();
          }
        })
        .catch(() => {
          this.dataLoading = false;
        });
    },
  },
  computed: {
    formatDate() {
      return dayjs(this.form.date).format("YYYYMMDD");
    },
  },
};
</script>

<style lang="scss" scoped>
.nlp-analysis {
  .toolbar {
    margin-bottom: 10px;
    text-align: right;

    .new-btn {
      float: left;
    }

    .search-bar {
      display: inline-block;

      .el-select {
        margin-right: 10px;
      }

      /deep/ .el-select .el-input {
        width: 125px;
      }

      .el-input {
        width: 320px;
      }
    }
  }
  .upload-btn {
    display: inline-block;
    margin-right: 20px;
  }
  /deep/ .new-dialog .el-form {
    margin-top: 10px;
  }
}
</style>