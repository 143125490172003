<template>
  <div class="container">
    <el-input
      class="search-input"
      size="small"
      v-model="listQuery.q"
      @keyup.enter.native="fetchData"
    >
      <el-button
        slot="append"
        class="el-icon-search"
        @click="fetchData"
      ></el-button>
    </el-input>
    <el-tabs v-if="useOrg" v-model="searchType">
      <el-tab-pane label="User" name="user"> </el-tab-pane>
      <el-tab-pane label="Organization" name="organization"> </el-tab-pane>
    </el-tabs>
    <el-table
      v-if="searchType === 'user'"
      ref="table"
      :data="tableData"
      row-key="id"
      highlight-current-row
      @current-change="handleCurrentChange"
      key="userTable"
    >
      <el-table-column width="40">
        <template slot-scope="scope">
          <el-radio
            v-model="id"
            @change="handleCurrentChange"
            :label="scope.row.id"
          ></el-radio>
        </template>
      </el-table-column>
      <el-table-column
        prop="userName"
        label="UserName"
        width="130"
      ></el-table-column>
      <el-table-column prop="email" label="Email"></el-table-column>
      <el-table-column v-if="!lite" label="Ready" width="70" align="center">
        <template slot-scope="scope">
          <div
            class="status-dot"
            :class="scope.row.analysisEnable ? 'success' : 'danger'"
            effect="dark"
          ></div>
        </template>
      </el-table-column>
    </el-table>
    <el-table
      v-else-if="searchType === 'organization'"
      ref="orgTable"
      :data="tableData"
      row-key="id"
      highlight-current-row
      @current-change="handleCurrentChange"
      key="orgTable"
    >
      <el-table-column width="40">
        <template slot-scope="scope">
          <el-radio
            v-model="orgId"
            @change="handleCurrentChange"
            :label="scope.row.id"
          ></el-radio>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="Name"></el-table-column>
      <el-table-column prop="userCnt" label="UserCount"></el-table-column>
    </el-table>
    <pagination
      v-show="total > 0"
      :total="total"
      :offset.sync="listQuery.offset"
      :limit.sync="listQuery.limit"
      :pagerCount="5"
      layout="prev, pager, next"
      @pagination="fetchData"
    />
  </div>
</template>

<script>
import { fetchUser, fetchOrganization } from "@/api/admin";
import Pagination from "@/components/Pagination";

export default {
  components: { Pagination },
  data() {
    return {
      tableData: [],
      listQuery: {
        q: "",
        offset: 0,
        limit: 5,
      },
      total: 0,
      searchType: "user",
    };
  },
  props: {
    id: {
      required: true,
      type: Number,
    },
    useOrg: {
      required: false,
      type: Boolean,
    },
    orgId: {
      required: false,
      type: Number,
    },
    lite: {
      required: false,
      type: Boolean,
    },
  },
  created() {
    this.fetchData();
  },
  watch: {
    id(val) {
      if (val === 0) {
        this.$refs.table.setCurrentRow();
      }
    },
    orgId(val) {
      if (val == 0) {
        this.$refs.orgTable.setCurrentRow();
      }
    },
    searchType() {
      this.$emit("select", 0, 0);
      this.listQuery = {
        q: "",
        offset: 0,
        limit: 5,
      };
      this.fetchData();
    },
  },
  methods: {
    fetchData() {
      if (this.searchType === "user") {
        fetchUser(this.listQuery).then((response) => {
          if (response.success) {
            this.tableData = response.result.items || [];
            this.total = response.result.totalCount;
            this.handleCurrentChange();
          }
        });
      } else {
        fetchOrganization(this.listQuery).then((response) => {
          if (response.success) {
            this.tableData = response.result.items || [];
            this.total = response.result.totalCount;
            this.handleCurrentChange();
          }
        });
      }
    },
    handleCurrentChange(val) {
      if (this.searchType === "user") {
        this.$emit("select", val ? val.id : 0, 0);
      } else {
        this.$emit("select", 0, val ? val.id : 0);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  /deep/ .el-radio__label {
    display: none;
  }

  .search-input {
    margin-top: 8px;
    float: right;
    width: 50%;
  }

  /deep/ .el-tabs__header {
    width: 40%;
  }

  .status-dot {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    display: inline-block;
  }
  .status-dot.success {
    background-color: #67c23a;
  }
  .status-dot.danger {
    background-color: #f56c6c;
  }
}
</style>