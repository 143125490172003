<template>
  <div class="gpt-designer" v-loading="dataLoading">
    <el-card class="gpt-designer-card" shadow="never">
      <div slot="header" class="clearfix">
        <span>Prompt</span>
        <el-button class="top-botton" type="primary" size="small" icon="el-icon-check"
          @click="setSettings">Save</el-button>
        <el-button class="top-botton top-next-botton" type="" size="small" icon="el-icon-refresh"
          @click="fetchSettings">Fresh</el-button>
      </div>
      <el-form class="gpt-form" label-width="120px" size="mini" style="margin-bottom: 20px;">
        <el-form-item label="Basic">
          <el-input type="textarea" v-model="form.basic.value" autosize></el-input>
        </el-form-item>
        <el-form-item label="Negative">
          <el-input type="textarea" v-model="form.negative.value" autosize></el-input>
        </el-form-item>
        <el-form-item class="no-bottom-margin" label="Sex">
          <el-button icon="el-icon-plus" size="mini" type="primary" plain circle class="add-botton"
            @click="addKey('deSex')">
          </el-button>
          <el-form-item v-for="item in form.sex" :key="item.key + item.subKey">
            <el-col :span="1">
              <el-button icon="el-icon-minus" size="mini" type="danger" plain circle style="display: block"
                @click="removeOption({ 'type': 'deSex', key: item.key, subKey: item.subKey, thirdKey: item.thirdKey, fourthKey: item.fourthKey })">
              </el-button>
            </el-col>
            <el-col class="row-input" :span="23">
              <el-input v-model="item.value">
                <template slot="prepend">{{ item.key }}</template>
              </el-input>
            </el-col>
          </el-form-item>
        </el-form-item>
        <el-form-item class="no-bottom-margin" label="Age">
          <el-button icon="el-icon-plus" size="mini" type="primary" plain circle class="add-botton"
            @click="addKey('deAge')">
          </el-button>
          <el-form-item v-for="item in form.age" :key="item.key + item.subKey">
            <el-col :span="1">
              <el-button icon="el-icon-minus" size="mini" type="danger" plain circle style="display: block"
                @click="removeOption({ 'type': 'deAge', key: item.key, subKey: item.subKey, thirdKey: item.thirdKey, fourthKey: item.fourthKey })">
              </el-button>
            </el-col>
            <el-col class="row-input" :span="23">
              <el-input v-model="item.value">
                <template slot="prepend">{{ item.key + " - " + item.subKey }}</template>
              </el-input>
            </el-col>
          </el-form-item>
        </el-form-item>
        <el-form-item class="no-bottom-margin" label="Season">
          <el-button icon="el-icon-plus" size="mini" type="primary" plain circle class="add-botton"
            @click="addKey('deSeason')">
          </el-button>
          <el-form-item v-for="item in form.season" :key="item.key">
            <el-col :span="1">
              <el-button icon="el-icon-minus" size="mini" type="danger" plain circle style="display: block"
                @click="removeOption({ 'type': 'deSeason', key: item.key, subKey: item.subKey, thirdKey: item.thirdKey, fourthKey: item.fourthKey })">
              </el-button>
            </el-col>
            <el-col class="row-input" :span="23">
              <el-input v-model="item.value">
                <template slot="prepend">{{ item.key }}
                </template>
              </el-input>
            </el-col>
          </el-form-item>
        </el-form-item>
      </el-form>
      <el-col :span="24">
        <el-tabs type="border-card" v-model="tabIdx">
          <el-tab-pane v-for="(item, idx) in form.t2" :key="'tab-' + item.key" :label="item.label"
            :name="idx.toString()">
          </el-tab-pane>
          <el-form v-if="form.t2.length > 0" class="gpt-form" label-width="120px" size="mini">
            <el-form-item class="no-bottom-margin" label="Item">
              <el-form-item :key="form.t2[currentIdx].label">
                <el-input v-model="form.t2[currentIdx].value">
                  <template slot="prepend">{{
    form.t2[currentIdx].label
  }}</template>
                </el-input>
              </el-form-item>
            </el-form-item>
            <el-form-item class="no-bottom-margin" label="Specific Item">
              <el-form-item v-for="t3 in form.t2[currentIdx].t3" :key="t3.label">
                <el-input v-model="t3.value">
                  <template slot="prepend">{{
    t3.label
  }}</template>
                </el-input>
              </el-form-item>
            </el-form-item>
            <el-form-item class="no-bottom-margin" label="Trend Point">
              <el-form-item v-for="trendPoint in form.t2[currentIdx].trendPoint" :key="trendPoint.label">
                <el-input v-model="trendPoint.value">
                  <template slot="prepend">{{
                    trendPoint.label
                    }}</template>
                </el-input>
              </el-form-item>
            </el-form-item>
          </el-form>
        </el-tabs>
      </el-col>
    </el-card>
  </div>
</template>

<script>
import { fetchGptSettings, setGptSettings, fetchTaggerOptions, addGptSettingsType, deleteGptSettingsType } from "@/api/admin";

export default {
  data() {
    return {
      tabIdx: "0",
      dataLoading: false,
      form: {
        basic: { type: "deBasic", value: "" },
        negative: { type: "deNegative", value: "" },
        sex: [],
        age: [],
        t2: [],
        season: [],
      },
    };
  },
  created() {
    this.clearForm();
  },
  methods: {
    clearForm() {
      this.form.basic = { type: "deBasic", value: "" };
      this.form.negative = { type: "deNegative", value: "" };
      this.form.sex = [];
      this.form.age = [];
      this.form.season = [];
    },
    initOptions() {
      this.form.t2 = [];
      fetchTaggerOptions().then((response) => {
        if (response.success && response.result.items) {
          this.$store.dispatch("category/fresh").then(() => {
            let t2Flatten = this.$store.getters.t2Flatten;
            t2Flatten &&
              t2Flatten.forEach((t) => {
                t.disabled = true;
                let trendPoint = [];
                response.result && response.result.items.forEach(tt => {
                  if (t.value == tt.item) {
                    trendPoint.push({
                      key: tt.key,
                      subKey: t.value,
                      label: tt.key + " - " + t.label,
                      value: "",
                      group: tt.group
                    })
                  }
                })
                this.form.t2.push({
                  key: t.value,
                  label: t.label,
                  value: "",
                  t3: t.children ? t.children.map((sub) => {
                    return {
                      key: sub.value,
                      subKey: t.value,
                      label: sub.label + " - " + t.label,
                      value: ""
                    }
                  }) : [],
                  trendPoint: trendPoint,
                });
              });
            this.fetchSettings()
          });
        }
      })
    },
    fetchSettings() {
      this.dataLoading = true;
      fetchGptSettings({ category: "designer" })
        .then((response) => {
          if (response.success) {
            this.clearForm();
            response.result.forEach((t) => {
              if (t.type === "deBasic") {
                this.form.basic = t;
              } else if (t.type === "deNegative") {
                this.form.negative = t;
              } else if (t.type === "deAge") {
                this.form.age.push(t);
              } else if (t.type === "deSeason") {
                this.form.season.push(t);
              } else if (t.type === "deSex") {
                this.form.sex.push(t);
              } else if (t.type === "deT2") {
                for (let i = 0; i < this.form.t2.length; i++) {
                  if (this.form.t2[i].key === t.key) {
                    this.form.t2[i].value = t.value;
                    break;
                  }
                }
              } else if (t.type === "deT3") {
                for (let i = 0; i < this.form.t2.length; i++) {
                  for (let j = 0; j < this.form.t2[i].t3.length; j++) {
                    if (this.form.t2[i].t3[j].key === t.key && this.form.t2[i].key === t.subKey) {
                      this.form.t2[i].t3[j].value = t.value;
                      break;
                    }
                  }
                }
              } else if (t.type === "deTrendPoint") {
                for (let i = 0; i < this.form.t2.length; i++) {
                  for (let j = 0; j < this.form.t2[i].trendPoint.length; j++) {
                    if (this.form.t2[i].trendPoint[j].key === t.key && this.form.t2[i].key === t.subKey) {
                      this.form.t2[i].trendPoint[j].value = t.value;
                      break;
                    }
                  }
                }
              }
            });
          }
        })
        .finally(() => {
          this.dataLoading = false;
        });
    },
    setSettings() {
      let v = [];
      v.push(this.form.basic);
      v.push(this.form.negative);
      v = v.concat(this.form.sex);
      v = v.concat(this.form.age);
      v = v.concat(this.form.season);
      this.form.t2.forEach(t2 => {
        v.push({
          type: "deT2",
          key: t2.key,
          value: t2.value
        })
        t2.t3.forEach(t3 => {
          v.push({
            type: "deT3",
            key: t3.key,
            subKey: t3.subKey,
            value: t3.value
          })
        });
        t2.trendPoint.forEach(trendPoint => {
          v.push({
            type: "deTrendPoint",
            key: trendPoint.key,
            subKey: trendPoint.subKey,
            value: trendPoint.value,
            group: trendPoint.group
          })
        })
      });
      this.dataLoading = true;
      setGptSettings({ data: v })
        .then((response) => {
          if (response.success) {
            this.$notify({
              title: "Update successful",
              type: "success",
            });
            this.fetchSettings();
          }
        })
        .finally(() => {
          this.dataLoading = false;
        });
    },
    active() {
      this.initOptions();
    },
    addKey(type) {
      this.$prompt('It takes effect immediately after confirm', 'Create Key', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning',
        inputValidator: (value) => {
          if (!value) {
            return "key can not be empty"
          }
        }
      }).then(({ value }) => {
        addGptSettingsType({ "type": type, "key": value }).then(response => {
          if (response.success) {
            this.$notify({
              title: "Create successful",
              type: "success",
            });
            this.fetchSettings();
          }
        })
      }).catch(() => {
      });
    },
    removeOption(data) {
      this.$confirm('It takes effect immediately after confirm', 'Delete this key?', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        deleteGptSettingsType(data).then(response => {
          if (response.success) {
            this.$notify({
              title: "Delete successful",
              type: "success",
            });
            this.fetchSettings();
          }
        })
      }).catch(() => {
      });
    }
  },
  computed: {
    currentIdx() {
      return parseInt(this.tabIdx, 10);
    }
  }
};
</script>

<style lang="scss" scoped>
.gpt-designer {
  .gpt-designer-card {
    margin-bottom: 10px;

    .top-botton {
      float: right;
      margin-top: -8px;
      margin-bottom: -8px
    }

    .top-next-botton {
      margin-right: 10px;
    }


    .gpt-form {
      margin-bottom: -10px;

      .add-botton {
        margin-bottom: 18px;
        display: block
      }

      .row-input {
        margin-top: 1px
      }

      .no-bottom-margin {
        margin-bottom: 0;
      }
    }
  }
}
</style>