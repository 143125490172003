<template>
  <el-tabs class="admin" v-model="currentTab" @tab-click="clickMainTab">
    <el-tab-pane label="User's MGT">
      <el-tabs v-model="currentSubTab">
        <el-tab-pane label="Users" name="00" v-if="adminType === 'root'">
          <user ref="users" />
        </el-tab-pane>
        <el-tab-pane label="Organization" name="01" v-if="adminType === 'root'">
          <organization ref="organization" />
        </el-tab-pane>
        <el-tab-pane label="Role" name="02" v-if="adminType === 'root'">
          <role ref="role" />
        </el-tab-pane>
        <el-tab-pane label="User Services" name="03" v-if="adminType === 'root'">
          <user-service ref="userServices" />
        </el-tab-pane>
        <el-tab-pane label="Brands" name="04" v-if="adminType === 'root'">
          <brand ref="brands" />
        </el-tab-pane>
        <el-tab-pane label="User Brands & Items" name="05" v-if="adminType === 'root'">
          <user-brand ref="userBrands" />
        </el-tab-pane>
        <el-tab-pane label="Notification" name="06" v-if="adminType === 'root'">
          <notification ref="notification" />
        </el-tab-pane>
      </el-tabs>
    </el-tab-pane>
    <el-tab-pane label="Big Data">
      <el-tabs v-model="currentSubTab">
        <el-tab-pane label="Crawling Sales" name="10" v-if="adminType === 'root' || adminType === 'checker'">
          <basic-data ref="crawlingSales" analysisType="sales" />
        </el-tab-pane>
        <el-tab-pane label="Crawling Stock" name="11" v-if="adminType === 'root'">
          <basic-data ref="crawlingStock" analysisType="stock" />
        </el-tab-pane>
        <el-tab-pane label="Tagger Check" name="12" v-if="adminType === 'root' || adminType === 'checker'">
          <tagger-check ref="taggerCheck" />
        </el-tab-pane>
      </el-tabs>
    </el-tab-pane>
    <el-tab-pane label="AI MGT">
      <el-tabs v-model="currentSubTab">
        <el-tab-pane label="Category" name="20" v-if="adminType === 'root'">
          <category ref="category" />
        </el-tab-pane>
        <el-tab-pane label="NLP Task" name="21" v-if="adminType === 'root'">
          <NLPTask ref="nlpTask" />
        </el-tab-pane>
        <el-tab-pane label="NLP Analysis" name="22" v-if="adminType === 'root'">
          <NLPAnalysis ref="nlpAnalysis" />
        </el-tab-pane>
        <el-tab-pane label="GPT" name="23" v-if="adminType === 'root'">
          <GPT ref="gpt" />
        </el-tab-pane>
        <el-tab-pane label="GPT Concept" name="24" v-if="adminType === 'root'">
          <GPTConcept ref="gptConcept" />
        </el-tab-pane>
        <el-tab-pane label="GPT Designer" name="25" v-if="adminType === 'root'">
          <GptDesigner ref="gptDesigner" />
        </el-tab-pane>
        <el-tab-pane label="GPT Drawing" name="26" v-if="adminType === 'root'">
          <GPTDrawing ref="gptDrawing" />
        </el-tab-pane>
        <el-tab-pane label="GPT Target" name="27" v-if="adminType === 'root'">
          <GptTargetFace ref="gptTargetFace" />
        </el-tab-pane>
      </el-tabs>
    </el-tab-pane>
    <el-tab-pane label="Report">
      <el-tabs v-model="currentSubTab">
        <el-tab-pane label="Weekly Board" name="30" v-if="adminType === 'root'">
          <Board ref="board" />
        </el-tab-pane>
        <el-tab-pane label="User Report" name="31" v-if="adminType === 'root'">
          <user-report ref="userReport" />
        </el-tab-pane>
        <el-tab-pane label="Image Trend Map" name="32" v-if="adminType === 'root'">
          <image-trend-map ref="imageTrendMap" />
        </el-tab-pane>
        <el-tab-pane label="Image Trend Map (Brands&Items)" name="33" v-if="adminType === 'root'">
          <image-trend-map-detail ref="imageTrendMapDetail" />
        </el-tab-pane>
        <el-tab-pane label="User Data" name="34" v-if="adminType === 'root'">
          <user-data ref="userData" />
        </el-tab-pane>
      </el-tabs>
    </el-tab-pane>
    <el-tab-pane label="ETC MGT">
      <el-tabs v-model="currentSubTab">
        <el-tab-pane label="Drives" name="40" v-if="adminType === 'root'" key="drives">
          <drive ref="drives" />
        </el-tab-pane>
        <el-tab-pane label="Visual Files" name="41" v-if="adminType === 'root'">
          <visual-files ref="visualFiles" />
        </el-tab-pane>
        <el-tab-pane label="Global Trend" name="42" v-if="adminType === 'root'">
          <global-trends ref="globalTrend" />
        </el-tab-pane>
        <el-tab-pane label="Cores" name="43" v-if="adminType === 'root'">
          <cores ref="cores" />
        </el-tab-pane>
      </el-tabs>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import User from "@/components/admin/User.vue";
import Organization from "@/components/admin/Organization.vue";
import Role from "@/components/admin/Role.vue";
import UserService from "@/components/admin/UserService.vue";
import Brand from "@/components/admin/Brand.vue";
import UserBrand from "@/components/admin/UserBrand.vue";
import Category from "@/components/admin/Category.vue";
import Drive from "@/components/admin/Drive.vue";
import VisualFiles from "@/components/admin/VisualFiles.vue";
import BasicData from "@/components/admin/BasicData.vue";
import Board from "@/components/admin/Board.vue";
import UserReport from "@/components/admin/UserReport.vue";
import ImageTrendMap from "@/components/admin/ImageTrendMap.vue";
import ImageTrendMapDetail from "@/components/admin/ImageTrendMapDetail.vue";
import NLPTask from "@/components/admin/NLPTask.vue";
import NLPAnalysis from "@/components/admin/NLPAnalysis.vue";
import Notification from "@/components/admin/Notification.vue";
import TaggerCheck from "@/components/admin/TaggerCheck.vue";
import UserData from "@/components/admin/UserData.vue";
import GPT from "@/components/admin/Gpt.vue";
import GPTConcept from "@/components/admin/GptConcept.vue";
import GptDesigner from "@/components/admin/GptDesigner.vue";
import GPTDrawing from "@/components/admin/GptDrawing.vue";
import GptTargetFace from "@/components/admin/GptTargetFace.vue";
import GlobalTrends from "@/components/admin/GlobalTrends.vue";
import Cores from "@/components/admin/Cores.vue";

export default {
  name: "Admin",
  components: {
    User,
    Organization,
    Role,
    UserService,
    Brand,
    UserBrand,
    Category,
    Drive,
    VisualFiles,
    BasicData,
    Board,
    UserReport,
    ImageTrendMap,
    ImageTrendMapDetail,
    NLPTask,
    NLPAnalysis,
    Notification,
    TaggerCheck,
    UserData,
    GPT,
    GPTConcept,
    GptDesigner,
    GPTDrawing,
    GptTargetFace,
    GlobalTrends,
    Cores
  },
  data() {
    return {
      currentTab: "",
      lastTab: "",
      currentSubTab: "",
      list: [
        {
          router: "#user",
          index: "0",
          subIndex: "00",
          ref: "users",
          roles: ["root"],
        },
        {
          router: "#organization",
          index: "0",
          subIndex: "01",
          ref: "organization",
          roles: ["root"],
        },
        {
          router: "#role",
          index: "0",
          subIndex: "02",
          ref: "role",
          roles: ["root"],
        },
        {
          router: "#user-services",
          index: "0",
          subIndex: "03",
          ref: "userServices",
          roles: ["root"],
        },
        {
          router: "#brands",
          index: "0",
          subIndex: "04",
          ref: "brands",
          roles: ["root"],
        },
        {
          router: "#user-brands",
          index: "0",
          subIndex: "05",
          ref: "userBrands",
          roles: ["root"],
        },
        {
          router: "#notification",
          index: "0",
          subIndex: "06",
          ref: "notification",
          roles: ["root"],
        },
        {
          router: "#crawling-sales",
          index: "1",
          subIndex: "10",
          ref: "crawlingSales",
          roles: ["root", "checker"],
        },
        {
          router: "#crawling-stock",
          index: "1",
          subIndex: "11",
          ref: "crawlingStock",
          roles: ["root"],
        },
        {
          router: "#tagger-check",
          index: "1",
          subIndex: "12",
          ref: "taggerCheck",
          roles: ["root", "checker"],
        },
        {
          router: "#category",
          index: "2",
          subIndex: "20",
          ref: "category",
          roles: ["root"],
        },
        {
          router: "#nlp-task",
          index: "2",
          subIndex: "21",
          ref: "nlpTask",
          roles: ["root"],
        },
        {
          router: "#nlp-analysis",
          index: "2",
          subIndex: "22",
          ref: "nlpAnalysis",
          roles: ["root"],
        },
        {
          router: "#gpt",
          index: "2",
          subIndex: "23",
          ref: "gpt",
          roles: ["root"],
        },
        {
          router: "#gpt-concept",
          index: "2",
          subIndex: "24",
          ref: "gptConcept",
          roles: ["root"],
        },
        {
          router: "#gpt-designer",
          index: "2",
          subIndex: "25",
          ref: "gptDesigner",
          roles: ["root"],
        },
        {
          router: "#gpt-drawing",
          index: "2",
          subIndex: "26",
          ref: "gptDrawing",
          roles: ["root"],
        },
        {
          router: "#gpt-target-face",
          index: "2",
          subIndex: "27",
          ref: "gptTargetFace",
          roles: ["root"],
        },
        {
          router: "#board",
          index: "3",
          subIndex: "30",
          ref: "board",
          roles: ["root"],
        },
        {
          router: "#user-report",
          index: "3",
          subIndex: "31",
          ref: "userReport",
          roles: ["root"],
        },
        {
          router: "#image-trend-map",
          index: "3",
          subIndex: "32",
          ref: "imageTrendMap",
          roles: ["root"],
        },
        {
          router: "#image-trend-map-detail",
          index: "3",
          subIndex: "33",
          ref: "imageTrendMapDetail",
          roles: ["root"],
        },
        {
          router: "#user-data",
          index: "3",
          subIndex: "34",
          ref: "userData",
          roles: ["root"],
        },
        {
          router: "#drives",
          index: "4",
          subIndex: "40",
          ref: "drives",
          roles: ["root"],
        },
        {
          router: "#visual-files",
          index: "4",
          subIndex: "41",
          roles: ["root"],
        },
        {
          router: "#global-trends",
          index: "4",
          subIndex: "42",
          ref: "globalTrend",
          roles: ["root"],
        },
        {
          router: "#cores",
          index: "4",
          subIndex: "43",
          ref: "cores",
          roles: ["root"],
        },
      ],
    };
  },
  mounted() {
    if (this.$route.hash !== "") {
      for (let i = 0; i < this.list.length; i++) {
        const e = this.list[i];
        if (this.$route.hash === e.router) {
          if (this.adminType && !e.roles.includes(this.adminType)) {
            break;
          }
          this.lastTab = e.index;
          this.currentTab = e.index;
          this.currentSubTab = e.subIndex;
          return;
        }
      }
    }
    let firstSub = this.firstSubItem();
    this.lastTab = firstSub.index;
    this.currentTab = firstSub.index;
    this.currentSubTab = firstSub.subIndex;
  },
  methods: {
    clickMainTab() {
      if (this.currentTab !== this.lastTab) {
        let firstSub = this.firstSubItem(this.currentTab);
        this.currentSubTab = firstSub.subIndex;
        this.lastTab = this.currentTab;
      }
    },
    firstSubItem(index) {
      for (let i = 0; i < this.list.length; i++) {
        const e = this.list[i];
        if (this.adminType && !e.roles.includes(this.adminType)) {
          continue;
        }
        if (!index || e.index === index) {
          return e;
        }
      }
    },
  },
  watch: {
    currentSubTab(index) {
      for (let i = 0; i < this.list.length; i++) {
        const e = this.list[i];
        if (index === e.subIndex) {
          if (this.adminType && !e.roles.includes(this.adminType)) {
            return;
          }
          if (this.$route.hash !== e.router) {
            this.$router.push(e.router);
          }
          if (e.ref) {
            this.$refs[e.ref].active();
          }
          break;
        }
      }
    },
  },
  computed: {
    adminType() {
      return this.$store.getters.my && this.$store.getters.my.adminType
        ? this.$store.getters.my.adminType
        : "";
    },
  },
};
</script>

<style lang="scss" scoped>
.admin {
  padding: 10px 20px 0;
}
</style>