<template>
  <el-drawer
    class="trend-point-drawer"
    :visible.sync="drawerVisible"
    size="900px"
    :show-close="false"
    append-to-body
    @closed="drawerClosed"
  >
    <div class="trend-point-body">
      <el-col :span="6" v-for="(colItems, i) in optionList" :key="i">
        <el-row
          class="trend-point-row"
          v-for="item in colItems"
          :key="item.key"
        >
          <el-button
            class="trend-point-group"
            v-if="item.isGroup"
            type="text"
            size="small"
          >
            {{ item.key }}
          </el-button>
          <el-button
            v-else
            class="trend-point-button"
            size="small"
            :type="item.selected ? 'primary' : ''"
            @click="clickTrendPoint(item.group, item.key)"
          >
            {{ item.key }}
          </el-button>
        </el-row>
      </el-col>
    </div>
    <div class="trend-point-header" slot="title">
      <span class="trend-point-header-title">Trend Point</span>
      <!-- TODO ElementUI有bug导致drawer中的第一个控件自动聚焦，需要升级 -->
      <div class="trend-point-header-button">
        <el-button
          type="primary"
          size="small"
          @click="confirmDetail"
          :autofocus="false"
          >Confirm</el-button
        >
        <el-button size="small" @click="drawerVisible = false"
          >Cancel</el-button
        >
      </div>
    </div>
  </el-drawer>
</template>

<script>
import { fetchTaggerOptions } from "@/api/admin";

export default {
  data() {
    return {
      drawerVisible: false,
      originalList: [],
      optionList: [],
      selectList: [],
    };
  },
  created() {
    fetchTaggerOptions().then((response) => {
      if (response.success && response.result.items) {
        this.originalList = response.result.items;
        if (this.current) {
          this.updateOptionList();
        }
      }
    });
  },
  props: {
    current: {
      type: Object,
      default: null,
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    confirmDetail() {
      this.$emit("change", this.selectList);
      this.drawerVisible = false;
    },
    updateOptionList() {
      this.optionList = [];
      this.selectList = this.current.trendPoints
        ? this.current.trendPoints
        : {};
      this.originalList.forEach((e, i) => {
        if (e.item !== this.current.t2) {
          return;
        }
        if (i === 0 || e.group !== this.originalList[i - 1].group) {
          // new group
          this.pushOptionList({ isGroup: true, key: e.group });
        }
        this.pushOptionList({ isGroup: false, selected: false, ...e });
      });
      this.freshSelectedOption();
    },
    pushOptionList(e) {
      if (
        this.optionList.length === 0 ||
        this.optionList[this.optionList.length - 1].length === 25
      ) {
        this.optionList.push([]);
      }
      this.optionList[this.optionList.length - 1].push(e);
    },
    clickTrendPoint(group, key) {
      if (this.selectList[group] === key) {
        delete this.selectList[group];
      } else {
        this.selectList[group] = key;
      }
      this.freshSelectedOption();
    },
    drawerClosed() {
      this.$emit("closed");
    },
    freshSelectedOption() {
      this.optionList.forEach((x, i) => {
        x.forEach((y, j) => {
          if (!y.isGroup) {
            this.optionList[i][j].selected = this.selectList[y.group] === y.key;
          }
        });
      });
    },
  },
  watch: {
    current() {
      this.updateOptionList();
    },
    visible(v) {
      this.drawerVisible = v;
      if (v) {
        this.updateOptionList();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.trend-point-drawer {
  /deep/ .el-drawer__header {
    padding-top: 10px;
    margin-bottom: 10px;
  }

  .trend-point-body {
    border-top: 1px solid #e8e8e8;
    height: calc(100vh - 56px);
    overflow-y: scroll;
    padding: 5px 10px;

    .trend-point-row {
      padding-left: 5px !important;
      padding-right: 5px !important;
      margin-bottom: 5px;
      min-height: 35px;
      line-height: 35px;
      text-align: center;

      .trend-point-group {
        cursor: unset;
        -webkit-user-select: text;
        -moz-user-select: text;
        -ms-user-select: text;
        user-select: text;
        width: 100%;
        background-color: #909399;
        color: white;
      }

      .trend-point-button {
        width: 100%;
      }
    }
  }

  .trend-point-header {
    min-height: 34px;
    line-height: 34px;

    .trend-point-header-title {
      font-size: 18px;
      color: #303133;
    }
    .trend-point-header-button {
      float: right;
    }
  }
}
</style>