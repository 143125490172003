<template>
  <div class="basic-data-tagger" v-loading.fullscreen.lock="fullLoading">
    <el-button
      icon="el-icon-brush"
      class="task-btn fr"
      type="primary"
      size="small"
      :disabled="taskId === 0 || adminType !== 'root'"
      @click="goToTask"
      >Go to task</el-button
    >

    <el-button
      icon="el-icon-magic-stick"
      class="task-btn fr"
      type="warning"
      size="small"
      :disabled="!needRecount"
      @click="recountTaggerBest"
      >Recount</el-button
    >
    <el-tabs v-model="tab" type="card" :before-leave="tabBeforeChange">
      <el-tab-pane
        v-for="t in tabList"
        :label="t !== 0 ? 'Best' + t : 'All'"
        :name="t !== 0 ? 'best' + t : 'all'"
        :key="t"
      >
        <el-form :inline="true" size="small" class="search-form">
          <el-form-item class="search-form-gutter" label="User">
            <el-select
              :disabled="!taggered || needRecount"
              style="width: 168px"
              v-model="userId"
              @change="handleUserChange"
              clearable
            >
              <el-option
                v-for="item in userList"
                :key="item.userId"
                :label="item.userName"
                :value="item.userId"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="search-form-gutter" label="Item">
            <el-select
              :disabled="!taggered || needRecount"
              style="width: 168px"
              v-model="item"
              @change="handleChangeItem"
            >
              <el-option
                v-for="item in t2List"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                :disabled="item.disabled"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="search-form-gutter" label="Brand">
            <el-select
              :disabled="!taggered || needRecount"
              style="width: 168px"
              v-model="brandId"
              :placeholder="$t('common.all')"
              @change="searchTaggerDetails"
              clearable
            >
              <el-option
                v-for="item in brandList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="search-form-gutter">
            <el-button
              icon="el-icon-refresh"
              type="primary"
              size="small"
              :disabled="!taggered"
              @click="searchTaggerDetails"
              >Refresh</el-button
            >
          </el-form-item>
          <el-form-item>
            <i class="el-icon-warning-outline"></i
            ><span>&nbsp;Press ⬅️ or ➡️ to switch expanded items.</span>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="No detection" name="noDetection">
        <el-form :inline="true" size="small" class="search-form">
          <el-form-item class="search-form-gutter" label="User">
            <el-select
              :disabled="!taggered || needRecount"
              style="width: 168px"
              v-model="userId"
              @change="handleUserChange"
              clearable
            >
              <el-option
                v-for="item in userList"
                :key="item.userId"
                :label="item.userName"
                :value="item.userId"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="search-form-gutter" label="Brand">
            <el-select
              :disabled="!taggered || needRecount"
              style="width: 168px"
              v-model="brandId"
              :placeholder="$t('common.all')"
              @change="searchTaggerDetails"
              clearable
            >
              <el-option
                v-for="item in brandList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="search-form-gutter">
            <el-button
              icon="el-icon-refresh"
              type="primary"
              size="small"
              :disabled="!taggered"
              @click="searchTaggerDetails"
              >Refresh</el-button
            >
          </el-form-item>
          <el-form-item>
            <i class="el-icon-warning-outline"></i
            ><span>&nbsp;Press ⬅️ or ➡️ to switch expanded items.</span>
          </el-form-item>
        </el-form>
      </el-tab-pane>
    </el-tabs>
    <el-table
      v-loading="tableLoading"
      class="tagger-table"
      :expand-row-keys="expandKeys"
      row-key="imageClassifyTempId"
      :row-class-name="getRowClass"
      :row-style="{ cursor: 'pointer' }"
      @row-click="clickRowHandle"
      @expand-change="clickRowHandle"
      v-if="taggered"
      :data="tableData"
      @filter-change="filterChangeHandle"
    >
      <el-table-column type="expand">
        <template slot-scope="scope">
          <div>
            <el-col :span="8">
              <el-popover
                width="200px"
                placement="right"
                :disabled="!trendPointEnable"
                :title="trendPointTip ? trendPointTip.title : ''"
                trigger="manual"
                :value="trendPointTip != null"
                popper-class="trend-point-tip"
                style="top: 0 !important"
              >
                <div class="tip-sub-title">
                  {{ trendPointTip ? trendPointTip.original : "" }}
                </div>
                <img
                  width="200px"
                  :src="trendPointTip ? trendPointTip.url : ''"
                />
                <el-image
                  slot="reference"
                  width="100%"
                  :src="scope.row.url"
                  :preview-src-list="[scope.row.url]"
                  fit="contain"
                />
              </el-popover>
            </el-col>
            <el-col :span="16">
              <el-row class="tagger-table-row">
                <el-col :span="9">
                  <el-form inline>
                    <el-form-item class="tagger-table-form" label="T2">
                      <el-col
                        class="tagger-btn-container"
                        v-for="item in t2List"
                        :key="item.value"
                        :span="12"
                      >
                        <el-button
                          size="mini"
                          style="width: 100%"
                          :type="current.t2 === item.value ? 'primary' : ''"
                          @click="clickT2(item.value)"
                          >{{ item.label }}</el-button
                        >
                      </el-col>
                    </el-form-item>
                  </el-form>
                </el-col>
                <el-col :span="15">
                  <el-form inline>
                    <el-form-item class="tagger-table-form" label="T3">
                      <el-col
                        class="tagger-btn-container"
                        v-for="item in t3List(current.t2)"
                        :key="item.value"
                        :span="8"
                      >
                        <el-popover
                          :disabled="!t3TipEnable"
                          placement="left-start"
                          :title="t3tip ? t3tip.title : ''"
                          trigger="hover"
                          @show="showT3Tip(item.value)"
                        >
                          <div class="tip-sub-title">
                            {{ t3tip ? t3tip.original : "" }}
                          </div>
                          <img :src="t3tip ? t3tip.url : ''" />
                          <el-button
                            slot="reference"
                            size="mini"
                            style="width: 100%"
                            :type="current.t3 === item.value ? 'primary' : ''"
                            @click="clickT3(item.value)"
                            >{{ item.label }}</el-button
                          >
                        </el-popover>
                      </el-col>
                    </el-form-item>
                  </el-form>
                </el-col>
              </el-row>
              <el-row class="tagger-table-row">
                <el-col :span="8" v-if="options.COLOR">
                  <el-form label-position="top">
                    <el-form-item class="tagger-table-form" label="Color">
                      <el-color-picker
                        class="tagger-color-picker"
                        size="mini"
                        color-format="rgb"
                        :show-alpha="false"
                        v-model="currentColors[index]"
                        v-for="(_, index) in currentColors"
                        :key="index"
                      ></el-color-picker>
                      <el-button
                        class="tagger-color-add"
                        icon="el-icon-plus"
                        size="mini"
                        plain
                        @click="clickColorAdd"
                      ></el-button>
                      <el-button
                        class="tagger-color-add"
                        icon="el-icon-minus"
                        size="mini"
                        plain
                        @click="clickColorRemove"
                      ></el-button>
                    </el-form-item>
                  </el-form>
                </el-col>
                <el-col :span="8" v-if="options.PATTERN">
                  <el-form label-position="top">
                    <el-form-item class="tagger-table-form" label="Pattern">
                      <el-select
                        v-model="current.pattern"
                        size="mini"
                        filterable
                      >
                        <el-option
                          v-for="item in options.PATTERN"
                          :key="item"
                          :label="item"
                          :value="item"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-form>
                </el-col>
                <el-col :span="8" v-if="options.STYLE">
                  <el-form label-position="top">
                    <el-form-item class="tagger-table-form" label="Style">
                      <el-select v-model="current.style" size="mini" filterable>
                        <el-option
                          v-for="item in options.STYLE"
                          :key="item"
                          :label="item"
                          :value="item"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-form>
                </el-col>
                <el-col :span="8" v-if="options['COLLAR/NECK']">
                  <el-form label-position="top">
                    <el-form-item class="tagger-table-form" label="Collar">
                      <el-select
                        v-model="current.collarNeck"
                        size="mini"
                        filterable
                      >
                        <el-option
                          v-for="item in options['COLLAR/NECK']"
                          :key="item"
                          :label="item"
                          :value="item"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-form>
                </el-col>
                <el-col :span="8" v-if="options.FG">
                  <el-form label-position="top">
                    <el-form-item class="tagger-table-form" label="FG">
                      <el-select v-model="current.fg" size="mini" filterable>
                        <el-option
                          v-for="item in options.FG"
                          :key="item"
                          :label="item"
                          :value="item"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-form>
                </el-col>
                <el-col :span="8" v-if="options.FIT">
                  <el-form label-position="top">
                    <el-form-item class="tagger-table-form" label="Fit">
                      <el-select v-model="current.fit" size="mini" filterable>
                        <el-option
                          v-for="item in options.FIT"
                          :key="item"
                          :label="item"
                          :value="item"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-form>
                </el-col>
                <el-col :span="8" v-if="options.LENGTH">
                  <el-form label-position="top">
                    <el-form-item class="tagger-table-form" label="Length">
                      <el-select
                        v-model="current['length']"
                        size="mini"
                        filterable
                      >
                        <el-option
                          v-for="item in options.LENGTH"
                          :key="item"
                          :label="item"
                          :value="item"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-form>
                </el-col>
                <el-col
                  :span="8"
                  v-if="trendPointOptions && trendPointOptions.length > 0"
                >
                  <el-form label-position="top">
                    <el-form-item class="tagger-table-form" label="Trend Point">
                      <el-button
                        class="tagger-table-form-button"
                        type="primary"
                        @click="clickCheck"
                        size="mini"
                        >{{
                          "Select (" + currentTrendPointLen + ")"
                        }}</el-button
                      >
                    </el-form-item>
                  </el-form>
                </el-col>
              </el-row>
              <el-row class="tagger-table-row tagger-table-footer">
                <el-button size="mini" type="primary" @click="clickConfirm"
                  >Save</el-button
                >
                <el-button size="mini" @click="clickReset">Reset</el-button>
              </el-row>
            </el-col>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="image" label="Image" min-width="65px">
        <template slot-scope="scope">
          <el-avatar
            slot="reference"
            class="table-avatar"
            shape="square"
            :size="80"
            fit="contain"
            :src="scope.row.url"
            alt="image"
          />
        </template>
      </el-table-column>
      <el-table-column prop="t1" label="T1"></el-table-column>
      <el-table-column prop="t2" label="T2"> </el-table-column>
      <el-table-column prop="t3" label="T3"> </el-table-column>
      <el-table-column v-if="tab === 'all'" prop="rank" label="Rank">
        <template slot-scope="scope">
          {{ "Best " + scope.row.rank }}
        </template>
      </el-table-column>
      <el-table-column
        v-if="tab === 'all'"
        :filters="[
          { text: 'Checked', value: 'true' },
          { text: 'Not Checked', value: 'false' },
        ]"
        :filter-multiple="false"
        :filtered-value="['false']"
        label="Check By"
        min-width="90px"
        column-key="checkBy"
        key="checkBy"
      >
        <template slot-scope="scope">
          <i v-if="scope.row.checkBy" class="el-icon-success check-icon" />
          <span style="margin-left: 10px">{{ scope.row.checkBy }}</span>
        </template>
      </el-table-column>
      <el-table-column
        v-else
        label="Check By"
        min-width="90px"
        column-key="checkByRank"
        key="checkByRank"
      >
        <template slot-scope="scope">
          <i v-if="scope.row.checkBy" class="el-icon-success check-icon" />
          <span style="margin-left: 10px">{{ scope.row.checkBy }}</span>
        </template>
      </el-table-column>
      <el-table-column label="On Sale Time" min-width="70px">
        <template slot-scope="scope">
          <span
            :class="{ redtxt: scope.row.yearTag === '当年' }"
            style="margin-left: 10px"
            >{{ scope.row.yearTag }}</span
          >
        </template>
      </el-table-column>
      <el-table-column
        v-if="tab === 'all'"
        :filters="[{ text: 'In 7 Days', value: 7 }]"
        :filter-multiple="false"
        :filtered-value="[7]"
        label="Create At"
        min-width="80"
        column-key="createdAt"
        key="createdAt"
      >
        <template slot-scope="scope">
          <i class="el-icon-time"></i>
          <span style="margin-left: 10px">{{
            scope.row.createdAt | displayTime()
          }}</span>
        </template>
      </el-table-column>
      <el-table-column
        v-else
        label="Create At"
        min-width="80"
        column-key="createdAtRank"
        key="createdAtRank"
      >
        <template slot-scope="scope">
          <i class="el-icon-time"></i>
          <span
            :class="{ redtxt: in7days(scope.row.createdAt) }"
            style="margin-left: 10px"
            >{{ scope.row.createdAt | displayTime() }}</span
          >
        </template>
      </el-table-column>
      <el-table-column label="Update At" min-width="80">
        <template slot-scope="scope">
          <i class="el-icon-time"></i>
          <span style="margin-left: 10px">{{
            scope.row.updatedAt | displayTime()
          }}</span>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-if="taggered"
      v-show="total > 0"
      :total="total"
      :offset.sync="listQuery.offset"
      :limit.sync="listQuery.limit"
      layout="sizes, prev, pager, next, total "
      @pagination="searchTaggerDetails"
    >
      <span slot="addition">
        <el-button
          style="vertical-align: bottom"
          icon="el-icon-refresh"
          type="primary"
          size="small"
          :disabled="!taggered"
          @click="searchTaggerDetails"
          >Refresh</el-button
        >
      </span>
    </pagination>
    <div v-if="!taggered" class="info">
      <i class="el-icon-loading" /> Please wait for task finish!
    </div>
    <TaggerCheckTrendPoint
      :visible="trendPointVisible"
      :current="current"
      @closed="trendPointVisible = false"
      @change="changeTrendPoint"
    />
  </div>
</template>

<script>
import Cookies from "js-cookie";
import tipTaggerT3 from "@/assets/tip_tagger_t3.json";
import dayjs from "dayjs";
import {
  fetchBasicTaggerDetailBest,
  saveBasicTaggerDetailBest,
  fetchBasicTaggerDetailNoDetection,
  fetchBasicTaggerDetailBrand,
  updateBasicTaggerDetail,
  fetchItem,
  fetchUserItemsUser,
} from "@/api/admin";
import Pagination from "@/components/Pagination";
import TaggerCheckTrendPoint from "@/components/admin/TaggerCheckTrendPoint";
import { OssSourceURL } from "@/constants";

export default {
  components: { Pagination, TaggerCheckTrendPoint },
  data() {
    return {
      userList: [],
      userId: null,
      brandList: [],
      brandId: null,
      item: null,
      hasChecked: "false",
      beforeDays: 7,
      t2List: [],
      current: {},
      currentColors: [null],
      currentIdx: 0,
      currentTrendPointLen: 0,
      tableData: [],
      listQuery: {
        offset: 0,
        limit: 10,
      },
      total: 0,
      tab: "all",
      tabList: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
      fullLoading: false,
      expandKeys: [],
      t3tip: null,
      trendPointTip: null,
      t3TipEnable: true,
      trendPointEnable: true,
      tableLoading: false,
      trendPointVisible: false,
    };
  },
  props: {
    date: {
      type: String,
      default: "",
    },
    taskId: {
      type: Number,
      default: 0,
    },
    taggered: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.$store.dispatch("category/fresh").then(() => {
      let t2Flatten = this.$store.getters.t2Flatten;
      this.t2List = [];
      t2Flatten &&
        t2Flatten.forEach((t) => {
          t.disabled = true;
          this.t2List.push(t);
        });
      this.fetchUserList();
      this.fetchItem();
    });
    let that = this;
    this.handler = function () {
      if (!that.tableData || that.tableData.length === 0) {
        return;
      }
      let key = window.event.keyCode;
      if (key === 37) {
        // active last
        if (that.expandKeys && that.expandKeys.length > 0) {
          let currentId = that.expandKeys[0];
          for (let i = 0; i < that.tableData.length; i++) {
            if (that.tableData[i].imageClassifyTempId === currentId) {
              if (i === 0) {
                that.expandKeys = [];
              } else {
                that.expandKeys = [that.tableData[i - 1].imageClassifyTempId];
              }
            }
          }
        }
      } else if (key === 39) {
        // active next
        if (that.expandKeys && that.expandKeys.length > 0) {
          let currentId = that.expandKeys[0];
          for (let i = 0; i < that.tableData.length; i++) {
            if (that.tableData[i].imageClassifyTempId === currentId) {
              if (that.tableData.length > i + 1) {
                that.expandKeys = [that.tableData[i + 1].imageClassifyTempId];
              } else {
                that.expandKeys = [];
              }
            }
          }
        } else if (that.tableData && that.tableData.length > 0) {
          that.expandKeys = [that.tableData[0].imageClassifyTempId];
        }
      }
    };
  },
  mounted() {
    window.addEventListener("keydown", this.handler);
    let disableT3Tip = Cookies.get("disable-t3-tip");
    if (disableT3Tip === "true") {
      this.t3TipEnable = false;
    }
    let disableTrendPointTip = Cookies.get("disable-trendpoint-tip");
    if (disableTrendPointTip === "true") {
      this.trendPointEnable = false;
    }
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.handler);
  },
  methods: {
    fetchUserList() {
      this.userId = null;
      this.tableLoading = true;
      fetchUserItemsUser(this.date)
        .then((response) => {
          this.tableLoading = false;
          if (response.success) {
            this.userList = response.result.items;
          }
        })
        .catch(() => {
          this.tableLoading = false;
        });
    },
    handleUserChange() {
      this.item = null;
      this.fetchItem();
    },
    fetchBrandList() {
      let params = { date: this.date, limit: -1 };
      if (this.tab !== "noDetection") {
        params.item = this.item;
      }
      if (this.userId) {
        params.userId = this.userId;
      }
      this.tableLoading = true;
      this.brandList = [];
      this.brandId = null;
      fetchBasicTaggerDetailBrand(params)
        .then((response) => {
          this.tableLoading = false;
          if (response.success) {
            this.brandList = response.result.items;
            if (this.brandList && this.brandList.length > 0) {
              // this.brandId = this.brandList[0].id;
              this.searchTaggerDetails();
            }
          }
        })
        .catch(() => {
          this.tableLoading = false;
        });
    },
    handleChangeItem() {
      this.fetchBrandList();
    },
    searchTaggerDetails() {
      if (this.needRecount) {
        this.$notify({
          title: "Please recount the classify changes first",
          type: "warning",
        });
        return;
      }
      if (this.tab !== "noDetection") {
        let rank = 0;
        if (this.tab === "best10") {
          rank = 10;
        } else if (this.tab === "best20") {
          rank = 20;
        } else if (this.tab === "best30") {
          rank = 30;
        } else if (this.tab === "best40") {
          rank = 40;
        } else if (this.tab === "best50") {
          rank = 50;
        } else if (this.tab === "best60") {
          rank = 60;
        } else if (this.tab === "best70") {
          rank = 70;
        } else if (this.tab === "best80") {
          rank = 80;
        } else if (this.tab === "best90") {
          rank = 90;
        } else if (this.tab === "best100") {
          rank = 100;
        }
        if (!this.item || !this.date) {
          this.tableData = [];
          this.total = 0;
          return;
        }
        let params = {
          ...this.listQuery,
          userId: this.userId,
          item: this.item,
          brandId: this.brandId,
          date: this.date,
          rank: rank,
        };
        if (rank === 0) {
          params.hasChecked = this.hasChecked;
          params.beforeDays = this.beforeDays;
        }
        this.tableLoading = true;
        fetchBasicTaggerDetailBest(params)
          .then((response) => {
            this.tableLoading = false;
            if (response.success) {
              this.tableData = response.result.items || [];
              this.total = response.result.totalCount;
            }
          })
          .catch(() => {
            this.tableLoading = false;
          });
      } else {
        if (!this.date) {
          this.tableData = [];
          this.total = 0;
          return;
        }
        this.tableLoading = true;
        fetchBasicTaggerDetailNoDetection({
          ...this.listQuery,
          date: this.date,
          brandId: this.brandId,
        })
          .then((response) => {
            this.tableLoading = false;
            if (response.success) {
              this.tableData = response.result.items || [];
              this.total = response.result.totalCount;
            }
          })
          .catch(() => {
            this.tableLoading = false;
          });
      }
    },
    recountTaggerBest() {
      if (!this.needRecount) {
        return;
      }
      this.fullLoading = true;
      saveBasicTaggerDetailBest({
        date: this.date,
        brandId: this.brandId,
        userId: this.userId,
      })
        .then((response) => {
          if (response.success) {
            this.$notify({
              title: "Recount Best successful",
              type: "success",
            });
            this.$store.dispatch("admin/setNeedRecount", false);
            this.searchTaggerDetails();
          }
          this.fullLoading = false;
        })
        .catch(() => {
          this.fullLoading = false;
        });
    },
    clearDetails() {
      this.current.pattern = "";
      this.current.style = "";
      this.current.collarNeck = "";
      this.current.fg = "";
      this.current.fit = "";
      this.current["length"] = "";
      this.current.trendPoints = null;
      this.freshTrendPointLen();
    },
    clickT2(value) {
      this.current.t2 = value;
      this.current.t3 = "";
      this.clearDetails();
    },
    clickT3(value) {
      this.current.t3 = value;
    },
    clickConfirm() {
      let colors = [];
      for (let i = 0; i < this.currentColors.length; i++) {
        const e = this.currentColors[i];
        if (e && e.startsWith("rgb(") && e.endsWith(")")) {
          let rgbs = e.substring(4, e.length - 1).split(",");
          if (!rgbs || rgbs.length !== 3) {
            continue;
          }
          colors.push({
            rgb: [parseInt(rgbs[0]), parseInt(rgbs[1]), parseInt(rgbs[2])],
          });
        }
      }
      this.current.color = JSON.stringify(colors);
      for (let i = 0; i < this.tableData.length; i++) {
        if (
          this.tableData[i].imageClassifyTempId ===
            this.current.imageClassifyTempId &&
          this.tableData[i].t2 !== this.current.t2
        ) {
          this.$store.dispatch("admin/setNeedRecount", true);
        }
      }
      this.tableLoading = true;
      updateBasicTaggerDetail(
        {
          date: this.date,
          key: this.current.name,
          id: this.current.classifyId,
          t2: this.current.t2,
          t3: this.current.t3,
          pattern: this.current.pattern,
          style: this.current.style,
          collarNeck: this.current.collarNeck,
          fg: this.current.fg,
          fit: this.current.fit,
          length: this.current.length,
          trendPoints: this.current.trendPoints,
          color: this.current.color,
        },
        this.current.imageClassifyTempId
      )
        .then((response) => {
          this.tableLoading = false;
          if (response.success) {
            this.$notify({
              title: "Save classify successful",
              type: "success",
            });
          }
          this.tableData[this.currentIdx] = JSON.parse(
            JSON.stringify(this.current)
          );
        })
        .catch(() => {
          this.tableLoading = false;
        });
    },
    clickReset() {
      this.current = JSON.parse(
        JSON.stringify(this.tableData[this.currentIdx])
      );
      this.freshTrendPointLen();
      this.covertColor();
    },
    clickColorAdd() {
      this.currentColors.push(null);
    },
    clickColorRemove() {
      this.currentColors.pop();
    },
    clickRowHandle(row) {
      if (
        this.expandKeys &&
        this.expandKeys.length > 0 &&
        this.expandKeys[0] === row.imageClassifyTempId
      ) {
        this.expandKeys = [];
      } else {
        this.expandKeys = [row.imageClassifyTempId];
      }
    },
    getRowClass({ row }) {
      return "row-" + row.imageClassifyTempId;
    },
    t3List(t2) {
      for (let i = 0; i < this.t2List.length; i++) {
        if (t2 === this.t2List[i].value) {
          return this.t2List[i].children;
        }
      }
      return [];
    },
    covertColor() {
      // 转"rgb": [0, 0, 0] -> rgb(0, 0, 0)格式
      if (this.current && this.current.color) {
        let colors = JSON.parse(this.current.color);
        this.currentColors = colors.map((v) => {
          if (!v.rgb || v.rgb.length !== 3) {
            return null;
          }
          return "rgb(" + v.rgb[0] + "," + v.rgb[1] + "," + v.rgb[2] + ")";
        });
      } else {
        this.currentColors = [null];
      }
    },
    trendPointTipChange(val) {
      this.trendPointTip = val;
    },
    showT3Tip(t3) {
      if (!t3) {
        this.t3tip = null;
        return;
      }
      for (let i = 0; i < tipTaggerT3.length; i++) {
        const e = tipTaggerT3[i];
        if (e.t2 == this.current.t2 && e.en == t3) {
          this.t3tip = {
            original: e.en,
            title: e.cn,
            url: OssSourceURL + "tip-tagger-t3/" + e.t2 + "/" + e.pic,
          };
          return;
        }
      }
      this.t3tip = null;
    },
    t3TipEnableChange(val) {
      Cookies.set("disable-t3-tip", !val);
    },
    trendPointEnableChange(val) {
      Cookies.set("disable-trendpoint-tip", !val);
    },
    clickCheck() {
      this.trendPointVisible = true;
    },
    changeTrendPoint(list) {
      this.current.trendPoints = list;
      this.freshTrendPointLen();
    },
    freshTrendPointLen() {
      this.currentTrendPointLen = this.current.trendPoints
        ? Object.keys(this.current.trendPoints).length
        : 0;
    },
    goToTask() {
      window.open(
        window.location.origin + "/services/tagger/" + this.taskId,
        "_blank"
      );
    },
    fetchItem() {
      this.tableData = [];
      this.total = 0;
      this.brandList = [];
      this.brandId = null;
      let param = { date: this.date };
      if (this.userId) {
        param.userId = this.userId;
      }
      this.tableLoading = true;
      fetchItem(param)
        .then((response) => {
          this.tableLoading = false;
          if (response.success) {
            this.t2List.forEach((e, i) => {
              let disabled = true;
              if (response.result) {
                for (let j = 0; j < response.result.length; j++) {
                  const r = response.result[j];
                  if (e.value === r) {
                    disabled = false;
                    break;
                  }
                }
              }
              e.disabled = disabled;
              this.$set(this.t2List, i, e);
              if (!disabled && !this.item) {
                this.item = e.value;
                this.fetchBrandList();
              }
            });
          }
        })
        .catch(() => {
          this.tableLoading = false;
        });
    },
    filterChangeHandle(v) {
      if (v.checkBy) {
        if (v.checkBy.length > 0) {
          this.hasChecked = v.checkBy[0];
        } else {
          this.hasChecked = null;
        }
      } else if (v.createdAt) {
        if (v.createdAt.length > 0) {
          this.beforeDays = v.createdAt[0];
        } else {
          this.beforeDays = null;
        }
      }
      this.searchTaggerDetails();
    },
    tabBeforeChange() {
      if (this.needRecount) {
        this.$notify({
          title: "Please recount the classify changes first",
          type: "warning",
        });
        return false;
      }
      return true;
    },
    in7days(str) {
      if (str === "" || str === "0001-01-01T00:00:00Z") {
        return false;
      } else {
        return dayjs().subtract(7, "day").isBefore(dayjs(str));
      }
    },
  },
  watch: {
    date() {
      this.fetchUserList();
      this.item = null;
      this.fetchItem();
    },
    tab() {
      this.item = null;
      this.hasChecked = "false";
      this.beforeDays = 7;
      this.fetchItem();
    },
    expandKeys() {
      // 定位元素
      if (this.expandKeys && this.expandKeys.length > 0) {
        for (let i = 0; i < this.tableData.length; i++) {
          if (this.tableData[i].imageClassifyTempId === this.expandKeys[0]) {
            this.current = JSON.parse(JSON.stringify(this.tableData[i]));
            this.freshTrendPointLen();
            this.currentIdx = i;
            this.covertColor();
            break;
          }
        }

        let es = document.getElementsByClassName("row-" + this.expandKeys[0]);
        if (es.length > 0) {
          this.$nextTick(() => {
            es[0].scrollIntoView();
          });
        }
      }
    },
  },
  computed: {
    options() {
      if (!this.current || !this.current.t2) {
        return {};
      }
      let v = {};
      let mappings = this.$store.getters.taggerMapping[this.current.t2];
      if (mappings) {
        mappings.forEach((t) => {
          v[t] = this.$store.getters.taggerOptions[t];
        });
      }
      return v;
    },
    trendPointOptions() {
      if (!this.current || !this.current.t2) {
        return [];
      }
      return this.$store.getters.taggerTrendPoint[this.current.t2]
        ? this.$store.getters.taggerTrendPoint[this.current.t2]
        : [];
    },
    needRecount() {
      return this.$store.getters.needRecount;
    },
    adminType() {
      return this.$store.getters.my && this.$store.getters.my.adminType
        ? this.$store.getters.my.adminType
        : "";
    },
  },
};
</script>

<style lang="scss" scoped>
.basic-data-tagger {
  margin-top: 10px;

  .redtxt {
    color: red;
    font-weight: bold;
  }

  .task-btn {
    position: relative;
    z-index: 999;
    margin-left: 10px;
  }

  .table-avatar {
    vertical-align: middle;
    border: 1px solid #e8e8e8;
  }

  .check-icon {
    color: #67c23a;
  }

  .search-form {
    width: auto;
    margin-left: 10px;

    .search-form-gutter {
      margin-right: 20px;
    }
  }

  .tagger-table {
    .tagger-table-row {
      padding-left: 5px;

      .tagger-table-form {
        /deep/ .el-form-item__label {
          margin-left: 10px;
          padding-bottom: 0;
        }

        /deep/ .el-form-item__content {
          margin-top: -10px;
        }

        margin-bottom: 5px;
        margin-right: 0;

        .tagger-btn-container {
          padding-left: 5px !important;
          padding-right: 5px !important;
        }

        .el-select {
          margin-left: 5px;
          width: 90%;
        }

        .tagger-table-form-button {
          margin-left: 5px;
          width: 60%;
        }

        .tagger-color-picker {
          vertical-align: middle;
          margin-left: 5px;

          /deep/.el-color-picker__trigger {
            padding: 0;
            border: 0;
          }

          /deep/ .el-color-picker__color {
            border-color: #dcdfe6;
          }
        }

        .tagger-color-add {
          margin-left: 5px;
          padding: 7px 8px;
        }
      }
    }

    .tagger-table-footer {
      padding-top: 10px;
      padding-left: 10px;
    }

    /deep/ .el-table__expanded-cell {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .info {
    text-align: center;
    margin: 20px 0;
  }
}
</style>