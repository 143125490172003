<template>
  <div class="user-report">
    <el-tabs class="catalog" v-model="group" tab-position="left" @tab-click="tabChange">
      <el-tab-pane label="Consulting Report" name="monthly"></el-tab-pane>
      <el-tab-pane label="Street Trend" name="street"></el-tab-pane>
      <el-tab-pane label="Global Street Fashion" name="global"></el-tab-pane>
      <el-tab-pane label="Window Trend" name="window"></el-tab-pane>
      <el-tab-pane label="Product Reviews" name="product-reviews"></el-tab-pane>
      <el-tab-pane label="SNS" name="sns"></el-tab-pane>
      <el-tab-pane label="Item Prediction" name="item-prediction"></el-tab-pane>
      <el-tab-pane label="Trend Prediction" name="trend-prediction"></el-tab-pane>
    </el-tabs>
    <div class="content">
      <div class="new-btn">
        <el-button icon="el-icon-plus" size="small" type="primary" @click="openNewDialog">New</el-button>
      </div>
      <el-table :data="tableData" row-key="id">
        <el-table-column prop="name" label="Name"></el-table-column>
        <el-table-column prop="url" label="URL"></el-table-column>
        <el-table-column prop="thumbnailUrl" label="ThumbnailURL"></el-table-column>
        <el-table-column v-if="!anyUser" label="Users">
          <template slot-scope="scope">
            <span>{{
      scope.row.users && scope.row.users.length > 0
        ? scope.row.users[0].userName + " ... "
        : ""
    }}</span>
            <el-popover placement="bottom" width="666" trigger="click">
              <el-table :data="scope.row.users" row-key="userId">
                <el-table-column width="260" property="userName" label="UserName"></el-table-column>
                <el-table-column width="380" property="email" label="Email"></el-table-column>
              </el-table>
              <el-button slot="reference" size="small" round>{{
      (scope.row.users && scope.row.users.length
        ? scope.row.users.length
        : 0) + " total"
    }}</el-button>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="Detail" min-width="34">
          <template slot-scope="scope">
            <el-button type="primary" circle size="small" icon="el-icon-more" @click="openDetailDialog(scope.row)">
            </el-button>
          </template>
        </el-table-column>
        <el-table-column label="Delete" min-width="34">
          <template slot-scope="scope">
            <el-popconfirm title="Confirm delete?" @confirm="deleteUserReport(scope.row.id)">
              <el-button type="danger" icon="el-icon-delete" circle size="small" slot="reference"></el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <pagination v-show="total > 0" :total="total" :offset.sync="listQuery.offset" :limit.sync="listQuery.limit"
        :pagerCount="5" layout="sizes, prev, pager, next, total " @pagination="fetchData" />
    </div>
    <el-dialog :visible.sync="dialogVisible" @close="closeDialog" :title="dialogTitle" custom-class="group-dialog">
      <el-form ref="form" :model="form" :rules="rules" size="small" label-width="140px">
        <el-form-item label="Name" prop="name" required>
          <el-input placeholder="Please input file name" v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="URL" prop="url" required>
          <el-input v-model="form.url"></el-input>
        </el-form-item>
        <el-form-item label="ThumbnailURL" prop="thumbnailUrl" required>
          <el-input v-model="form.thumbnailUrl"></el-input>
        </el-form-item>
      </el-form>
      <div v-if="!anyUser" class="user-container">
        <user-selection-multi ref="userSelection" :ids="currentUserIds" @select="selectUserId" />
      </div>
      <span slot="footer">
        <el-button size="small" @click="closeDialog">Cancel</el-button>
        <el-button type="primary" size="small" @click="confirmUserReport">OK</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  fetchUserReport,
  createUserReport,
  updateUserReport,
  deleteUserReport,
} from "@/api/admin";
import Pagination from "@/components/Pagination";
import UserSelectionMulti from "./UserSelectionMulti";

export default {
  components: { Pagination, UserSelectionMulti },
  data() {
    return {
      group: "monthly",
      tableData: [],
      listQuery: {
        offset: 0,
        limit: 10,
      },
      total: 0,
      form: null,
      rules: {
        name: [{ required: true }],
        url: [{ required: true }],
        thumbnailUrl: [{ required: true }],
      },
      currentUserIds: [],
      currentUsers: [],
      dialogVisible: false,
    };
  },
  created() {
    this.initForm();
    this.fetchData();
  },
  methods: {
    initForm() {
      this.form = {
        url: "",
        thumbnailUrl: "",
      };
    },
    fetchData() {
      fetchUserReport({
        group: this.group,
        ...this.listQuery,
      }).then((response) => {
        if (response.success) {
          this.tableData = response.result.items || [];
          this.total = response.result.totalCount;
        }
      });
    },
    tabChange() {
      this.tableData = [];
      this.total = 0;
      this.fetchData();
    },
    active() {
      this.initForm();
    },
    selectUserId(ids) {
      this.currentUserIds = ids;
    },
    deleteUserReport(id) {
      deleteUserReport(id).then((response) => {
        if (response.success) {
          this.$notify({
            title: "Delete successful",
            type: "success",
          });
          this.fetchData();
        }
      });
    },
    closeDialog() {
      this.dialogVisible = false;
      this.$refs.form.resetFields();
      this.initForm();
      this.currentUserIds = [];
    },
    openNewDialog() {
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.userSelection && this.$refs.userSelection.reopen();
      });
    },
    openDetailDialog(row) {
      this.dialogVisible = true;
      this.form = row;
      this.currentUserIds = row.users
        ? row.users.map((t) => {
          return t.userId;
        })
        : [];
      this.$nextTick(() => {
        this.$refs.userSelection && this.$refs.userSelection.reopen();
      });
    },
    confirmUserReport() {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }
        if (!this.anyUser) {
          if (!this.currentUserIds || this.currentUserIds.length === 0) {
            this.$notify({
              title: "Please select user first",
              type: "warning",
            });
            return;
          }
          this.form.users = this.currentUserIds.map((id) => {
            return {
              userId: id,
            };
          });
        }
        if (!this.form.id || this.form.id === 0) {
          createUserReport({
            group: this.group,
            ...this.form,
          }).then((response) => {
            if (response.success) {
              this.$notify({
                title: "Create successful",
                type: "success",
              });
              this.closeDialog();
              this.fetchData();
            }
          });
        } else {
          updateUserReport(this.form.id, {
            group: this.group,
            ...this.form,
          }).then((response) => {
            if (response.success) {
              this.$notify({
                title: "Update successful",
                type: "success",
              });
              this.closeDialog();
              this.fetchData();
            }
          });
        }
      });
    },
    showPopover(row) {
      if (!this.anyUser) {
        this.currentUsers = row.users;
      }
    },
  },
  computed: {
    anyUser() {
      return this.group === 'item-prediction' || this.group === 'trend-prediction'
    },
    dialogTitle() {
      let prefix = "New";
      if (this.form.id && this.form.id > 0) {
        prefix = "Detail";
      }
      let content = "report";
      switch (this.group) {
        case "monthly":
          content = " consulting " + content;
          break;
        case "street":
          content = " street trend " + content;
          break;
        case "global":
          content = " global street trend " + content;
          break;
        case "window":
          content = " window trend " + content;
          break;
        case "product-reviews":
          content = " product reviews " + content;
          break;
        case "sns":
          content = " sns " + content;
          break;
        case "item-prediction":
          content = " item prediction " + content;
          break;
        case "trend-prediction":
          content = " trend prediction " + content;
          break;
      }
      return prefix + content;
    },
  },
};
</script>

<style lang="scss" scoped>
.user-report {
  min-height: 360px;

  .catalog {
    position: absolute;
  }

  .content {
    margin-left: 190px;
    margin-bottom: 10px;

    .new-btn {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }

  .group-dialog .el-form {
    margin-top: 10px;
    margin-bottom: -10px;
    width: 90%;
  }

  .user-container {
    margin-top: 30px;
    border: 1px solid #e8e8e8;
    border-radius: 5px;
    padding: 20px 10px 0;
  }
}
</style>