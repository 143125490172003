<template>
  <div
    class="image-trend-map"
    v-loading.fullscreen.lock="loading"
    :element-loading-text="$t('common.preparingData')"
  >
    <div class="search-content">
      <el-form :inline="true" size="small" class="search-form">
        <el-form-item label="User">
          <el-select style="width: 158px" v-model="currentUserId">
            <el-option
              v-for="item in userList"
              :key="item.id"
              :label="item.userName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="search-form-gutter" label="LinkDate">
          <el-select style="width: 128px" v-model="currentDate" clearable>
            <el-option
              v-for="item in dateList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <label class="date-vs">VS</label>
          <el-select
            style="width: 128px"
            v-model="currentCompareDate"
            clearable
          >
            <el-option
              v-for="item in dateList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="search-form-gutter" label="Type">
          <el-select style="width: 128px" v-model="currentType" clearable>
            <el-option
              v-for="item in typeList"
              :key="item"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
          <label class="date-vs">VS</label>
          <el-select
            style="width: 128px"
            v-model="currentCompareType"
            clearable
          >
            <el-option
              v-for="item in typeList"
              :key="item"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button icon="el-icon-search" type="primary" @click="fetchData">{{
            $t("common.search")
          }}</el-button>
        </el-form-item>
        <el-form-item>
          <el-button
            icon="el-icon-plus"
            type="warning"
            @click="openAddReportDialog"
            >{{ $t("common.new") }}</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="tableData" row-key="id" style="width: 100%">
      <el-table-column prop="linkDate" label="LinkDate"></el-table-column>
      <el-table-column
        prop="compareLinkDate"
        label="CompareLinkDate"
      ></el-table-column>
      <el-table-column prop="type" label="Type"></el-table-column>
      <el-table-column prop="compareType" label="CompareType"></el-table-column>
      <el-table-column label="CreateAt">
        <template slot-scope="scope">
          <i class="el-icon-time"></i>
          <span style="margin-left: 10px">{{
            scope.row.createdAt | displayTime()
          }}</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('common.report')" min-width="80">
        <template slot-scope="scope">
          <a :href="scope.row.url | ossUrl">
            <el-button
              class="excel-dl-btn"
              icon="el-icon-download"
              size="small"
              >{{ $t("common.download") }}</el-button
            >
          </a>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-show="total > 0"
      :total="total"
      :offset.sync="listQuery.offset"
      :limit.sync="listQuery.limit"
      layout="sizes, prev, pager, next, total "
      @pagination="fetchData"
    />
    <el-dialog
      title="Create Image Trend Map Report"
      :visible.sync="dialogVisible"
      @close="closeAddReportDialog"
    >
      <el-form
        class="report-form"
        ref="form"
        :model="form"
        size="small"
        :rules="rules"
      >
        <el-form-item label="User" required>
          <el-select v-model="currentUserId">
            <el-option
              v-for="item in userList"
              :key="item.id"
              :label="item.userName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Date" key="date" required>
          <el-form-item class="inline-form-item" prop="date">
            <el-select v-model="form.date" clearable multiple>
              <el-option
                v-for="item in dateList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <label class="date-vs">VS</label>
          <el-form-item class="inline-form-item" prop="compareDate">
            <el-select v-model="form.compareDate" clearable multiple>
              <el-option
                v-for="item in dateList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form-item>
        <el-form-item label="Type" key="type" required>
          <el-form-item class="inline-form-item" prop="type">
            <el-select v-model="form.type" clearable multiple>
              <el-option
                v-for="item in typeList"
                :key="item"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>
          <label class="date-vs">VS</label>
          <el-form-item class="inline-form-item" prop="compareType">
            <el-select v-model="form.compareType" clearable multiple>
              <el-option
                v-for="item in typeList"
                :key="item"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button size="small" @click="closeAddReportDialog">Cancel</el-button>
        <el-button type="primary" size="small" @click="confirmReport"
          >OK</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  fetchUser,
  fetchBasicRecord,
  fetchUserBrandType,
  fetchImageTrendMap,
  exportImageTrendMap,
} from "@/api/admin";
import Pagination from "@/components/Pagination";
export default {
  components: { Pagination },
  data() {
    return {
      currentUserId: null,
      currentDate: null,
      currentCompareDate: null,
      currentType: null,
      currentCompareType: null,
      userList: [],
      dateList: [],
      typeList: [],
      tableData: [],
      listQuery: {
        offset: 0,
        limit: 10,
      },
      total: 0,
      dialogVisible: false,
      form: {
        date: [],
        compareDate: [],
        type: [],
        compareType: [],
      },
      rules: {
        date: [{ required: true, trigger: "change" }],
        compareDate: [
          {
            required: true,
            trigger: "change",
          },
        ],
        type: [
          {
            required: true,
            trigger: "change",
          },
        ],
        compareType: [
          {
            required: true,
            trigger: "change",
          },
        ],
      },
      timer: null,
      loading: false,
    };
  },
  beforeDestroy() {
    clearTimeout(this.timer);
  },
  methods: {
    fetchData() {
      fetchImageTrendMap({
        userId: this.currentUserId,
        date: this.currentDate,
        compareDate: this.currentCompareDate,
        type: this.currentType,
        compareType: this.currentCompareType,
        ...this.listQuery,
      }).then((response) => {
        if (response.success) {
          this.tableData = response.result.items || [];
          this.total = response.result.totalCount;
        }
      });
    },
    fetchUsers() {
      fetchUser({ analysisEnable: "true", limit: -1 }).then((response) => {
        if (response.success) {
          this.userList = response.result.items || [];
          if (this.userList.length > 0) {
            this.currentUserId = this.userList[0].id;
          }
        }
      });
    },
    fetchDates() {
      if (!this.currentUserId) {
        return;
      }
      this.dateList = [];
      fetchBasicRecord({
        period: "week",
        userId: this.currentUserId,
        type: "sales",
        limit: -1,
      }).then((response) => {
        if (response.success) {
          response.result &&
            response.result.items &&
            response.result.items.forEach((e) => {
              this.dateList.push({
                value: e.date,
                label: e.linkDate,
              });
            });
        }
      });
    },
    fetchTypes() {
      if (!this.currentUserId) {
        return;
      }
      this.typeList = [];
      fetchUserBrandType(this.currentUserId).then((response) => {
        this.typeList = response.result.items || [];
      });
    },
    openAddReportDialog() {
      this.dialogVisible = true;
    },
    closeAddReportDialog() {
      this.dialogVisible = false;
    },
    checkExist(cb) {
      fetchImageTrendMap({
        userId: this.currentUserId,
        date: this.form.date.join(","),
        compareDate: this.form.compareDate.join(","),
        type: this.form.type.join(","),
        compareType: this.form.compareType.join(","),
        limit: 1,
      })
        .then((response) => {
          if (response.success) {
            if (cb) {
              cb(
                response.result.items && response.result.items.length > 0
                  ? response.result.items[0].url
                  : null
              );
            }
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    fetchUrl() {
      this.checkExist((url) => {
        if (url) {
          this.$refs.form.resetFields();
          clearTimeout(this.timer);
          this.loading = false;
          this.fetchData();
        } else {
          this.timer = setTimeout(() => {
            this.fetchUrl();
          }, 2000);
        }
      });
    },
    confirmReport() {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }
        let doExport = () => {
          this.loading = true;
          let params = { userId: this.currentUserId };
          params.date = this.form.date.join(",");
          params.compareDate = this.form.compareDate.join(",");
          params.type = this.form.type.join(",");
          params.compareType = this.form.compareType.join(",");
          exportImageTrendMap(params).then((response) => {
            if (response.success) {
              this.timer = setTimeout(() => {
                this.fetchUrl();
              }, 2000);
            }
          });
        };
        this.closeAddReportDialog();
        this.checkExist((url) => {
          if (url) {
            this.$confirm(
              this.$t("analysis.reportRegenerate"),
              this.$t("common.pleaseConfirm"),
              {
                type: "warning",
              }
            ).then(() => {
              doExport();
            });
          } else {
            doExport();
          }
        });
      });
    },
    active() {
      this.fetchUsers();
    },
  },
  watch: {
    currentUserId() {
      this.currentDate = null;
      this.currentCompareDate = null;
      this.currentType = null;
      this.currentCompareType = null;
      this.fetchDates();
      this.fetchTypes();
      this.fetchData();
    },
  },
};
</script>
<style lang="scss" scoped>
.image-trend-map {
  .date-vs {
    vertical-align: middle;
    font-size: 14px;
    color: #606266;
    margin-left: 12px;
    margin-right: 12px;
  }
  .report-form {
    margin-top: 20px;
  }
  .inline-form-item {
    display: inline-block;
    margin-bottom: 0;
  }
}
</style>