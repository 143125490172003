<template>
  <div class="image-trend" v-loading.fullscreen.lock="loading" :element-loading-text="$t('common.preparingData')">
    <div class="search-content">
      <el-form :inline="true" size="small" class="search-form">
        <el-form-item label="User">
          <el-select style="width: 158px" v-model="currentUserId">
            <el-option v-for="item in userList" :key="item.id" :label="item.userName" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="search-form-gutter" label="LinkDate">
          <el-select style="width: 128px" v-model="currentDate" clearable @change="changeDates">
            <el-option v-for="item in dateList" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
          <label class="date-vs">VS</label>
          <el-select style="width: 128px" v-model="currentCompareDate" clearable @change="changeDates">
            <el-option v-for="item in dateList" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="search-form-gutter" label="Brand">
          <el-select style="width: 198px" v-model="currentBrands" clearable multiple>
            <el-option v-for="item in brandList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
          <label class="date-vs">VS</label>
          <el-select style="width: 198px" v-model="currentCompareBrands" clearable multiple>
            <el-option v-for="item in brandList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="search-form-gutter" label="Item">
          <el-select style="width: 298px" v-model="currentItems" clearable multiple>
            <el-option v-for="item in itemList" :key="item.value" :label="item.label" :value="item.value"
              :disabled="item.disabled"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button icon="el-icon-search" type="primary" @click="fetchData">{{
            $t("common.search")
          }}</el-button>
        </el-form-item>
        <el-form-item>
          <el-button icon="el-icon-plus" type="warning" @click="openAddReportDialog">{{ $t("common.new") }}</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="tableData" row-key="id" style="width: 100%">
      <el-table-column prop="linkDate" label="LinkDate"></el-table-column>
      <el-table-column prop="compareLinkDate" label="CompareLinkDate"></el-table-column>
      <el-table-column prop="brandNames" label="Brands"></el-table-column>
      <el-table-column prop="compareBrandNames" label="CompareBrands"></el-table-column>
      <el-table-column label="CreateAt">
        <template slot-scope="scope">
          <i class="el-icon-time"></i>
          <span style="margin-left: 10px">{{
            scope.row.createdAt | displayTime()
          }}</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('common.report')" min-width="80">
        <template slot-scope="scope">
          <a :href="scope.row.url | ossUrl">
            <el-button class="excel-dl-btn" icon="el-icon-download" size="small">{{ $t("common.download") }}</el-button>
          </a>
        </template>
      </el-table-column>
    </el-table>
    <pagination v-show="total > 0" :total="total" :offset.sync="listQuery.offset" :limit.sync="listQuery.limit"
      layout="sizes, prev, pager, next, total " @pagination="fetchData" />
    <el-dialog title="Create Image Trend Map Report" :visible.sync="dialogVisible" @close="closeAddReportDialog">
      <el-form class="report-form" ref="form" :model="form" size="small" :rules="rules" label-position="right"
        label-width="80px">
        <el-form-item label="User" required>
          <el-select v-model="currentUserId">
            <el-option v-for="item in userList" :key="item.id" :label="item.userName" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Date" key="date" required>
          <el-form-item class="inline-form-item" prop="date">
            <el-select v-model="form.date" clearable multiple @change="changeFormDates">
              <el-option v-for="item in dateList" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <label class="date-vs">VS</label>
          <el-form-item class="inline-form-item" prop="compareDate">
            <el-select v-model="form.compareDate" clearable multiple @change="changeFormDates">
              <el-option v-for="item in dateList" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
        </el-form-item>
        <el-form-item label="Brands" key="brands" required>
          <el-form-item class="inline-form-item" prop="brands">
            <el-select v-model="form.brands" clearable multiple>
              <el-option v-for="item in formBrandList" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <label class="date-vs">VS</label>
          <el-form-item class="inline-form-item" prop="compareBrands">
            <el-select v-model="form.compareBrands" clearable multiple>
              <el-option v-for="item in formBrandList" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        </el-form-item>
        <el-form-item label="Items" key="items" prop="items" required>
          <el-select style="width: 298px" v-model="form.items" clearable multiple>
            <el-option v-for="item in formItemList" :key="item.value" :label="item.label" :value="item.value"
              :disabled="item.disabled"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button size="small" @click="closeAddReportDialog">Cancel</el-button>
        <el-button type="primary" size="small" @click="confirmReport">OK</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  fetchUser,
  fetchBasicRecord,
  fetchImageTrendMapDetail,
  exportImageTrendMapDetail,
  fetchAnalysisUserBrands,
  fetchAnalysisUserItems,
} from "@/api/admin";
import Pagination from "@/components/Pagination";
export default {
  components: { Pagination },
  data() {
    return {
      currentUserId: null,
      currentDate: null,
      currentCompareDate: null,
      currentBrands: [],
      currentCompareBrands: [],
      currentItems: [],
      userList: [],
      dateList: [],
      brandList: [],
      itemList: [],
      formBrandList: [],
      formItemList: [],
      tableData: [],
      listQuery: {
        offset: 0,
        limit: 10,
      },
      total: 0,
      dialogVisible: false,
      form: {
        date: [],
        compareDate: [],
        brands: [],
        compareBrands: [],
        items: []
      },
      rules: {
        date: [{ required: true, trigger: "change" }],
        compareDate: [
          {
            required: true,
            trigger: "change",
          },
        ],
        brands: [
          {
            required: true,
            trigger: "change",
          },
        ],
        compareBrands: [
          {
            required: true,
            trigger: "change",
          },
        ],
        items: [
          {
            required: true,
            trigger: "change",
          },
        ],
      },
      timer: null,
      loading: false,
      mode: "group"
    };
  },
  beforeDestroy() {
    clearTimeout(this.timer);
  },
  methods: {
    resetItemList(isForm) {
      let t2Flatten = this.$store.getters.t2Flatten;
      if (isForm) {
        this.formItemList = [];
      } else {
        this.itemList = [];
      }
      t2Flatten &&
        t2Flatten.forEach((t) => {
          t.disabled = true;
          if (isForm) {
            this.formItemList.push(t);
          } else {
            this.itemList.push(t);
          }
        });
    },
    fetchData() {
      fetchImageTrendMapDetail({
        userId: this.currentUserId,
        date: this.currentDate,
        compareDate: this.currentCompareDate,
        brands: this.currentBrands.join(","),
        compareBrands: this.currentCompareBrands.join(","),
        items: this.currentItems.join(","),
        ...this.listQuery,
      }).then((response) => {
        if (response.success) {
          this.tableData = response.result.items || [];
          this.total = response.result.totalCount;
        }
      });
    },
    fetchUsers() {
      fetchUser({ analysisEnable: "true", limit: -1 }).then((response) => {
        if (response.success) {
          this.userList = response.result.items || [];
          if (this.userList.length > 0) {
            this.currentUserId = this.userList[0].id;
          }
        }
      });
    },
    fetchDates() {
      if (!this.currentUserId) {
        return;
      }
      this.dateList = [];
      fetchBasicRecord({
        period: "week",
        userId: this.currentUserId,
        type: "sales",
        limit: -1,
      }).then((response) => {
        if (response.success) {
          response.result &&
            response.result.items &&
            response.result.items.forEach((e) => {
              this.dateList.push({
                value: e.date,
                label: e.linkDate,
              });
            });
        }
      });
    },
    fetchBrands() {
      this.currentBrands = [];
      this.currentCompareBrands = [];
      this.brandList = [];
      if (!this.currentUserId) {
        return;
      }
      fetchAnalysisUserBrands(this.currentUserId, { dates: this.datesString() }).then((response) => {
        if (response.success) {
          this.brandList =
            response.result && response.result.items
              ? response.result.items
              : [];
        }
      });
    },
    fetchFormBrands() {
      this.form.brands = [];
      this.form.compareBrands = [];
      this.formBrandList = [];
      if (!this.currentUserId) {
        return;
      }
      fetchAnalysisUserBrands(this.currentUserId, { dates: this.formDatesString() }).then((response) => {
        if (response.success) {
          this.formBrandList =
            response.result && response.result.items
              ? response.result.items
              : [];
        }
      });
    },
    fetchItems() {
      this.currentItems = [];
      this.resetItemList(false);
      if (!this.currentUserId) {
        return;
      }
      fetchAnalysisUserItems(this.currentUserId, { dates: this.datesString() }).then((response) => {
        if (response.success && response.result) {
          this.itemList.forEach((e, i) => {
            let disabled = true;
            for (let j = 0; j < response.result.length; j++) {
              const r = response.result[j];
              if (e.value === r) {
                disabled = false;
                break;
              }
            }
            e.disabled = disabled;
            this.$set(this.itemList, i, e);
          });
        }
      });
    },
    fetchFormItems() {
      this.form.items = [];
      this.resetItemList(true);
      if (!this.currentUserId) {
        return;
      }
      fetchAnalysisUserItems(this.currentUserId, { dates: this.formDatesString() }).then((response) => {
        if (response.success && response.result) {
          this.formItemList.forEach((e, i) => {
            let disabled = true;
            for (let j = 0; j < response.result.length; j++) {
              const r = response.result[j];
              if (e.value === r) {
                disabled = false;
                break;
              }
            }
            e.disabled = disabled;
            this.$set(this.formItemList, i, e);
          });
        }
      });
    },
    openAddReportDialog() {
      this.dialogVisible = true;
      this.resetItemList(true);
    },
    closeAddReportDialog() {
      this.dialogVisible = false;
    },
    checkExist(cb) {
      fetchImageTrendMapDetail({
        userId: this.currentUserId,
        date: this.form.date.join(","),
        compareDate: this.form.compareDate.join(","),
        brand: this.form.brands.join(","),
        compareBrands: this.form.compareBrands.join(","),
        items: this.form.items.join(","),
        limit: 1,
      })
        .then((response) => {
          if (response.success) {
            if (cb) {
              cb(
                response.result.items && response.result.items.length > 0
                  ? response.result.items[0].url
                  : null
              );
            }
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    fetchUrl() {
      this.checkExist((url) => {
        if (url) {
          this.$refs.form.resetFields();
          clearTimeout(this.timer);
          this.loading = false;
          this.fetchData();
        } else {
          this.timer = setTimeout(() => {
            this.fetchUrl();
          }, 2000);
        }
      });
    },
    confirmReport() {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }
        let doExport = () => {
          this.loading = true;
          let params = { userId: this.currentUserId };
          params.date = this.form.date.join(",");
          params.compareDate = this.form.compareDate.join(",");
          params.brands = this.form.brands.join(",");
          params.compareBrands = this.form.compareBrands.join(",");
          params.items = this.form.items.join(",");
          exportImageTrendMapDetail(params).then((response) => {
            if (response.success) {
              this.timer = setTimeout(() => {
                this.fetchUrl();
              }, 2000);
            }
          });
        };
        this.closeAddReportDialog();
        this.checkExist((url) => {
          if (url) {
            this.$confirm(
              this.$t("analysis.reportRegenerate"),
              this.$t("common.pleaseConfirm"),
              {
                type: "warning",
              }
            ).then(() => {
              doExport();
            });
          } else {
            doExport();
          }
        });
      });
    },
    active() {
      this.$store.dispatch("category/fresh").then(() => {
        this.resetItemList(false);
        this.fetchUsers();
      });
    },
    datesString() {
      let v = [];
      if (this.currentDate) {
        v.push(this.currentDate);
      }
      if (this.currentCompareDate) {
        v.push(this.currentCompareDate);
      }
      return v.length > 0 ? v.join(",") : ""
    },
    formDatesString() {
      let v = [];
      if (this.form.date) {
        v.push(this.form.date);
      }
      if (this.form.compareDate) {
        v.push(this.form.compareDate);
      }
      return v.length > 0 ? v.join(",") : ""
    },
    changeDates() {
      this.fetchBrands();
      this.fetchItems();
    },
    changeFormDates() {
      this.fetchFormBrands();
      this.fetchFormItems();
    },
  },
  watch: {
    currentUserId() {
      this.currentDate = null;
      this.currentCompareDate = null;
      this.currentBrands = [];
      this.currentCompareBrands = [];
      this.currentItems = [];
      this.fetchDates();
      this.fetchBrands();
      this.fetchItems();
      this.fetchData();
    },
  },
};
</script>
<style lang="scss" scoped>
.image-trend {
  .date-vs {
    vertical-align: middle;
    font-size: 14px;
    color: #606266;
    margin-left: 12px;
    margin-right: 12px;
  }

  .report-form {
    margin-top: 20px;
  }

  .inline-form-item {
    display: inline-block;
    margin-bottom: 0;
  }
}
</style>