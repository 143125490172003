<template>
  <div class="notification">
    <div class="new-btn">
      <el-button
        icon="el-icon-plus"
        size="small"
        type="primary"
        @click="dialogVisible = true"
        >New</el-button
      >
    </div>
    <el-table :data="tableData" row-key="id">
      <el-table-column
        prop="title"
        label="Title"
        min-width="160"
      ></el-table-column>
      <el-table-column prop="imageUrl" label="Image" width="180">
        <template slot-scope="scope">
          <el-image
            style="width: 160px; height: 120px"
            v-if="scope.row.imageUrl"
            :src="scope.row.imageUrl | imageUrl(false)"
            fit="contain"
          ></el-image>
        </template>
      </el-table-column>
      <el-table-column label="Start At" min-width="110">
        <template slot-scope="scope">
          <i class="el-icon-time"></i>
          <span style="margin-left: 10px">{{
            scope.row.startAt | displayTime()
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="End At" min-width="110">
        <template slot-scope="scope">
          <i class="el-icon-time"></i>
          <span style="margin-left: 10px">{{
            scope.row.endAt | displayTime()
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Create At" min-width="110">
        <template slot-scope="scope">
          <i class="el-icon-time"></i>
          <span style="margin-left: 10px">{{
            scope.row.createdAt | displayTime()
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Delete" min-width="50" align="center">
        <template slot-scope="scope">
          <el-popconfirm
            title="Delete Notification?"
            @confirm="deleteNotification(scope.row.id)"
          >
            <el-button
              type="danger"
              icon="el-icon-delete"
              circle
              size="small"
              slot="reference"
            ></el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-show="total > 0"
      :total="total"
      :offset.sync="listQuery.offset"
      :limit.sync="listQuery.limit"
      layout="sizes, prev, pager, next, total "
      @pagination="fetchData"
    />
    <el-dialog
      :visible.sync="dialogVisible"
      @close="closeDialog"
      title="Create Notification"
      custom-class="new-dialog"
    >
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        size="small"
        label-width="120px"
      >
        <el-form-item label="Title" prop="title">
          <el-input v-model="form.title"></el-input>
        </el-form-item>
        <el-form-item label="Image">
          <el-upload
            :action="aiApiRoot + '/v1/images'"
            accept=".jpg, .jpeg, .png"
            :headers="{ Authorization: this.token }"
            :data="{ group: 'notification' }"
            list-type="picture-card"
            :on-success="handleIconUploadSuccess"
            :fileList="imageUploadList"
          >
            <el-image
              style="width: 160px; height: 120px"
              v-if="form.imageUrl"
              :src="form.imageUrl | imageUrl(false)"
              fit="contain"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="StartAt" prop="startAt">
          <el-date-picker
            v-model="form.startAt"
            type="datetime"
            :clearable="false"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="EndAt" prop="endAt">
          <el-date-picker
            v-model="form.endAt"
            type="datetime"
            :clearable="false"
          ></el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button size="small" @click="closeDialog">Cancel</el-button>
        <el-button type="primary" size="small" @click="confirmNotification"
          >OK</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  fetchNotification,
  createNotification,
  deleteNotification,
} from "@/api/admin";
import { getToken } from "@/utils/auth";
import { aiApiRoot } from "@/utils/server";
import Pagination from "@/components/Pagination";
import dayjs from "dayjs";
export default {
  components: { Pagination },
  data() {
    return {
      token: "Bearer " + getToken(),
      aiApiRoot,
      tableData: [],
      listQuery: {
        offset: 0,
        limit: 10,
      },
      total: 0,
      form: null,
      rules: {
        title: [{ required: true }],
        startAt: [{ required: true }],
        endAt: [{ required: true }],
      },
      dialogVisible: false,
      loading: false,
      imageUploadList: [],
    };
  },
  created() {
    this.initForm();
  },
  methods: {
    initForm() {
      this.form = {
        title: "",
        imageUrl: "",
        startAt: dayjs(),
        endAt: dayjs(),
      };
    },
    closeDialog() {
      this.dialogVisible = false;
      this.$refs.form.resetFields();
      this.initForm();
    },
    fetchData() {
      fetchNotification(this.listQuery).then((response) => {
        if (response.success) {
          this.tableData = response.result.items || [];
          this.total = response.result.totalCount;
        }
      });
    },
    active() {
      this.initForm();
      this.fetchData();
    },
    deleteNotification(id) {
      deleteNotification(id).then((response) => {
        if (response.success) {
          this.$notify({
            title: "Delete successful",
            type: "success",
          });
          this.fetchData();
        }
      });
    },
    confirmNotification() {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }
        if (!this.form.startAt.isBefore(this.form.endAt)) {
          this.$message.error("StartAt should before EndAt");
          return;
        }
        createNotification(this.form).then((response) => {
          if (response.success) {
            this.$notify({
              title: "Create successful",
              type: "success",
            });
            this.closeDialog();
            this.fetchData();
          }
        });
      });
    },
    handleIconUploadSuccess(res) {
      if (res.result && res.result.length > 0) {
        this.form.imageUrl = res.result[0].url;
      }
      this.imageUploadList = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.notification {
  /deep/ .el-upload--picture-card {
    width: 164px;
    height: 123px;
    line-height: 126px;
  }
  /deep/ .el-upload-list--picture-card .el-upload-list__item {
    width: 164px;
    height: 123px;
    line-height: 126px;
  }

  .new-btn {
    width: 100%;
    margin-bottom: 10px;
  }

  .new-dialog .el-form {
    margin-top: 10px;
    margin-bottom: -10px;
    width: 90%;
  }
}
</style>