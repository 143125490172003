<template>
  <div class="organization">
    <div class="new-btn">
      <el-button icon="el-icon-plus" size="small" type="primary" @click="openNewDialog">New</el-button>
    </div>
    <el-table :data="tableData" :expand-row-keys="expandKeys" :row-style="{ cursor: 'pointer' }"
      @row-click="clickRowHandle" @expand-change="clickRowHandle" row-key="id" v-loading="loadingData">
      <el-table-column type="expand">
        <template slot-scope="props">
          <el-table :data="subTableData" row-key="id" :key="props.row.id" size="mini" v-loading="loadingUserList">
            <el-table-column prop="userName" label="User Name"></el-table-column>
            <el-table-column prop="email" label="Email"></el-table-column>
            <el-table-column prop="phoneNo" label="Phone No"></el-table-column>
            <el-table-column label="Last Signin At" min-width="100" width="180">
              <template slot-scope="scope">
                <i class="el-icon-time"></i>
                <span style="margin-left: 10px">{{
        scope.row.lastSigninAt | displayTime()
      }}</span>
              </template>
            </el-table-column>
            <el-table-column label="Last Active At" min-width="100" width="180">
              <template slot-scope="scope">
                <i class="el-icon-time"></i>
                <span style="margin-left: 10px">{{
          scope.row.lastActiveAt | displayTime()
        }}</span>
              </template>
            </el-table-column>
            <el-table-column label="Admin">
              <template slot-scope="scope">
                <span>{{ displayAdminType(scope.row.adminType) }}</span>
              </template>
            </el-table-column>
          </el-table>
          <pagination v-show="subTotal > 0" :total="subTotal" :offset.sync="subListQuery.offset"
            :limit.sync="subListQuery.limit" layout="prev, pager, next, total " :small="true"
            @pagination="fetchSubData" />
        </template>
      </el-table-column>
      <el-table-column prop="id" label="Id" width="90"></el-table-column>
      <el-table-column prop="name" label="Name"></el-table-column>
      <el-table-column label="Detail" min-width="34">
        <template slot-scope="scope">
          <el-button type="primary" circle size="small" icon="el-icon-more" @click.stop="openDetailDialog(scope.row)">
          </el-button>
        </template>
      </el-table-column>
      <el-table-column label="Delete" min-width="34">
        <template slot-scope="scope">
          <el-popconfirm title="Confirm delete?" @confirm="deleteOrganization(scope.row.id)">
            <el-button type="danger" icon="el-icon-delete" circle size="small" slot="reference"></el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <pagination v-show="total > 0" :total="total" :offset.sync="listQuery.offset" :limit.sync="listQuery.limit"
      layout="sizes, prev, pager, next, total " @pagination="fetchData" />
    <el-dialog :visible.sync="dialogVisible" @close="closeDialog" :title="form.id && form.id !== 0 ? 'Update Organization' : 'Create Organization'
        " custom-class="add-dialog">
      <el-form ref="form" :model="form" :rules="rules" size="small" label-width="140px">
        <el-form-item label="Name" prop="name" required>
          <el-input v-model="form.name"></el-input>
        </el-form-item>
      </el-form>
      <div class="user-container">
        <user-selection-multi ref="userSelection" :ids="form.userIds" @select="selectUserId" />
      </div>
      <span slot="footer">
        <el-button size="small" @click="closeDialog">Cancel</el-button>
        <el-button type="primary" size="small" @click="confirmOrganization">OK</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  fetchOrganization,
  fetchOneOrganization,
  createOrganization,
  updateOrganization,
  deleteOrganization,
  fetchUser,
} from "@/api/admin";
import Pagination from "@/components/Pagination";
import UserSelectionMulti from "./UserSelectionMulti";

export default {
  components: { Pagination, UserSelectionMulti },
  data() {
    return {
      openDialog: false,
      tableData: [],
      listQuery: {
        offset: 0,
        limit: 10,
      },
      total: 0,
      expandKeys: [],
      subTableData: [],
      subListQuery: {
        offset: 0,
        limit: 5,
      },
      subTotal: 0,
      form: null,
      rules: {
        name: [{ required: true }],
      },
      dialogVisible: false,
      loadingData: false,
      loadingUserList: false,
    };
  },
  created() {
    this.initForm();
  },
  methods: {
    initForm() {
      this.form = {
        name: "",
        userIds: [],
      };
    },
    fetchData() {
      this.loadingData = true;
      fetchOrganization(this.listQuery).then((response) => {
        if (response.success) {
          this.tableData = response.result.items || [];
          this.total = response.result.totalCount;
        }
      }).finally(() => {
        this.loadingData = false;
      });
    },
    fetchSubData() {
      this.loadingUserList = true;
      fetchUser({
        organizationId: this.expandKeys[0],
        ...this.subListQuery,
      }).then((response) => {
        if (response.success) {
          this.subTableData = response.result.items || [];
          this.subTotal = response.result.totalCount;
        }
      }).finally(() => {
        this.loadingUserList = false;
      });
    },
    active() {
      this.fetchData();
    },
    selectUserId(ids) {
      this.form.userIds = ids;
    },
    closeDialog() {
      this.dialogVisible = false;
      this.initForm();
      this.$refs.form.resetFields();
      this.expandKeys = [];
    },
    openNewDialog() {
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.userSelection && this.$refs.userSelection.reopen();
      });
    },
    openDetailDialog(row) {
      this.dialogVisible = true;
      this.form = { id: row.id, name: row.name, userIds: [] };
      fetchOneOrganization(row.id).then((response) => {
        if (response.success) {
          this.form = response.result;
          this.$nextTick(() => {
            this.$refs.userSelection && this.$refs.userSelection.reopen();
          });
        }
      });
    },
    confirmOrganization() {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }
        if (!this.form.id || this.form.id === 0) {
          createOrganization(this.form).then((response) => {
            if (response.success) {
              this.$notify({
                title: "Create successful",
                type: "success",
              });
              this.closeDialog();
              this.fetchData();
            }
          });
        } else {
          let migrateAnalysis = false;
          this.$confirm(
            "Migrate basic data, brands, items for new users of this organization ?",
            "Prompt",
            {
              type: "info",
              cancelButtonText: "No",
              confirmButtonText: "Yes",
            }
          )
            .then(() => {
              migrateAnalysis = true;
            })
            .catch(() => { })
            .finally(() => {
              updateOrganization(this.form.id, {
                organization: this.form,
                migrateAnalysis,
              }).then((response) => {
                if (response.success) {
                  this.$notify({
                    title: "Update successful",
                    type: "success",
                  });
                  this.closeDialog();
                  this.fetchData();
                }
              });
            });
        }
      });
    },
    deleteOrganization(id) {
      deleteOrganization(id).then((response) => {
        if (response.success) {
          this.$notify({
            title: "Update successful",
            type: "success",
          });
          this.fetchData();
        }
      });
    },
    clickRowHandle(row) {
      if (
        this.expandKeys &&
        this.expandKeys.length > 0 &&
        this.expandKeys[0] === row.id
      ) {
        this.expandKeys = [];
      } else {
        this.expandKeys = [row.id];
        this.subTableData = [];
        this.subListQuery = {
          offset: 0,
          limit: 5,
        };
        this.subTotal = 0;
        this.fetchSubData();
      }
    },
    displayAdminType(adminType) {
      switch (adminType) {
        case "root":
          return "Root";
        case "checker":
          return "Checker";
        default:
          return "Not";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.organization {
  .new-btn {
    width: 100%;
    margin-bottom: 10px;
  }

  /deep/ .add-dialog .el-form {
    margin-top: 10px;
    margin-bottom: -10px;
    width: 90%;
  }

  .user-container {
    margin-top: 30px;
    border: 1px solid #e8e8e8;
    border-radius: 5px;
    padding: 20px 10px 0;
  }
}
</style>