<template>
  <div class="brand" v-loading.fullscreen.lock="loading">
    <div class="toolbar">
      <div class="new-btn">
        <el-button icon="el-icon-plus" size="small" type="primary" @click="dialogVisible = true">New</el-button>
        <el-button icon="el-icon-plus" size="small" type="warning" @click="dialogUploadVisible = true">Upload</el-button>
      </div>
      <div class="search-bar">
        <el-input size="small" v-model="listQuery.name">
          <el-button slot="append" icon="el-icon-search" @click="fetchData"></el-button>
        </el-input>
      </div>
    </div>
    <el-table :data="tableData" row-key="id" key="brand">
      <el-table-column prop="code" label="Code" min-width="50"></el-table-column>
      <el-table-column prop="name" label="Name" min-width="70"></el-table-column>
      <el-table-column prop="platform" label="Platform" min-width="50">
        <template slot-scope="scope">
          {{ platformLabel(scope.row.platform) }}
        </template>
      </el-table-column>
      <el-table-column prop="ossDirName" label="OssDirName"></el-table-column>
      <el-table-column prop="displayName" label="DisplayName"></el-table-column>
      <el-table-column label="IconImage">
        <template slot-scope="scope">
          <el-image v-if="scope.row.iconImageUrl" :src="scope.row.iconImageUrl | imageThumbnailUrl(false)"
            fit="contain"></el-image>
        </template>
      </el-table-column>
      <el-table-column prop="shopUrl" label="ShopURL"></el-table-column>
      <el-table-column prop="productUrl" label="ProductURL"></el-table-column>
      <el-table-column prop="when" label="When" min-width="50"></el-table-column>
      <el-table-column prop="category" label="Category" min-width="70"></el-table-column>
      <el-table-column label="Update" min-width="50">
        <template slot-scope="scope">
          <el-button type="primary" icon="el-icon-edit" circle size="small"
            @click="openUpdateDialog(scope.row)"></el-button>
        </template>
      </el-table-column>
      <el-table-column label="Delete" min-width="50">
        <template slot-scope="scope">
          <el-popconfirm title="Delete Brand?" @confirm="deleteBrand(scope.row.id)">
            <el-button type="danger" icon="el-icon-delete" circle size="small" slot="reference"></el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <pagination v-show="total > 0" :total="total" :offset.sync="listQuery.offset" :limit.sync="listQuery.limit"
      layout="sizes, prev, pager, next, total " @pagination="fetchData" />
    <el-dialog :visible.sync="dialogVisible" @close="closeUpdateDialog"
      :title="currentId === 0 ? 'Create Brand' : 'Update Brand'" custom-class="brand-dialog">
      <el-form ref="form" :model="form" :rules="rules" size="small" label-width="120px">
        <el-form-item label="Code" prop="code" required>
          <el-input v-model="form.code"></el-input>
        </el-form-item>
        <el-form-item label="Name" prop="name" required>
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="Platform" prop="platform" required>
          <el-select v-model="form.platform" size="small">
            <el-option label="TMall" value="tmall"></el-option>
            <el-option label="TikTok" value="tiktok"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="OssDirName" prop="ossDirName" required>
          <el-input v-model="form.ossDirName"></el-input>
        </el-form-item>
        <el-form-item label="DisplayName" prop="displayName">
          <el-input v-model="form.displayName"></el-input>
        </el-form-item>
        <el-form-item label="IconImage">
          <el-upload :action="aiApiRoot + '/v1/images'" accept=".jpg, .jpeg, .png"
            :headers="{ Authorization: this.token }" :data="{ group: 'brand-icon' }" list-type="picture-card"
            :on-success="handleIconUploadSuccess" :fileList="imageUploadList">
            <img style="width: 122px; height: 122px" v-if="form.iconImageUrl"
              :src="form.iconImageUrl | imageThumbnailUrl(false)" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="ShopURL" prop="shopUrl">
          <el-input v-model="form.shopUrl"></el-input>
        </el-form-item>
        <el-form-item label="ProductURL" prop="productUrl">
          <el-input v-model="form.productUrl"></el-input>
        </el-form-item>
        <el-form-item label="When" prop="when">
          <el-input v-model="form.when"></el-input>
        </el-form-item>
        <el-form-item label="Category" prop="category">
          <el-input v-model="form.category"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button size="small" @click="closeUpdateDialog">Cancel</el-button>
        <el-button type="primary" size="small" @click="confirmBrand">OK</el-button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="dialogUploadVisible" @close="closeUploadDialog" title="Upload Brand List" width="40%">
      <el-upload class="upload-btn" action accept=".csv, .xlsx, .xls" :before-upload="onImportExcel">
        <el-button size="small" icon="el-icon-upload2">Click to Upload</el-button>
      </el-upload>
      <el-link href="/example-brands.xlsx">Example file</el-link>
      <div class="external-info" v-if="uploadList.length > 0">
        <i class="el-icon-info"></i>
        Successfully upload {{ uploadList.length }} data
      </div>
      <span slot="footer">
        <el-button size="small" @click="closeUploadDialog">Cancel</el-button>
        <el-button type="primary" size="small" @click="confirmUpload">OK</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  fetchBrand,
  createBrand,
  updateBrand,
  deleteBrand,
  createBrandBatch,
} from "@/api/admin";
import { getToken } from "@/utils/auth";
import { aiApiRoot } from "@/utils/server";
import Pagination from "@/components/Pagination";
import XLSX from "xlsx";

export default {
  components: { Pagination },
  data() {
    return {
      token: "Bearer " + getToken(),
      aiApiRoot,
      dialogVisible: false,
      dialogUploadVisible: false,
      currentId: 0,
      tableData: [],
      listQuery: {
        name: "",
        offset: 0,
        limit: 10,
      },
      total: 0,
      form: null,
      rules: {
        code: [{ required: true }],
        name: [{ required: true }],
        platform: [{ required: true }],
      },
      uploadList: [],
      loading: false,
      imageUploadList: [],
    };
  },
  created() {
    this.initForm();
  },
  methods: {
    initForm() {
      this.form = {
        code: "",
        name: "",
        platform: "tmall",
        shopUrl: "",
        productUrl: "",
        when: "",
        category: "",
      };
    },
    fetchData() {
      fetchBrand(this.listQuery).then((response) => {
        if (response.success) {
          this.tableData = response.result.items || [];
          this.total = response.result.totalCount;
        }
      });
    },
    active() {
      this.initForm();
      this.fetchData();
    },
    openUpdateDialog(row) {
      this.currentId = row.id;
      this.form = JSON.parse(JSON.stringify(row));
      this.dialogVisible = true;
    },
    closeUpdateDialog() {
      this.dialogVisible = false;
      this.currentId = 0;
      this.$refs.form.resetFields();
      this.initForm();
    },
    confirmBrand() {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }
        if (this.currentId === 0) {
          createBrand(this.form).then((response) => {
            if (response.success) {
              this.$notify({
                title: "Create successful",
                type: "success",
              });
              this.closeUpdateDialog();
              this.fetchData();
            }
          });
        } else {
          updateBrand(this.currentId, this.form).then((response) => {
            if (response.success) {
              this.$notify({
                title: "Update successful",
                type: "success",
              });
              this.closeUpdateDialog();
              this.fetchData();
            }
          });
        }
      });
    },
    deleteBrand(id) {
      deleteBrand(id).then((response) => {
        if (response.success) {
          this.$notify({
            title: "Delete successful",
            type: "success",
          });
          this.fetchData();
        }
      });
    },
    onImportExcel(f) {
      const loading = this.$loading({
        lock: true,
        text: "Processing data, please wait...",
      });
      setTimeout(() => {
        const fileReader = new FileReader();
        fileReader.onload = (e) => {
          try {
            const { result } = e.target;
            const workbook = XLSX.read(result, { type: "binary" });
            let data = [];
            for (const sheet in workbook.Sheets) {
              if (workbook.Sheets.hasOwnProperty(sheet)) {
                data = data.concat(
                  XLSX.utils.sheet_to_json(workbook.Sheets[sheet])
                );
              }
            }
            let results = data
              .filter((e) => {
                if (!e.BRAND_NAME || !e.BRAND_CODE) {
                  return false;
                }
                return true;
              })
              .map((e, i) => {
                if (i > 100000) {
                  throw new Error("Too many data");
                }
                return {
                  name: e.BRAND_NAME.toString(),
                  code: e.BRAND_CODE.toString(),
                  ossDirName: e.OSS_BRAND_NAME
                    ? e.OSS_BRAND_NAME.toString()
                    : "",
                  shopUrl: e.BRAND_SHOP_URL ? e.BRAND_SHOP_URL.toString() : "",
                  productUrl: e.BRAND_PRODUCT_URL
                    ? e.BRAND_PRODUCT_URL.toString()
                    : "",
                  when: e.WHEN ? e.WHEN.toString() : "",
                  category: e.BRAND_TYPE ? e.BRAND_TYPE.toString() : "",
                };
              });
            this.uploadList = results;
            loading.close();
          } catch (e) {
            loading.close();
            this.$message.error("Load excel error: " + e);
          }
        };
        fileReader.readAsBinaryString(f);
      }, 500);
      return false;
    },
    confirmUpload() {
      if (this.uploadList.length === 0) {
        this.$message({
          message: "Upload brand list please",
          type: "warning",
        });
        return;
      }
      this.loading = true;
      createBrandBatch({ brands: this.uploadList })
        .then((response) => {
          this.loading = false;
          if (response.success) {
            this.$notify({
              title: "Create successful",
              type: "success",
            });
            this.closeUploadDialog();
            this.fetchData();
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    closeUploadDialog() {
      this.uploadList = [];
      this.dialogUploadVisible = false;
    },
    handleIconUploadSuccess(res) {
      if (res.result && res.result.length > 0) {
        this.form.iconImageId = res.result[0].id;
        this.form.iconImageUrl = res.result[0].url;
      }
      this.imageUploadList = [];
    },
    platformLabel(platform) {
      if (platform === 'tmall') {
        return "TMall";
      } else if (platform === 'tiktok') {
        return "TikTok";
      }
      return platform;
    }
  },
};
</script>

<style lang="scss" scoped>
.brand {
  /deep/ .el-upload--picture-card {
    width: 124px;
    height: 124px;
    line-height: 128px;
  }

  /deep/ .el-upload-list--picture-card .el-upload-list__item {
    width: 124px;
    height: 124px;
    line-height: 128px;
  }

  .toolbar {
    width: 100%;
    margin-bottom: 10px;
    text-align: right;

    .new-btn {
      float: left;
    }

    .search-bar {
      display: inline-block;

      .el-input {
        width: 300px;
      }
    }
  }

  .brand-dialog .el-form {
    margin-top: 10px;
    margin-bottom: -10px;
    width: 90%;
  }

  .external-info {
    color: #666666;
  }

  .upload-btn {
    display: inline-block;
    margin: 10px 20px 10px 0;
  }
}
</style>