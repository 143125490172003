<template>
  <div class="cores">
    <el-card class="task-card" shadow="never" v-loading="taskLoading">
      <div slot="header" class="clearfix">
        <span>GPT Task</span>
        <el-button icon="el-icon-refresh" style="float: right; padding: 3px 0" type="text"
          @click="fetchTask">Refresh</el-button>
      </div>
      <el-row class="task-row">
        <el-col :span="2"><span class="cnt-title">ALL</span></el-col>
        <el-col :span="4" v-for="(t, i) in task.all" :key="i">
          <el-tag size="small" :type="t | statusFilter">{{ t.status }}</el-tag>
          <span class="cnt-text">{{ t.cnt }}</span>
        </el-col>
      </el-row>
      <el-row class="task-row">
        <el-col :span="2"><span class="cnt-title">Today</span></el-col>
        <el-col :span="4" v-for="(t, i) in task.today" :key="i">
          <el-tag size="small" :type="t | statusFilter">{{ t.status }}</el-tag>
          <span class="cnt-text">{{ t.cnt }}</span>
        </el-col>
      </el-row>
    </el-card>
    <el-card class="task-card" shadow="never" v-loading="coreLoading">
      <div slot="header" class="clearfix">
        <span>GPT Core</span>
        <el-button icon="el-icon-refresh" style="float: right; padding: 3px 0" type="text"
          @click="fetchCore">Refresh</el-button>
      </div>
      <el-row>
        <el-table size="small" :data="core.cores">
          <el-table-column prop="uuid" label="Uuid" min-width="120"></el-table-column>
          <el-table-column prop="location" label="Location" min-width="50"></el-table-column>
          <el-table-column prop="specs" label="Specs" min-width="90"></el-table-column>
          <el-table-column prop="status" label="Status" min-width="50"></el-table-column>
          <el-table-column label="Active At" min-width="140">
            <template slot-scope="scope">
              <i class="el-icon-time"></i>
              <span style="margin-left: 10px">{{
      scope.row.lastActiveAt | displayTime()
                }}</span>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import { fetchGptTask, fetchGptCore } from "@/api/admin";

export default {
  data() {
    return {
      task: {},
      core: {},
      taskLoading: false,
      coreLoading: false,
    };
  },
  methods: {
    active() {
      this.fetchTask();
      this.fetchCore();
    },
    fetchTask() {
      this.taskLoading = true;
      fetchGptTask().then(response => {
        if (response.success) {
          this.task = response.result;
        }
      }).finally(() => {
        this.taskLoading = false;
      })
    },
    fetchCore() {
      this.coreLoading = true;
      fetchGptCore().then(response => {
        if (response.success) {
          this.core = response.result;
        }
      }).finally(() => {
        this.coreLoading = false;
      })
    },
  },
  filters: {
    statusFilter(info) {
      if (!info) {
        return "info";
      }
      const statusMap = {
        waiting: "warning",
        running: "",
        done: "success",
        abort: "info",
        error: "danger",
      };
      return statusMap[info.status];
    },
  },
};
</script>

<style lang="scss" scoped>
.cores {
  .task-card {
    margin-bottom: 10px;
    border: none;
    border-radius: 0;

    .task-row {
      margin-bottom: 10px;

      .cnt-title {
        vertical-align: middle;
      }

      .cnt-text {
        color: #606266;
        margin-left: 10px;
        display: inline;
        vertical-align: middle;
      }
    }
  }

  /deep/ .el-card__header {
    padding: 10px 20px;
    font-weight: bold;

    div {
      position: relative;
    }
  }



  .status-info {
    margin-top: 20px;
    color: #606266;
  }
}
</style>