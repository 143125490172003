<template>
  <div class="role" v-loading.fullscreen.lock="loading">
    <div class="new-btn">
      <el-button icon="el-icon-plus" size="small" type="primary" @click="openNewDialog">New</el-button>
    </div>
    <el-table :data="tableData" row-key="id">
      <el-table-column prop="id" label="Id" width="90"></el-table-column>
      <el-table-column prop="name" label="Name" min-width="140"></el-table-column>
      <el-table-column label="Create At" min-width="110">
        <template slot-scope="scope">
          <i class="el-icon-time"></i>
          <span style="margin-left: 10px">{{
            scope.row.createAt | displayTime()
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Update At" min-width="110">
        <template slot-scope="scope">
          <i class="el-icon-time"></i>
          <span style="margin-left: 10px">{{
            scope.row.updateAt | displayTime()
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Detail" min-width="34">
        <template slot-scope="scope">
          <el-button type="primary" circle size="small" icon="el-icon-more" @click="openDetailDialog(scope.row)">
          </el-button>
        </template>
      </el-table-column>
      <el-table-column label="Delete" min-width="34">
        <template slot-scope="scope">
          <el-button type="danger" :disabled="scope.row.fixed" icon="el-icon-delete" @click="deleteRole(scope.row.id)"
            circle size="small" slot="reference"></el-button>
        </template>
      </el-table-column>
    </el-table>
    <pagination v-show="total > 0" :total="total" :offset.sync="listQuery.offset" :limit.sync="listQuery.limit"
      layout="sizes, prev, pager, next, total " @pagination="fetchData" />
    <el-dialog :visible.sync="dialogVisible" @close="closeDialog" :title="form.id && form.id !== 0 ? 'Update Role' : 'Create Role'
      " custom-class="add-dialog">
      <el-form ref="form" :model="form" :rules="rules" size="small" label-width="140px">
        <el-form-item label="Name" prop="name" required>
          <el-input :disabled="form.fixed" v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="Menu">
          <el-tree ref="tree" :data="form.menu" show-checkbox node-key="id" :props="treeProps">
          </el-tree>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button size="small" @click="closeDialog">Cancel</el-button>
        <el-button type="primary" size="small" @click="confirmRole">OK</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  fetchRoleMenu,
  fetchRole,
  deleteRole,
  createRole,
  updateRole,
  fetchOneRole,
} from "@/api/admin";
import Pagination from "@/components/Pagination";
import { MenuList } from "@/router/menu";

export default {
  components: { Pagination },
  data() {
    return {
      dialogVisible: false,
      roleMenu: [],
      checkedMenuIds: [],
      tableData: [],
      listQuery: {
        offset: 0,
        limit: 10,
      },
      total: 0,
      form: null,
      rules: {
        name: [{ required: true }],
      },
      treeProps: {
        children: 'children',
        label: 'name'
      },
      loading: false
    }
  },
  created() {
    this.initForm();
    fetchRoleMenu().then(response => {
      if (response.success) {
        let roleMenu = response.result || [];
        this.roleMenu = this.fillName(roleMenu);
      }
    })
  },
  methods: {
    initForm() {
      this.form = {
        name: "",
        menu: []
      };
    },
    fillName(menuList) {
      menuList.forEach(t => {
        let m = MenuList[t.key];
        if (m) {
          t.name = this.$t(m.i18n);
        } else {
          t.name = t.key;
        }
        t.children = this.fillName(t.children ? t.children : []);
      })
      return menuList;
    },
    fetchData() {
      fetchRole(this.listQuery).then((response) => {
        if (response.success) {
          this.tableData = response.result.items || [];
          this.total = response.result.totalCount;
        }
      });
    },
    active() {
      this.fetchData();
    },
    closeDialog() {
      this.dialogVisible = false;
      this.initForm();
      this.$refs.form.resetFields();
    },
    openNewDialog() {
      this.dialogVisible = true;
      this.form.menu = JSON.parse(JSON.stringify(this.roleMenu));
      this.$nextTick(() => {
        this.$refs.tree.setCheckedKeys([]);
      })
    },
    deleteRole(id) {
      this.$confirm('Any users related this role would be rolled back to the default role', 'Confirm delete?', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        deleteRole(id).then((response) => {
          if (response.success) {
            this.$notify({
              title: "Update successful",
              type: "success",
            });
            this.fetchData();
          }
        });
      })
    },
    findIds(menuList) {
      let ids = [];
      for (let i = 0; i < menuList.length; i++) {
        ids.push(menuList[i].id);
        if (menuList[i].children) {
          let cids = this.findIds(menuList[i].children);
          ids = ids.concat(cids);
        }
      }
      return ids;
    },
    filterNotAllCheckedIds(allMenuList, ids) {
      let v = [];
      for (let i = 0; i < allMenuList.length; i++) {
        if (!allMenuList[i].children || allMenuList[i].children.length === 0) {
          // 叶子节点
          if (ids.includes(allMenuList[i].id)) {
            v.push(allMenuList[i].id);
          }
        } else {
          let cids = this.filterNotAllCheckedIds(allMenuList[i].children, ids);
          v = v.concat(cids);
        }
      }
      return v;
    },
    openDetailDialog(row) {
      this.dialogVisible = true;
      this.form = { id: row.id, name: row.name, fixed: row.fixed, menu: [] };
      let checkedMenuIds = [];
      // get detail
      fetchOneRole(row.id).then(response => {
        if (response.success) {
          this.form.menu = JSON.parse(JSON.stringify(this.roleMenu));
          let menu = response.result.menu ? response.result.menu : [];
          checkedMenuIds = this.filterNotAllCheckedIds(this.roleMenu, this.findIds(menu));
          this.$refs.tree.setCheckedKeys(checkedMenuIds);
        }
      })
    },
    pickSelectMenu(menuList, checkedIds) {
      let v = [];
      for (let i = 0; i < menuList.length; i++) {
        if (menuList[i].children) {
          menuList[i].children = this.pickSelectMenu(menuList[i].children, checkedIds);
        }
        if (menuList[i].children && menuList[i].children.length > 0 ||
          checkedIds.includes(menuList[i].id)) {
          v.push(menuList[i]);
        }
      }
      return v
    },
    confirmRole() {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }
        this.loading = true;
        let checkedIds = this.$refs.tree.getCheckedKeys();
        let menu = JSON.parse(JSON.stringify(this.form.menu));
        menu = this.pickSelectMenu(menu, checkedIds);
        if (!this.form.id || this.form.id === 0) {
          createRole({ name: this.form.name, menu: menu }).then((response) => {
            if (response.success) {
              this.$notify({
                title: "Create successful",
                type: "success",
              });
              this.closeDialog();
              this.fetchData();
            }
          }).finally(() => {
            this.loading = false;
          });
        } else {
          updateRole(this.form.id, { name: this.form.name, menu: menu }).then((response) => {
            if (response.success) {
              this.$notify({
                title: "Update successful",
                type: "success",
              });
              this.closeDialog();
              this.fetchData();
            }
          }).finally(() => {
            this.loading = false;
          });
        }
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.role {
  .new-btn {
    width: 100%;
    margin-bottom: 10px;
  }

  /deep/ .add-dialog .el-form {
    margin-top: 10px;
    margin-bottom: -10px;
    width: 90%;
  }
}
</style>