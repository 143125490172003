<template>
  <div class="admin-user-data">
    <el-row :gutter="20">
      <el-col :span="11">
        <el-card class="user-card" shadow="never">
          <div slot="header">
            <span>1. User</span>
          </div>
          <user-selection
            :id="current.userId"
            @select="selectUserId"
            :lite="true"
          />
        </el-card>
      </el-col>
      <el-col :span="13">
        <el-row>
          <el-card class="user-card" shadow="never">
            <div class="user-brand-card-header" slot="header">
              <span>2. User Data</span>
            </div>
            <span v-if="current.userId === 0">Please select user first!</span>
            <el-form v-else size="small">
              <el-form-item>
                <el-radio-group v-model="current.type" @change="fetchDateList">
                  <el-radio-button label="sales">Sales</el-radio-button>
                  <el-radio-button label="stock">Stock</el-radio-button>
                </el-radio-group>
              </el-form-item>
              <el-form-item>
                <el-radio-group
                  v-model="current.period"
                  @change="fetchDateList"
                >
                  <el-radio label="week">Week</el-radio>
                  <el-radio label="month">Month</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item>
                <el-radio-group
                  v-model="current.platform"
                  @change="fetchDateList"
                >
                  <el-radio label="tmall">TMall</el-radio>
                  <el-radio label="tiktok">TikTok</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item>
                <el-select
                  v-model="current.date"
                  placeholder="Please select date"
                  filterable
                >
                  <el-option
                    v-for="item in this.dateList"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-button
                  :disabled="!enableDownload"
                  class="excel-dl-btn"
                  icon="el-icon-download"
                  @click="clickDownload"
                  >Download Primary</el-button
                >
              </el-form-item>
            </el-form>
          </el-card>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { downloadFileAuth } from "@/utils/download-file";
import { aiApiRoot } from "@/utils/server";
import { getToken } from "@/utils/auth";
import UserSelection from "./UserSelection.vue";
import { fetchPrimaryDate } from "@/api/admin";
export default {
  components: { UserSelection },
  data() {
    return {
      current: {
        userId: 0,
        type: "sales",
        period: "week",
        platform: "tmall",
        date: "",
      },
      dateList: [],
    };
  },
  methods: {
    active() {},
    fetchDateList() {
      this.current.date = "";
      fetchPrimaryDate(this.current.type, {
        userId: this.current.userId,
        period: this.current.period,
        platform: this.current.platform,
      }).then((response) => {
        if (response.success) {
          this.dateList = response.result;
        }
      });
    },
    selectUserId(userId) {
      this.current.userId = userId;
      this.current.date = "";
      if (userId) {
        this.fetchDateList();
      }
    },
    clickDownload() {
      const loading = this.$loading({
        lock: true,
        text: this.$t("common.preparingData"),
      });
      downloadFileAuth(
        aiApiRoot +
          "/v1/admins/analysis-basic/" +
          this.current.type +
          "/primary/export?period=" +
          this.current.period +
          "&date=" +
          this.current.date +
          "&userId=" +
          this.current.userId,
        this.current.type +
          "_data_" +
          this.current.date +
          "_" +
          this.current.userId +
          ".xlsx",
        getToken(),
        () => {
          loading.close();
        }
      );
    },
  },
  computed: {
    enableDownload() {
      return this.current.userId && this.current.date;
    },
  },
};
</script>

<style lang="scss" scoped>
.admin-user-data {
  .user-card {
    margin-bottom: 10px;
  }
}
</style>