<template>
  <div class="user-service" v-loading="dataLoading">
    <div class="toolbar">
      <div class="new-btn">
        <el-button icon="el-icon-plus" size="small" type="primary" @click="dialogNewVisible = true">New</el-button>
      </div>
      <div class="search-bar">
        <el-input size="small" v-model="listQuery.q">
          <el-select v-model="listQuery.queryType" slot="prepend">
            <el-option label="User" value="user"></el-option>
            <el-option label="Organization" value="organization"></el-option>
          </el-select>
          <el-button slot="append" icon="el-icon-search" @click="clickSearch"></el-button>
        </el-input>
      </div>
    </div>
    <el-table :data="tableData" row-key="id" @filter-change="filterChangeHandle">
      <el-table-column prop="userName" label="User Name" min-width="160"></el-table-column>
      <el-table-column prop="serviceCode" label="Service" min-width="90" :filters="[
        { text: 'tagger', value: 'tagger' },
        { text: 'analysis', value: 'analysis' },
        { text: 'data', value: 'data' },
        { text: 'visual', value: 'visual' },
        { text: 'report', value: 'report' },
        { text: 'nlp', value: 'nlp' },
        { text: 'gpt', value: 'gpt' },
        { text: 'gpt designer', value: 'gptDesigner' },
        { text: 'gpt morph', value: 'gptMorph' },
        { text: 'gpt concept', value: 'gptConcepter' },
        { text: 'gpt drawing', value: 'gptDrawing' },
        { text: 'global trend', value: 'globalTrend' },
        { text: 'aeye', value: 'aeye' },
      ]" column-key="serviceCode" key="serviceCode"></el-table-column>
      <el-table-column prop="status" label="Status" min-width="90">
        <template slot-scope="scope">
          <el-tag effect="dark" size="small"
            :type="scope.row.status === 'approved' ? 'success' : scope.row.status === 'applied' ? 'warning' : 'danger'">{{
              scope.row.status }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="level" label="Level" min-width="82"></el-table-column>
      <el-table-column prop="coreNum" label="Core Num" min-width="88"></el-table-column>
      <el-table-column prop="chargeMode" label="Charge Mode" min-width="110"></el-table-column>
      <el-table-column label="Expire At" min-width="174">
        <template slot-scope="scope">
          <i :class="isExpire(scope.row) ? 'el-icon-time col-expire-at danger' : 'el-icon-time col-expire-at'"></i>
          <span style="margin-left: 10px" :class="isExpire(scope.row) ? 'col-expire-at danger' : 'col-expire-at'">{{
            scope.row.chargeMode === "period"
              ? scope.row.expireAt
              : "" | displayTime()
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Remain Image Count (Tagger)" min-width="160">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{
            scope.row.serviceCode === 'tagger' && scope.row.chargeMode === "usage" ? scope.row.remainTimes : "-"
            }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Daily Remain Image Count (GPT)" min-width="160">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{
            ((scope.row.serviceCode === 'gpt' || scope.row.serviceCode === 'gptDesigner' || scope.row.serviceCode ===
              'gptMorph' || scope.row.serviceCode === 'gptConcepter' || scope.row.serviceCode === 'gptDrawing') ?
              scope.row.remainTimes : "-") +
            " / " +
            ((scope.row.serviceCode === 'gpt' || scope.row.serviceCode === 'gptDesigner' || scope.row.serviceCode ===
              'gptMorph' || scope.row.serviceCode === 'gptConcepter' || scope.row.serviceCode === 'gptDrawing') ?
              scope.row.dailyMaxTimes : "-")
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Extends" min-width="100">
        <template slot-scope="scope">
          <el-button size="small" slot="reference" icon="el-icon-setting" type="primary"
            @click="showDialog(scope.row.id, scope.row.expireAt, scope.row.dailyMaxTimes)" circle
            :disabled="scope.row.chargeMode !== 'period'"></el-button>
        </template>
      </el-table-column>
    </el-table>
    <pagination v-show="total > 0" :total="total" :offset.sync="listQuery.offset" :limit.sync="listQuery.limit"
      layout="sizes, prev, pager, next, total " @pagination="fetchData" />
    <el-dialog :visible.sync="dialogSetVisible" title="Set User Service" custom-class="set-dialog">
      <el-form ref="setForm" :model="setForm" size="small" label-width="185px">
        <el-form-item label="Type" prop="type">
          <el-radio-group v-model="setForm.type">
            <el-radio label="expireAt">Expire At</el-radio>
            <el-radio label="dailyMaxTimes">Daily Image Count (GPT)</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="setForm.type === 'expireAt'" label="Expire At" prop="expireAt">
          <el-date-picker v-model="setForm.expireAt" type="datetime" size="small">
          </el-date-picker>
        </el-form-item>
        <el-form-item v-if="setForm.type === 'dailyMaxTimes'" label="Daily Image Count (GPT)" prop="dailyMaxTimes">
          <el-input-number v-model="setForm.dailyMaxTimes" controls-position="right" :min="1"></el-input-number>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button size="small" @click="dialogSetVisible = false">Cancel</el-button>
        <el-button type="primary" size="small" @click="setUserService">OK</el-button>
      </span>
    </el-dialog>
    <el-dialog width="800px" :visible.sync="dialogNewVisible" @close="initForm" title="Create User Service"
      custom-class="add-dialog">
      <el-alert
        title="Each user can only have one basic level user service, the create operation will update the existing user service"
        type="info" show-icon :closable="false">
      </el-alert>
      <el-form ref="form" :model="form" :rules="rules" size="small" label-width="190px">
        <el-form-item label="Service" prop="serviceCodes">
          <el-checkbox-group v-model="form.serviceCodes" @change="changeService">
            <el-checkbox label="tagger">AI Image Tagger</el-checkbox>
            <el-checkbox label="analysis">Trend Analysis</el-checkbox>
            <el-checkbox label="data">Data Center</el-checkbox>
            <el-checkbox label="visual">Visual</el-checkbox>
            <el-checkbox label="report">Report & Consulting</el-checkbox>
            <el-checkbox label="nlp">NLP Analysis</el-checkbox>
            <el-checkbox label="gpt">GPT</el-checkbox>
            <el-checkbox label="gptDesigner">GPT Designer</el-checkbox>
            <el-checkbox label="gptMorph">GPT Morph</el-checkbox>
            <el-checkbox label="gptConcepter">GPT Concepter</el-checkbox>
            <el-checkbox label="gptDrawing">GPT Drawing</el-checkbox>
            <el-checkbox label="globalTrend">Global Trend</el-checkbox>
            <el-checkbox label="aeye">A.eye</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="Level" prop="level">
          <el-radio-group v-model="form.level" @change="changeLevel">
            <el-radio label="basic">Basic</el-radio>
            <el-radio label="advanced">Advanced</el-radio>
            <el-radio disabled label="premium">Premium</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="allowSettings" label="Core Num" prop="coreNum">
          <el-input-number :disabled="form.level === 'basic'" v-model="form.coreNum" controls-position="right"
            :min="1"></el-input-number>
        </el-form-item>
        <el-form-item label="Charge Mode" prop="chargeMode">
          <el-radio-group :disabled="!allowSettings || form.level === 'basic'" v-model="form.chargeMode">
            <el-radio label="period">Period</el-radio>
            <el-radio label="usage">Usage</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="form.chargeMode === 'period'" label="Expire At" prop="expireAt">
          <el-date-picker v-model="form.expireAt" type="datetime" :clearable="false"></el-date-picker>
        </el-form-item>
        <el-form-item v-if="form.chargeMode === 'usage'" label="Image Count (Tagger)" prop="remainTimes">
          <el-input-number v-model="form.remainTimes" controls-position="right" :min="1"></el-input-number>
        </el-form-item>
        <el-form-item v-if="allowGPTSettings" label="Daily Image Count (GPT)" prop="dailyMaxTimes">
          <el-input-number v-model="form.dailyMaxTimes" controls-position="right" :min="1"></el-input-number>
        </el-form-item>
        <el-form-item label="User" prop="userId"></el-form-item>
      </el-form>
      <div class="user-container">
        <user-selection :id="form.userId" :orgId="form.organizationId" :useOrg="true" @select="selectUserId" />
      </div>
      <el-alert class="select-notify" v-if="selectNotifyVisible" :closable="false" title="Please select a user"
        type="error"></el-alert>
      <span slot="footer">
        <el-button size="small" @click="dialogNewVisible = false">Cancel</el-button>
        <el-button type="primary" size="small" @click="confirmUserService">OK</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import UserSelection from "./UserSelection.vue";
import Pagination from "@/components/Pagination";
import {
  fetchUserService,
  fetchUser,
  updateUserService,
  createUserService,
} from "@/api/admin";
import dayjs from "dayjs";

export default {
  components: { UserSelection, Pagination },
  data() {
    return {
      dialogSetVisible: false,
      dialogNewVisible: false,
      selectNotifyVisible: false,
      currentId: 0,
      selectedUserId: 0,
      tableData: [],
      listQuery: {
        queryType: "user",
        offset: 0,
        limit: 10,
      },
      total: 0,
      form: null,
      setForm: {
        type: "expireAt",
        expireAt: null,
        dailyMaxTimes: 0
      },
      rules: {
        serviceCodes: [{ required: true }],
        level: [{ required: true }],
        chargeMode: [{ required: true }],
        coreNum: [{ required: true }],
        expireAt: [{ required: true }],
        remainTimes: [{ required: true }],
        dailyMaxTimes: [{ required: true }],
        userId: [{ required: true }],
      },
      dataLoading: false,
    };
  },
  created() {
    this.initForm();
  },
  methods: {
    initForm() {
      this.form = {
        serviceCodes: ["tagger"],
        level: "basic",
        chargeMode: "period",
        expireAt: dayjs().add(1, "year"),
        remainTimes: 1,
        dailyMaxTimes: 100,
        coreNum: 1,
        userId: 0,
        organizationId: 0,
      };
    },
    clickSearch() {
      this.listQuery.offset = 0;
      this.listQuery.limit = 10;
      this.fetchData();
    },
    fetchData() {
      this.dataLoading = true;
      fetchUserService(this.listQuery)
        .then((response) => {
          this.dataLoading = false;
          if (response.success) {
            if (
              !response.result ||
              !response.result.items ||
              response.result.items.length === 0
            ) {
              this.tableData = [];
              return;
            }
            this.total = response.result.totalCount;
            this.fetchUserData(response.result.items);
          }
        })
        .catch(() => {
          this.dataLoading = false;
        });
    },
    fetchUserData(userServices) {
      this.dataLoading = true;
      let userIds = userServices.map((t) => {
        return t.userId;
      });
      fetchUser({ ids: userIds.join(","), limit: -1 })
        .then((response) => {
          userServices.forEach((t, i) => {
            response.result.items.forEach((u) => {
              if (t.userId === u.id) {
                userServices[i].userName = u.userName;
              }
            });
          });
          this.tableData = userServices;
        })
        .finally(() => {
          this.dataLoading = false;
        });
    },
    active() {
      this.fetchData();
      this.initForm();
    },
    showDialog(id, expireAt, dailyMaxTimes) {
      this.dialogSetVisible = true;
      this.currentId = id;
      this.setForm = {
        type: "expireAt",
        expireAt: expireAt,
        dailyMaxTimes: dailyMaxTimes
      }
    },
    setUserService() {
      this.dialogSetVisible = false;
      updateUserService(this.currentId, this.setForm).then((response) => {
        if (response.success) {
          this.fetchData();
        }
      });
    },
    selectUserId(userId, orgId) {
      this.form.userId = userId;
      this.form.organizationId = orgId;
      this.selectNotifyVisible = false;
    },
    confirmUserService() {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }
        let params = {
          userId: this.form.userId,
          organizationId: this.form.organizationId,
          serviceCodes: this.form.serviceCodes,
          chargeMode: this.form.chargeMode,
          level: this.form.level,
          coreNum: this.form.coreNum,
          applyStatus: "approved",
        };
        if (params.chargeMode === "period") {
          params.expireAt = this.form.expireAt;
        } else {
          params.remainTimes = this.form.remainTimes;
        }
        params.dailyMaxTimes = this.form.dailyMaxTimes;
        if (
          (!params.userId || params.userId === 0) &&
          (!params.organizationId || params.organizationId === 0)
        ) {
          this.selectNotifyVisible = true;
          return;
        }
        createUserService(params).then((response) => {
          if (response.success) {
            this.$notify({
              title: "Create successful",
              type: "success",
            });
            this.initForm();
            this.dialogNewVisible = false;
            this.fetchData();
          }
        });
      });
    },
    changeService() {
      if (!this.allowSettings) {
        this.form.chargeMode = "period";
        this.form.remainTimes = 1;
        this.form.coreNum = 1;
      }
      if (!this.allowGPTSettings) {
        this.form.dailyMaxTimes = 100;
      }
    },
    changeLevel() {
      this.form.chargeMode = "period";
      this.form.remainTimes = 1;
      this.form.coreNum = 1;
    },
    isExpire(row) {
      return dayjs(row.expireAt).isBefore(dayjs())
    },
    filterChangeHandle(v) {
      if (v.serviceCode) {
        if (v.serviceCode.length > 0) {
          this.listQuery.serviceCodes = v.serviceCode.join(',');
        } else {
          this.listQuery.serviceCodes = null;
        }
      }
      this.listQuery.offset = 0;
      this.listQuery.limit = 10;
      this.fetchData();
    }
  },
  computed: {
    allowSettings() {
      let allow = false;
      this.form.serviceCodes.forEach((c) => {
        if (c === "tagger") {
          allow = true;
        }
      });
      return allow;
    },
    allowGPTSettings() {
      let allow = false;
      this.form.serviceCodes.forEach((c) => {
        if (c === "gpt" || c === "gptDesigner" || c === "gptMorph" || c === "gptConcepter" || c === "gptDrawing") {
          allow = true;
        }
      });
      return allow;
    }
  },
};
</script>

<style lang="scss" scoped>
.user-service {
  .col-expire-at.danger {
    color: #f56c6c;
  }

  .toolbar {
    width: 100%;
    margin-bottom: 10px;
    text-align: right;

    .new-btn {
      float: left;
    }

    .search-bar {
      display: inline-block;

      /deep/ .el-select .el-input {
        width: 125px;
      }

      .el-input {
        width: 400px;
      }
    }
  }

  /deep/ .set-dialog .el-form {
    margin-top: 10px;
    margin-bottom: -10px;
  }

  /deep/ .add-dialog .el-form {
    margin-top: 10px;
    margin-bottom: -10px;
  }

  .select-notify {
    margin-top: 10px;
  }

  .dialog-desc {
    margin: 20px 0;
    font-size: 18px;

    i {
      margin-right: 10px;
      color: #e6a23c;
    }
  }

  .user-container {
    border: 1px solid #e8e8e8;
    border-radius: 5px;
    padding: 20px 10px 0;
  }
}
</style>