<template>
  <div class="category">
    <div class="toolbar">
      <div class="new-btn">
        <el-button
          icon="el-icon-plus"
          size="small"
          type="primary"
          @click="dialogNewVisible = true"
          >New</el-button
        >
      </div>
    </div>
    <el-table
      :data="tableData"
      row-key="id"
      @filter-change="filterChangeHandle"
    >
      <el-table-column prop="name" label="Name"></el-table-column>
      <el-table-column
        label="Level"
        :filters="[
          { text: 'T1', value: 1 },
          { text: 'T2', value: 2 },
          { text: 'T3', value: 3 },
        ]"
        :filter-multiple="false"
        column-key="level"
      >
        <template slot-scope="scope">
          {{ "T" + scope.row.level }}
        </template>
      </el-table-column>
      <el-table-column label="DisplayName">
        <template slot-scope="scope">
          {{ scope.row.displayName }}
          <el-button
            type="text"
            circle
            size="small"
            icon="el-icon-edit"
            @click="openDisplayNameDialog(scope.row)"
          />
        </template>
      </el-table-column>
      <el-table-column label="Additional">
        <template slot-scope="scope">
          {{ scope.row.additional ? "Yes" : "No" }}
        </template>
      </el-table-column>
      <el-table-column label="Create At" min-width="110">
        <template slot-scope="scope">
          <i class="el-icon-time"></i>
          <span style="margin-left: 10px">{{
            scope.row.createAt | displayTime()
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Update At" min-width="110">
        <template slot-scope="scope">
          <i class="el-icon-time"></i>
          <span style="margin-left: 10px">{{
            scope.row.updateAt | displayTime()
          }}</span>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-show="total > 0"
      :total="total"
      :offset.sync="listQuery.offset"
      :limit.sync="listQuery.limit"
      layout="sizes, prev, pager, next, total "
      @pagination="fetchData"
    />
    <el-dialog
      :visible.sync="dialogNewVisible"
      @close="closeDialog"
      title="Create Category"
      custom-class="category-dialog"
    >
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        size="small"
        label-width="120px"
      >
        <el-form-item label="Name" prop="name" required>
          <el-input v-model="form.name"></el-input> </el-form-item
        ><el-form-item label="Level" required>
          <el-radio-group v-model="form.level">
            <el-radio :label="1">T1</el-radio>
            <el-radio :label="2">T2</el-radio>
            <el-radio :label="3">T3</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="DisplayName" prop="displayName" required>
          <el-input v-model="form.displayName"></el-input>
        </el-form-item>
        <el-form-item label="Additional" required>
          <el-radio-group v-model="form.additional">
            <el-radio :label="true">Yes</el-radio>
            <el-radio :label="false">No</el-radio>
          </el-radio-group>
        </el-form-item></el-form
      >
      <span slot="footer">
        <el-button size="small" @click="dialogNewVisible = false"
          >Cancel</el-button
        >
        <el-button type="primary" size="small" @click="confirmCategory"
          >OK</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import { fetchCategory, createCategory, updateCategory } from "@/api/admin";

export default {
  components: { Pagination },
  data() {
    return {
      tableData: [],
      listQuery: {
        offset: 0,
        limit: 10,
      },
      total: 0,
      dialogNewVisible: false,
      dialogDisplayNameVisible: false,
      form: null,
      rules: {
        name: [{ required: true }],
        level: [{ required: true }],
      },
    };
  },
  created() {
    this.initForm();
  },
  methods: {
    initForm() {
      this.form = {
        name: "",
        level: 1,
        displayName: "",
        additional: false,
      };
    },
    active() {
      this.fetchData();
      this.initForm();
    },
    fetchData() {
      fetchCategory(this.listQuery).then((response) => {
        if (response.success) {
          this.tableData =
            response.result && response.result.items
              ? response.result.items
              : [];
          this.total = response.result.totalCount;
        }
      });
    },
    filterChangeHandle(v) {
      if (v.level) {
        if (v.level.length > 0) {
          this.listQuery.level = v.level[0];
        } else {
          this.listQuery.level = null;
        }
      }
      this.listQuery.offset = 0;
      this.fetchData();
    },
    openDisplayNameDialog(row) {
      this.$prompt("Please input display name", "Prompt", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        inputValue: row.displayName,
        inputValidator: (value) => {
          if (value === null || value.trim() === "") {
            return "invalid display name";
          }
          return true;
        },
      })
        .then((value) => {
          if (value.action !== "confirm") {
            return;
          }
          updateCategory(row.id, { displayName: value.value }).then(
            (response) => {
              if (response.success) {
                this.fetchData();
              }
            }
          );
        })
        .catch(() => {});
    },
    confirmCategory() {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }
        createCategory(this.form).then((response) => {
          if (response.success) {
            this.$notify({
              title: "Create successful",
              type: "success",
            });
            this.initForm();
            this.dialogNewVisible = false;
            this.fetchData();
          }
        });
      });
    },
    closeDialog() {
      this.dialogNewVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.category {
  .new-btn {
    width: 100%;
    margin-bottom: 10px;
  }

  /deep/ .category-dialog .el-form {
    margin-top: 10px;
    margin-bottom: -10px;
  }
}
</style>