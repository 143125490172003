<template>
  <div class="basic-data-detail">
    <el-row class="title"><el-link>Current</el-link></el-row>
    <el-table :data="tableData" style="width: 100%" :border="false">
      <el-table-column type="expand">
        <template slot-scope="props">
          <el-form label-position="right" class="table-expand">
            <el-form-item label="Best10 Brands">
              <div class="tag-container">
                <el-tag
                  size="small"
                  v-for="tag in props.row.subjectBrands.Best10
                    ? props.row.subjectBrands.Best10
                    : []"
                  :key="tag"
                >
                  {{ tag }}
                </el-tag>
              </div>
            </el-form-item>
            <el-form-item label="TPS Brands">
              <div class="tag-container">
                <el-tag
                  size="small"
                  v-for="tag in props.row.subjectBrands.TPS
                    ? props.row.subjectBrands.TPS
                    : []"
                  :key="tag"
                >
                  {{ tag }}
                </el-tag>
              </div>
            </el-form-item>
            <el-form-item label="Basic Data Brands">
              <div class="tag-container">
                <el-tag
                  size="small"
                  v-for="tag in props.row.subjectBrands.Image
                    ? props.row.subjectBrands.Image
                    : []"
                  :key="tag"
                >
                  {{ tag }}
                </el-tag>
              </div>
            </el-form-item>
            <el-form-item label="Items">
              <div class="tag-container">
                <el-tag
                  size="small"
                  v-for="tag in props.row.items ? props.row.items : []"
                  :key="tag"
                >
                  {{ tag }}
                </el-tag>
              </div>
            </el-form-item>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column label="User" prop="userName"> </el-table-column>
      <el-table-column label="Brands">
        <el-table-column label="Best10">
          <template slot-scope="scope">
            <el-tag type="info" effect="plain" size="small"
              >total:
              {{
                scope.row.subjectBrands && scope.row.subjectBrands.Best10
                  ? scope.row.subjectBrands.Best10.length
                  : 0
              }}</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column label="TPS">
          <template slot-scope="scope">
            <el-tag type="info" effect="plain" size="small"
              >total:
              {{
                scope.row.subjectBrands && scope.row.subjectBrands.TPS
                  ? scope.row.subjectBrands.TPS.length
                  : 0
              }}</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column label="Image">
          <template slot-scope="scope">
            <el-tag type="info" effect="plain" size="small"
              >total:
              {{
                scope.row.subjectBrands && scope.row.subjectBrands.Image
                  ? scope.row.subjectBrands.Image.length
                  : 0
              }}</el-tag
            >
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="Items">
        <template slot-scope="scope">
          <el-tag type="info" effect="plain" size="small"
            >total: {{ scope.row.items ? scope.row.items.length : 0 }}</el-tag
          >
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-show="total > 0"
      :total="total"
      :offset.sync="listQuery.offset"
      :limit.sync="listQuery.limit"
      layout="prev, pager, next"
      @pagination="fetchUserItems"
    />
    <el-row class="title"><el-link>Additional</el-link></el-row>
    <el-table
      ref="notInUserTable"
      :data="userTableData"
      @select="handleSelect"
      @select-all="handleSelect"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column label="User" prop="userName"></el-table-column>
      <el-table-column label="Email" prop="email"></el-table-column>
    </el-table>
    <pagination
      v-show="userTotal > 0"
      :total="userTotal"
      :offset.sync="userListQuery.offset"
      :limit.sync="userListQuery.limit"
      layout="prev, pager, next"
      @pagination="fetchUserItemsNotInUser"
    />
  </div>
</template>

<script>
import { fetchUserItems, fetchUserItemsNotInUser } from "@/api/admin";
import Pagination from "@/components/Pagination";

export default {
  components: { Pagination },
  data() {
    return {
      tableData: [],
      listQuery: {
        offset: 0,
        limit: 5,
      },
      total: 0,
      userTableData: [],
      userListQuery: {
        offset: 0,
        limit: 5,
      },
      userTotal: 0,
    };
  },
  props: {
    date: {
      type: String,
      default: "",
    },
    selectedUserIds: {
      type: Array,
      default: () => [],
    },
    initSingle: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.tableData = [];
      this.fetchUserItems();
      this.userTableData = [];
      this.fetchUserItemsNotInUser();
    },
    handleSelect(es) {
      this.userTableData.forEach((t) => {
        let flag = false;
        for (let i = 0; i < es.length; i++) {
          if (es[i].userId == t.userId) {
            this.$emit("addUserId", t.userId);
            flag = true;
            break;
          }
        }
        if (!flag) {
          this.$emit("removeUserId", t.userId);
        }
      });
    },
    fetchUserItems() {
      fetchUserItems(this.date, this.listQuery).then((response) => {
        if (response.success) {
          this.tableData = response.result.items;
          this.total = response.result.totalCount;
        }
      });
    },
    fetchUserItemsNotInUser() {
      fetchUserItemsNotInUser(this.date, this.userListQuery)
        .then((response) => {
          if (response.success) {
            this.userTableData = response.result.items
              ? response.result.items
              : [];
            this.userTotal = response.result.totalCount;
            // trick: 如果没有nextTick，toggleRowSelection不会生效
            this.$nextTick(() => {
              this.userTableData.forEach((e) => {
                for (let j = 0; j < this.selectedUserIds.length; j++) {
                  if (this.selectedUserIds[j] == e.userId) {
                    this.$refs.notInUserTable.toggleRowSelection(e, true);
                  }
                }
              });
            });
          } else {
            this.userTableData = [];
          }
        })
        .catch(() => {
          this.userTableData = [];
        });
    },
  },
  watch: {
    initSingle() {
      this.initData();
    },
  },
};
</script>

<style lang="scss" scoped>
.basic-data-detail {
  .title {
    margin: 15px 0;
  }
  .table-expand {
    /deep/ label {
      color: #a9a9a9;
    }
    .el-form-item {
      margin-bottom: 0;
    }
  }
  .tag-container {
    .el-tag {
      margin: 0 5px;
    }
  }
}
</style>