<template>
  <div class="tagger-check">
    <div class="toolbar">
      <div class="search-bar">
        <el-input size="small" v-model="listQuery.q">
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="fetchData"
          ></el-button>
        </el-input>
      </div>
    </div>
    <el-table :data="tableData" style="width: 100%">
      <el-table-column
        prop="desc"
        :label="$t('tagger.desc')"
        min-width="120"
      ></el-table-column>
      <el-table-column :label="$t('tagger.status')" min-width="60">
        <template slot-scope="scope">
          <el-tag
            :type="scope.row.status | statusFilter"
            effect="dark"
            size="small"
            >{{
              scope.row.status === "waiting"
                ? $t("tagger.waiting")
                : scope.row.status === "running"
                ? $t("tagger.running")
                : scope.row.status === "done"
                ? $t("tagger.done")
                : scope.row.status === "abort"
                ? $t("tagger.abort")
                : scope.row.status === "error"
                ? $t("tagger.error")
                : scope.row.status
            }}</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column :label="$t('tagger.execAt')" min-width="135">
        <template slot-scope="scope">
          <i class="el-icon-time"></i>
          <span style="margin-left: 10px">{{
            scope.row.execAt | displayTime()
          }}</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('tagger.finishAt')" min-width="135">
        <template slot-scope="scope">
          <i class="el-icon-time"></i>
          <span style="margin-left: 10px">{{
            scope.row.finishAt | displayTime()
          }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="imageCount"
        :label="$t('tagger.imageCount')"
        min-width="80"
      ></el-table-column>
      <el-table-column :label="$t('common.detail')" min-width="60">
        <template slot-scope="scope">
          <el-button
            type="primary"
            icon="el-icon-more"
            circle
            size="small"
            @click="openTaggerDialog(scope.row)"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-show="total > 0"
      :total="total"
      :offset.sync="listQuery.offset"
      :limit.sync="listQuery.limit"
      layout="sizes, prev, pager, next, total "
      @pagination="fetchData"
    />
    <el-dialog title="Tagger" :visible.sync="dialogTaggerVisible" width="96%">
      <tagger-check-tagger :task="currentTask" />
      <span slot="footer">
        <el-button
          type="primary"
          size="small"
          @click="dialogTaggerVisible = false"
          >Close</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { fetchTask } from "@/api/admin";
import TaggerCheckTagger from "@/components/admin/TaggerCheckTagger";
import Pagination from "@/components/Pagination";
export default {
  components: { Pagination, TaggerCheckTagger },
  data() {
    return {
      tableData: [],
      listQuery: {
        offset: 0,
        limit: 10,
      },
      total: 0,
      currentTask: { taskId: 0 },
      dialogTaggerVisible: false,
    };
  },
  filters: {
    statusFilter(status) {
      const statusMap = {
        waiting: "warning",
        running: "",
        done: "success",
        abort: "info",
        error: "danger",
      };
      return statusMap[status];
    },
  },
  methods: {
    active() {
      this.$store.dispatch("category/fresh").then(() => {
        this.fetchData();
      });
    },
    fetchData() {
      fetchTask({ execType: "tagger", ...this.listQuery }).then((response) => {
        if (response.success) {
          this.tableData = response.result.items || [];
          this.total = response.result.totalCount;
        }
      });
    },
    openTaggerDialog(row) {
      this.dialogTaggerVisible = true;
      this.currentTask = row;
    },
  },
};
</script>

<style lang="scss" scoped>
.tagger-check {
  .toolbar {
    width: 100%;
    margin-bottom: 10px;
    text-align: right;

    .search-bar {
      display: inline-block;

      /deep/ .el-select .el-input {
        width: 125px;
      }

      .el-input {
        width: 275px;
      }
    }
  }
}
</style>