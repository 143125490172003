<template>
  <div class="basic-data" v-loading="dataLoading">
    <div class="toolbar">
      <div class="new-btn">
        <el-button icon="el-icon-plus" type="primary" size="small" @click="dialogNewVisible = true">Import</el-button>
      </div>
      <div class="search-bar">
        <el-select v-model="listQuery.platform" size="small">
          <el-option label="TMall" value="tmall"></el-option>
          <el-option label="TikTok" value="tiktok"></el-option>
        </el-select>
        <el-select v-model="listQuery.period" size="small">
          <el-option label="Week" value="week"></el-option>
          <el-option label="Month" value="month"></el-option>
        </el-select>
        <el-input v-model="listQuery.q" size="small">
          <el-button slot="append" icon="el-icon-search" @click="fetchData"></el-button>
        </el-input>
      </div>
    </div>
    <el-table :data="tableData">
      <el-table-column type="expand">
        <template slot-scope="props">
          <el-form label-position="right" class="table-expand">
            <el-form-item label="Year Tags">
              <span>
                <el-tag size="small" v-for="t in props.row.yearTags
                  ? props.row.yearTags.split(',')
                  : []" :key="t">
                  {{ t }}
                </el-tag>
              </span>
            </el-form-item>
            <el-form-item label="Successful Rows">
              <span>{{
                props.row.lastInfo.succRows ? props.row.lastInfo.succRows : 0
              }}</span>
            </el-form-item>
            <el-form-item label="Tagger Rows">
              <span>{{ props.row.taggerRows ? props.row.taggerRows : 0 }}</span>
            </el-form-item>
            <el-form-item label="Current Tagger Rows">
              <span>{{
                props.row.currentTaggerRows ? props.row.currentTaggerRows : 0
              }}</span>
            </el-form-item>
            <el-form-item label="Error">
              <span>{{
                props.row.lastInfo.errs ? props.row.lastInfo.errs[0] : "-"
              }}</span>
            </el-form-item>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column prop="date" label="Date" min-width="65"></el-table-column>
      <el-table-column prop="linkDate" label="Link Date" min-width="65"></el-table-column>
      <el-table-column label="Period" min-width="65" column-key="period">
        <template slot-scope="scope">
          {{ scope.row.period === "month" ? "Month" : "Week" }}
        </template>
      </el-table-column>
      <el-table-column label="Platform" min-width="65" column-key="platform">
        <template slot-scope="scope">
          {{ scope.row.platform === "tmall" ? "TMall" : "Tiktok" }}
        </template>
      </el-table-column>
      <el-table-column prop="status" label="Status">
        <template slot-scope="scope">
          <i v-if="scope.row.status === 'deleting' ||
            scope.row.status === 'processing' ||
            scope.row.status === 'syncing' ||
            scope.row.status === 'taggering'
            " class="el-icon-loading" />
          <span style="margin-left: 5px">{{ scope.row.status }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="rows" label="Rows" min-width="65"></el-table-column>
      <el-table-column label="Update At" min-width="140">
        <template slot-scope="scope">
          <i class="el-icon-time"></i>
          <span style="margin-left: 10px">{{
            scope.row.updateAt | displayTime()
          }}</span>
        </template>
      </el-table-column>
      <el-table-column v-if="analysisType === 'sales'" label="Tagger" min-width="55" align="center">
        <template slot-scope="scope">
          <el-button v-if="scope.row.status === 'taggered' ||
            scope.row.status === 'taggering'
            " type="primary" icon="el-icon-more" circle size="small" @click="openTaggerDialog(scope.row)"></el-button>
          <el-button v-else :disabled="scope.row.status !== 'processed' || adminType !== 'root'" type="primary"
            icon="el-icon-video-play" circle size="small"
            @click="startTagger(scope.row.period, scope.row.date)"></el-button>
        </template>
      </el-table-column>
      <el-table-column v-if="analysisType === 'sales' && adminType === 'root'" label="Details" min-width="55"
        align="center">
        <template slot-scope="scope">
          <el-button :disabled="scope.row.status !== 'available' &&
            scope.row.status !== 'processed'
            " type="primary" icon="el-icon-more" circle size="small" @click="openDetailDialog(scope.row)">
          </el-button>
        </template>
      </el-table-column>
      <el-table-column v-if="analysisType === 'sales' && adminType === 'root'" label="Reset" min-width="55"
        align="center">
        <template slot-scope="scope">
          <el-button :disabled="scope.row.status !== 'available' &&
            scope.row.status !== 'taggered' &&
            scope.row.status !== 'unavailable'
            " type="warning" icon="el-icon-refresh-left" circle size="small"
            @click="startReset(scope.row.period, scope.row.date)">
          </el-button>
        </template>
      </el-table-column>
      <el-table-column label="Delete" min-width="55" align="center" v-if="adminType === 'root'">
        <template slot-scope="scope">
          <el-popconfirm title="Delete all data of this date?" @confirm="deleteAll(scope.row.period, scope.row.date)">
            <el-button :disabled="scope.row.status === 'deleting' ||
              scope.row.status === 'processing' ||
              scope.row.status === 'syncing' ||
              scope.row.status === 'taggering'
              " type="danger" icon="el-icon-delete" circle size="small" slot="reference"></el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <pagination v-show="total > 0" :total="total" :offset.sync="listQuery.offset" :limit.sync="listQuery.limit"
      layout="sizes, prev, pager, next, total " @pagination="fetchData" />
    <el-dialog v-if="adminType == 'root'" :visible.sync="dialogNewVisible" @close="closeDialog" :title="'Import Crawling ' + (analysisType === 'sales' ? 'Sales' : 'Stock')
      " class="new-dialog">
      <el-form ref="form" :model="form" :rules="rules" label-width="120px" size="small">
        <el-form-item label="Date" prop="date" required>
          <el-date-picker v-model="form.date" :type="form.period === 'week' ? 'date' : 'month'"></el-date-picker>
        </el-form-item>
        <el-form-item label="Period" prop="period" required>
          <el-radio-group v-model="form.period">
            <el-radio label="week">Week</el-radio>
            <el-radio label="month">Month</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="Platform" prop="platform" required>
          <el-radio-group v-model="form.platform">
            <el-radio label="tmall">TMall</el-radio>
            <el-radio label="tiktok">TikTok</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="form.platform !== 'tmall'" label="LinkDate" prop="linkDate" required>
          <el-date-picker v-model="form.linkDate" :type="form.period === 'week' ? 'date' : 'month'"></el-date-picker>
        </el-form-item>
        <el-form-item v-if="form.platform === 'tmall'" label="Year Tags" prop="yearTags">
          <el-tag :key="tag" v-for="tag in yearTags" closable :disable-transitions="false" @close="handleYearClose(tag)">
            {{ tag }}
          </el-tag>
          <el-input class="input-new-tag" v-if="inputVisible" v-model="inputValue" ref="saveTagInput" size="mini"
            @keyup.enter.native="handleYearInputConfirm" @blur="handleYearInputConfirm">
          </el-input>
          <el-button v-else class="button-new-tag" size="mini" @click="showYearInput">+ New Tag</el-button>
        </el-form-item>
        <el-form-item label="Upload Excel" required>
          <el-upload :data="{
            date: formatDate,
            linkDate: formatLinkDate,
            yearTags: form.yearTags,
            period: form.period,
            platform: form.platform,
            imagePath: form.imagePath,
          }" :action="action" accept=".csv, .xlsx, .xls" :headers="{ Authorization: this.token }" ref="upload"
            :auto-upload="false" :on-error="onError" :on-success="onSuccess" :on-change="onChange"
            :before-remove="beforeRemove" :limit="1">
            <el-button slot="trigger" icon="el-icon-upload2">Click to Upload</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item label="Image Path" prop="imagePath">
          <el-input v-model="form.imagePath" style="width: 90%"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" :disabled="!enableSubmit" @click="onSubmit">Submit</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog :title="'Tagger ( ' + periodTitle + ' - ' + currentDate + ' )'" :visible.sync="dialogTaggerVisible"
      width="96%">
      <basic-data-tagger :period="currentPeriod" :date="currentDate" :taskId="currentTaskId"
        :taggered="currentTaggered" />
      <span slot="footer">
        <el-button size="small" @click="dialogTaggerVisible = false">Cancel</el-button>
        <el-button type="primary" size="small" :disabled="!currentTaggered" @click="confirmTagger">OK</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="'Detail ( ' + periodTitle + ' - ' + currentDate + ' )'" :visible.sync="dialogDetailVisible"
      width="70%">
      <basic-data-detail v-on:addUserId="addUserId" v-on:removeUserId="removeUserId" :period="currentPeriod"
        :date="currentDate" :selectedUserIds="selectedUserIds" :initSingle="initSingle" />
      <span slot="footer">
        <el-button size="small" @click="dialogDetailVisible = false">Cancel</el-button>
        <el-button type="primary" size="small" @click="confirmDetail">OK</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  fetchBasicRecord,
  deleteBasic,
  taggerBasic,
  confirmTaggerBasic,
  taggerBasicAddition,
  transformBasic,
} from "@/api/admin";
import Pagination from "@/components/Pagination";
import BasicDataTagger from "@/components/admin/BasicDataTagger";
import BasicDataDetail from "@/components/admin/BasicDataDetail";
import { aiApiRoot } from "@/utils/server";
import { getToken } from "@/utils/auth";
import dayjs from "dayjs";

export default {
  components: { Pagination, BasicDataTagger, BasicDataDetail },
  data() {
    return {
      token: "Bearer " + getToken(),
      dialogNewVisible: false,
      form: {
        date: "",
        linkDate: "",
        yearTags: "",
        period: "week",
        platform: "tmall",
      },
      inputVisible: false,
      inputValue: "",
      yearTags: [],
      rules: {
        date: [
          { required: true, message: "Please select Date", trigger: "change" },
        ],
        linkDate: [
          {
            required: true,
            message: "Please select Map Date",
            trigger: "change",
          },
        ],
      },
      enableSubmit: false,
      tableData: [],
      listQuery: {
        q: "",
        period: "week",
        platform: "tmall",
        offset: 0,
        limit: 10,
      },
      lastListQuery: null,
      total: 0,
      timer: null,
      dialogTaggerVisible: false,
      dialogDetailVisible: false,
      currentDate: "",
      currentPeriod: "week",
      currentTaskId: 0,
      currentTaggered: false,
      selectedUserIds: [],
      initSingle: false,
      dataLoading: false,
    };
  },
  props: {
    analysisType: {
      type: String,
      default: "sales",
    },
  },
  beforeDestroy() {
    clearTimeout(this.timer);
  },
  methods: {
    active() {
      this.fetchData();
    },
    closeDialog() {
      this.dialogNewVisible = false;
    },
    changeType() {
      this.$refs.form.resetFields();
      this.$refs.upload.clearFiles();
      this.enableSubmit = false;
    },
    handleYearClose(tag) {
      this.yearTags.splice(this.yearTags.indexOf(tag), 1);
    },
    showYearInput() {
      this.inputVisible = true;
      this.$nextTick(() => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    handleYearInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue && !inputValue.includes(",")) {
        this.yearTags.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = "";
    },
    onSubmit() {
      this.enableSubmit = false;
      if (this.form.date) {
        this.$refs.upload.submit();
      }
    },
    onSuccess(response) {
      this.$refs.form.resetFields();
      this.$refs.upload.clearFiles();
      const h = this.$createElement;
      if (response.success) {
        this.$msgbox({
          title: "Finish upload",
          message: h("div", null, [
            h("i", { class: "el-icon-success" }),
            h(
              "span",
              { style: "margin-left: 10px" },
              "Successful upload, please waiting for processing !"
            ),
          ]),
        });
      } else {
        this.$message({
          showClose: false,
          message: response && response.error ? response.error.message : "",
          type: "error",
        });
      }
      this.fetchData();
    },
    onError(err) {
      this.$refs.form.resetFields();
      this.$refs.upload.clearFiles();
      if (err && err.message) {
        let res = JSON.parse(err.message);
        if (res && res.error && res.error.message) {
          this.$message({
            showClose: false,
            message: res.error.message,
            type: "error",
          });
          return;
        }
      }
      this.$message({
        showClose: false,
        message: err,
        type: "error",
      });
    },
    onChange(file, fileList) {
      if (fileList && fileList.length > 0) {
        this.enableSubmit = true;
      } else {
        this.enableSubmit = false;
      }
    },
    beforeRemove() {
      this.enableSubmit = false;
      return true;
    },
    fetchData() {
      this.dataLoading = true;
      this.lastListQuery = { ...this.listQuery, type: this.analysisType };
      fetchBasicRecord(this.lastListQuery)
        .then((response) => {
          if (response.success) {
            this.tableData = response.result.items || [];
            this.total = response.result.totalCount;
            this.fetchNotFinished();
          }
        })
        .finally(() => {
          this.dataLoading = false;
        });
    },
    fetchNotFinished() {
      let dates = [];
      this.tableData &&
        this.tableData.forEach((t) => {
          if (
            t.status === "deleting" ||
            t.status === "processing" ||
            t.status === "syncing" ||
            t.status === "taggering"
          ) {
            dates.push(t.date);
          }
        });
      clearTimeout(this.timer);
      if (dates.length > 0) {
        this.timer = setTimeout(
          () => this.fetchDatesData(dates.join(",")),
          4000
        );
      }
    },
    fetchDatesData(dates) {
      fetchBasicRecord({
        type: this.analysisType,
        period: this.listQuery.period,
        platform: this.listQuery.platform,
        dates,
      }).then((response) => {
        if (response.success) {
          // match data
          if (this.tableData) {
            // taggered status check
            response.result &&
              response.result.items &&
              response.result.items.forEach((e) => {
                if (e.taskId === this.currentTaskId) {
                  this.currentTaggered = e.status === "taggered";
                }
              });

            for (let i = 0; i < this.tableData.length; i++) {
              if (dates.indexOf(this.tableData[i].date) === -1) {
                continue;
              }
              let match = false;
              if (!response.result.items) {
                this.$delete(this.tableData, i);
                i--;
                continue;
              }
              for (let j = 0; j < response.result.items.length; j++) {
                const t = response.result.items[j];
                if (this.tableData[i].date === t.date) {
                  this.$set(this.tableData, i, t);
                  match = true;
                  break;
                }
              }
              if (!match) {
                this.$delete(this.tableData, i);
                i--;
              }
            }
          }
          this.fetchNotFinished();
        }
      });
    },
    deleteAll(period, date) {
      deleteBasic(this.analysisType, period, date).then((response) => {
        if (response.success) {
          this.fetchData();
        }
      });
    },
    openTaggerDialog(row) {
      this.dialogTaggerVisible = true;
      this.currentPeriod = row.period;
      this.currentDate = row.date;
      this.currentTaskId = row.taskId;
      this.currentTaggered = row.status === "taggered";
    },
    startTagger(period, date) {
      this.$confirm("Start tagger?", "Please confirm", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        taggerBasic(period, date).then((response) => {
          if (response.success) {
            this.fetchData();
            this.$notify({
              title: "Start tagger successful",
              type: "success",
            });
          }
        });
      });
    },
    startReset(period, date) {
      this.$confirm("Reset to processed?", "Please confirm", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        transformBasic(period, date, { status: "processed" }).then(
          (response) => {
            if (response.success) {
              this.fetchData();
              this.$notify({
                title: "Reset to processed successful",
                type: "success",
              });
            }
          }
        );
      });
    },
    confirmTagger() {
      if (this.$store.getters.needRecount) {
        this.$notify({
          title: "Please recount the classify changes first",
          type: "warning",
        });
        return;
      }
      this.$confirm(
        "The basic data will be available and can not tagger again.",
        "Confirm tagger? ",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      )
        .then(() => {
          confirmTaggerBasic(this.currentPeriod, this.currentDate).then(
            (response) => {
              if (response.success) {
                this.fetchData();
                this.$notify({
                  title: "Confirm tagger successful",
                  type: "success",
                });
                this.dialogTaggerVisible = false;
              }
            }
          );
        })
        .catch(() => { });
    },
    openDetailDialog(row) {
      this.currentPeriod = row.period;
      this.currentDate = row.date;
      this.selectedUserIds = [];
      this.initSingle = !this.initSingle;
      this.dialogDetailVisible = true;
    },
    addUserId(id) {
      for (let i = 0; i < this.selectedUserIds.length; i++) {
        if (this.selectedUserIds[i] == id) {
          return;
        }
      }
      this.selectedUserIds.push(id);
    },
    removeUserId(id) {
      this.selectedUserIds.forEach((s, i) => {
        if (id == s) {
          this.selectedUserIds.splice(i, 1);
        }
      });
    },
    confirmDetail() {
      if (this.selectedUserIds.length === 0) {
        this.dialogDetailVisible = false;
        return;
      }
      let param = {
        userIds: this.selectedUserIds.join(","),
        period: this.currentPeriod,
        dates: this.currentDate,
      };
      taggerBasicAddition(param).then((response) => {
        if (response.success) {
          this.fetchData();
          this.$notify({
            title: "Start tagger additional successful",
            type: "success",
          });
          this.dialogDetailVisible = false;
        }
      });
    },
  },
  watch: {
    yearTags() {
      this.form.yearTags = this.yearTags.join(",");
    },
  },
  computed: {
    action() {
      return `${aiApiRoot}/v1/admins/analysis-basic/${this.analysisType}/import`;
    },
    formatDate() {
      if (this.form.period === "month") {
        return dayjs(this.form.date).format("YYYYMM");
      }
      return dayjs(this.form.date).format("YYYYMMDD");
    },
    formatLinkDate() {
      if (this.form.period === "month") {
        return dayjs(this.form.linkDate).format("YYYYMM");
      }
      return dayjs(this.form.linkDate).format("YYYYMMDD");
    },
    adminType() {
      return this.$store.getters.my && this.$store.getters.my.adminType
        ? this.$store.getters.my.adminType
        : "";
    },
    periodTitle() {
      switch (this.currentPeriod) {
        case "month":
          return "Month";
        default:
          return "Week";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.basic-data {
  .toolbar {
    margin-bottom: 10px;
    text-align: right;

    .new-btn {
      float: left;
    }

    .search-bar {
      display: inline-block;

      .el-select {
        margin-right: 10px;
      }

      /deep/ .el-select .el-input {
        width: 125px;
      }

      .el-input {
        width: 320px;
      }
    }
  }

  .new-dialog {
    .el-tag {
      margin-right: 10px;
    }

    .button-new-tag {
      height: 32px;
      line-height: 30px;
      padding-top: 0;
      padding-bottom: 0;
    }

    .input-new-tag {
      width: 90px;
      vertical-align: bottom;
    }
  }

  .table-expand {
    /deep/ label {
      color: #a9a9a9;
    }

    .el-tag {
      margin-right: 10px;
    }

    .el-form-item {
      margin-bottom: 0;
    }
  }

  /deep/ .new-dialog .el-form {
    margin-top: 10px;
  }
}
</style>