<template>
  <div class="global-trends" v-loading.fullscreen.lock="loading">
    <div class="new-btn">
      <el-button :loading="infoLoading" icon="el-icon-plus" size="small" type="warning"
        @click="dialogUploadVisible = true">Upload</el-button>
    </div>
    <el-dialog :visible.sync="dialogUploadVisible" @close="dialogUploadVisible = false" title="Import Global Trend"
      width="40%">
      <el-upload class="upload-btn" :action="aiApiRoot + '/v1/admins/global-trends/import'" accept=".csv, .xlsx, .xls"
        :headers="{ Authorization: this.token }" ref="upload" :auto-upload="true" :before-upload="beforeUpload"
        :on-error="onError" :on-success="onSuccess" :limit="1">
        <el-button size="small" slot="trigger" icon="el-icon-upload2">Click to Upload</el-button>
      </el-upload>
      <span slot="footer">
        <el-button type="primary" size="small" @click="dialogUploadVisible = false">OK</el-button>
      </span>
    </el-dialog>
    <div class="status-info">
      Last time upload status:
      <el-tag :type="this.info | statusFilter" effect="dark" size="small">
        {{ this.info
          ? this.info.status === "waiting"
            ? $t("tagger.waiting")
            : this.info.status === "running"
              ? $t("tagger.running")
              : this.info.status === "done"
                ? $t("tagger.done")
                : this.info.status === "abort"
                  ? $t("tagger.abort")
                  : this.info.status === "error"
                    ? $t("tagger.error")
                    : "-"
          : "-" }}
      </el-tag>
      <div v-if="info.status === 'done'">
        Total rows: {{ info.totalRows }}, affected rows: {{ info.affectedRows }}
      </div>
      <div v-if="info.status === 'error'">
        Error message: {{ info.errMsg }}
      </div>
    </div>
  </div>
</template>
  
<script>
import { fetchGlobalTrendImportInfo } from "@/api/admin";
import { aiApiRoot } from "@/utils/server";
import { getToken } from "@/utils/auth";

export default {
  data() {
    return {
      token: "Bearer " + getToken(),
      aiApiRoot,
      dialogUploadVisible: false,
      tableData: [],
      info: {},
      loading: false,
      infoLoading: false,
      timer: null,
    };
  },
  beforeUnmount() {
    clearTimeout(this.timer);
  },
  methods: {
    active() {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.fetchInfo();
    },
    fetchInfo() {
      fetchGlobalTrendImportInfo().then(response => {
        if (response.success) {
          this.info = response.result;
          if (this.info.status === 'running') {
            this.infoLoading = true;
            this.timer = setTimeout(
              this.fetchInfo,
              2000,
            );
          } else {
            clearTimeout(this.timer);
            this.infoLoading = false;
          }
        }
      })
    },
    onSuccess(response) {
      this.$refs.upload.clearFiles();
      if (!response.success) {
        this.$message({
          showClose: false,
          message: response && response.error ? response.error.message : "",
          type: "error",
        });
      }
      this.loading = false;
      this.dialogUploadVisible = false;
      this.fetchInfo();
    },
    onError(err) {
      this.loading = false;
      this.$refs.upload.clearFiles();
      if (err && err.message) {
        let res = JSON.parse(err.message);
        if (res && res.error && res.error.message) {
          this.$message({
            showClose: false,
            message: res.error.message,
            type: "error",
          });
          return;
        }
      }
      this.$message({
        showClose: false,
        message: err,
        type: "error",
      });
    },
    beforeUpload() {
      this.loading = true;
      return true;
    },
  },
  filters: {
    statusFilter(info) {
      if (!info) {
        return "info";
      }
      const statusMap = {
        waiting: "warning",
        running: "",
        done: "success",
        abort: "info",
        error: "danger",
      };
      return statusMap[info.status];
    },
  },
};
</script>
  
<style lang="scss" scoped>
.global-trends {

  .new-btn {
    width: 100%;
    margin-bottom: 10px;
  }

  .upload-btn {
    display: inline-block;
    margin: 10px 20px 10px 0;
  }

  .status-info {
    margin-top: 20px;
    color: #606266;
  }
}
</style>