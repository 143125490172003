<template>
  <div class="drive" v-loading="dataLoading">
    <el-tabs
      class="catalog"
      v-model="driveType"
      tab-position="left"
      @tab-click="fetchData"
    >
      <el-tab-pane label="Brand Images" name="images"></el-tab-pane>
    </el-tabs>
    <div class="content">
      <div class="new-btn">
        <el-button
          icon="el-icon-plus"
          size="small"
          type="primary"
          @click="dialogVisible = true"
          >New</el-button
        >
      </div>
      <el-table :data="tableData" row-key="id">
        <el-table-column prop="date" label="Date" width="128"></el-table-column>
        <el-table-column
          prop="platform"
          label="Platform"
          width="128"
        ></el-table-column>
        <el-table-column prop="path" label="Path"></el-table-column>
        <el-table-column label="Delete" width="98">
          <template slot-scope="scope">
            <el-popconfirm
              title="Confirm delete?"
              @confirm="deleteAll(scope.row.date)"
            >
              <el-button
                type="danger"
                icon="el-icon-delete"
                circle
                size="small"
                slot="reference"
              ></el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        v-show="total > 0"
        :total="total"
        :offset.sync="listQuery.offset"
        :limit.sync="listQuery.limit"
        layout="sizes, prev, pager, next, total "
        @pagination="fetchData"
      />
    </div>
    <el-dialog
      :visible.sync="dialogVisible"
      @close="closeDialog"
      :title="driveType === 'images' ? 'Create Brand Image' : ''"
      custom-class="drive-dialog"
    >
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        size="small"
        label-width="120px"
      >
        <el-form-item label="Date" prop="date" required>
          <el-input v-model="form.date"></el-input>
        </el-form-item>
        <el-form-item label="Path" prop="path" required>
          <el-input v-model="form.path"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button size="small" @click="closeDialog">Cancel</el-button>
        <el-button type="primary" size="small" @click="confirmDrive"
          >OK</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { createDrive, deleteDrive } from "@/api/admin";
import { fetchDrive } from "@/api/drive";
import Pagination from "@/components/Pagination";

export default {
  components: { Pagination },
  data() {
    return {
      driveType: "images",
      tableData: [],
      listQuery: {
        offset: 0,
        limit: 10,
      },
      total: 0,
      form: null,
      rules: {
        date: [{ required: true }],
        path: [{ required: true }],
      },
      dialogVisible: false,
      dataLoading: false,
    };
  },
  created() {
    this.initForm();
  },
  methods: {
    initForm() {
      this.form = {
        date: "",
        path: "",
      };
    },
    fetchData() {
      this.dataLoading = true;
      fetchDrive(this.driveType, this.listQuery)
        .then((response) => {
          if (response.success) {
            this.tableData = response.result.items || [];
            this.total = response.result.totalCount;
          }
        })
        .finally(() => {
          this.dataLoading = false;
        });
    },
    active() {
      this.initForm();
      this.fetchData();
    },
    deleteAll(date) {
      deleteDrive(this.driveType, date).then((response) => {
        if (response.success) {
          this.$notify({
            title: "Delete successful",
            type: "success",
          });
          this.fetchData();
        }
      });
    },
    closeDialog() {
      this.dialogVisible = false;
      this.$refs.form.resetFields();
      this.initForm();
    },
    confirmDrive() {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }
        createDrive({ type: this.driveType, ...this.form }).then((response) => {
          if (response.success) {
            this.$notify({
              title: "Create successful",
              type: "success",
            });
            this.closeDialog();
            this.fetchData();
          }
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.drive {
  .catalog {
    position: absolute;
  }
  .content {
    margin-left: 172px;

    .new-btn {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
  .drive-dialog .el-form {
    margin-top: 10px;
    margin-bottom: -10px;
    width: 90%;
  }
}
</style>