<template>
  <div class="user" v-loading="dataLoading">
    <div class="toolbar">
      <div class="search-bar">
        <el-input size="small" v-model="listQuery.q">
          <el-select v-model="listQuery.queryType" slot="prepend">
            <el-option label="User" value="user"></el-option>
            <el-option label="Organization" value="organization"></el-option>
          </el-select>
          <el-button slot="append" icon="el-icon-search" @click="fetchData"></el-button>
        </el-input>
      </div>
    </div>
    <el-table :data="tableData" row-key="id">
      <el-table-column prop="id" label="Id" min-width="50" fixed></el-table-column>
      <el-table-column prop="userName" label="User Name" fixed min-width="120"></el-table-column>
      <el-table-column prop="email" label="Email" min-width="190" fixed></el-table-column>
      <el-table-column prop="phoneNo" label="Phone No" min-width="130"></el-table-column>
      <el-table-column label="Organization" min-width="120">
        <template slot-scope="scope">
          {{ scope.row.organizationName ? scope.row.organizationName : "-" }}
        </template>
      </el-table-column>
      <el-table-column label="Available Services" min-width="180">
        <template slot-scope="scope">
          <div class="service-tag-container">
            <el-tag class="service-tag" size="small" v-for="s in scope.row.serviceCodes" :key="s">{{ s }}</el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Admin" min-width="110">
        <template slot-scope="scope">
          <span>{{ displayAdminType(scope.row.adminType) }}</span>
          <el-button type="text" circle size="small" icon="el-icon-edit" @click="openAdminDialog(scope.row)" />
        </template>
      </el-table-column>
      <el-table-column label="Role" min-width="110">
        <template slot-scope="scope">
          <span>{{ scope.row.role ? scope.row.role.name : "" }}</span>
          <el-button type="text" circle size="small" icon="el-icon-edit" @click="openRoleDialog(scope.row)" />
        </template>
      </el-table-column>
      <el-table-column label="Ready" min-width="65" align="center">
        <template slot-scope="scope">
          <div class="status-dot" :class="scope.row.analysisEnable ? 'success' : 'danger'" effect="dark"></div>
        </template>
      </el-table-column>
      <el-table-column label="Last Signin At" min-width="180">
        <template slot-scope="scope">
          <i class="el-icon-time"></i>
          <span style="margin-left: 10px">{{
    scope.row.lastSigninAt | displayTime()
  }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Last Active At" min-width="180">
        <template slot-scope="scope">
          <i class="el-icon-time"></i>
          <span style="margin-left: 10px">{{
      scope.row.lastActiveAt | displayTime()
    }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Signin Records" align="center" min-width="125" fixed="right">
        <template slot-scope="scope">
          <el-button type="primary" icon="el-icon-more" circle size="small"
            @click="openSigninDialog(scope.row)"></el-button>
        </template>
      </el-table-column>
    </el-table>
    <pagination v-show="total > 0" :total="total" :offset.sync="listQuery.offset" :limit.sync="listQuery.limit"
      layout="sizes, prev, pager, next, total " @pagination="fetchData" />
    <el-dialog title="Prompt" :visible.sync="dialogAdminVisible">
      <p><b>Please select admin type:</b></p>
      <el-radio-group v-model="currentAdminType" size="medium">
        <el-radio-button label="">Not</el-radio-button>
        <el-radio-button label="root">Root</el-radio-button>
        <el-radio-button label="checker">Checker</el-radio-button>
      </el-radio-group>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogAdminVisible = false">Cancel</el-button>
        <el-button type="primary" size="small" @click="confirmAdminType">OK</el-button>
      </span>
    </el-dialog>
    <el-dialog title="Prompt" :visible.sync="dialogRoleVisible">
      <p><b>Please select role:</b></p>
      <el-select v-model="currentRoleId">
        <el-option v-for="item in roleList" :key="item.id" :label="item.name" :value="item.id">
        </el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogRoleVisible = false">Cancel</el-button>
        <el-button type="primary" size="small" @click="confirmRole">OK</el-button>
      </span>
    </el-dialog>
    <el-dialog title="Last 10 singin records" :visible.sync="dialogSigninVisible" width="90%">
      <user-signin :userId="currentUserId" />
      <span slot="footer">
        <el-button type="primary" size="small" @click="dialogSigninVisible = false">OK</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import UserSignin from "@/components/admin/UserSignin";
import { fetchUser, updateUser, fetchRole } from "@/api/admin";

export default {
  components: { Pagination, UserSignin },
  data() {
    return {
      tableData: [],
      listQuery: {
        queryType: "user",
        offset: 0,
        limit: 10,
      },
      total: 0,
      dialogAdminVisible: false,
      dialogRoleVisible: false,
      dialogSigninVisible: false,
      currentAdminType: "none",
      currentRoleId: 0,
      currentUserId: 0,
      dataLoading: false,
      roleList: [],
    };
  },
  methods: {
    fetchData() {
      this.dataLoading = true;
      fetchUser(this.listQuery)
        .then((response) => {
          if (response.success) {
            this.tableData = response.result.items || [];
            this.total = response.result.totalCount;
          }
        })
        .finally(() => {
          this.dataLoading = false;
        });
    },
    active() {
      this.fetchData();
    },
    openSigninDialog(row) {
      this.currentUserId = row.id;
      this.dialogSigninVisible = true;
    },
    openAdminDialog(row) {
      this.currentAdminType = row.adminType;
      this.currentUserId = row.id;
      this.dialogAdminVisible = true;
    },
    confirmAdminType() {
      this.dialogAdminVisible = false;
      updateUser(this.currentUserId, {
        adminType: this.currentAdminType,
      }).then((response) => {
        if (response.success) {
          this.$notify({
            title: "Save successful",
            type: "success",
          });
          this.fetchData();
        }
      });
    },
    displayAdminType(adminType) {
      switch (adminType) {
        case "root":
          return "Root";
        case "checker":
          return "Checker";
        default:
          return "Not";
      }
    },
    openRoleDialog(row) {
      this.currentRoleId = row.role.id;
      this.currentUserId = row.id;
      this.dialogRoleVisible = true;
      this.roleList = [];
      fetchRole({ limit: -1 }).then(response => {
        if (response.success) {
          let list = response.result.items || [];
          this.roleList = list.filter(t => t.id !== 1)
        }
      })
    },
    confirmRole() {
      this.dialogRoleVisible = false;
      updateUser(this.currentUserId, {
        roleId: this.currentRoleId,
      }).then((response) => {
        if (response.success) {
          this.$notify({
            title: "Save successful",
            type: "success",
          });
          this.fetchData();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.user {
  .toolbar {
    width: 100%;
    margin-bottom: 10px;
    text-align: right;

    .search-bar {
      display: inline-block;

      /deep/ .el-select .el-input {
        width: 125px;
      }

      .el-input {
        width: 400px;
      }
    }
  }

  .status-dot {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    display: inline-block;
  }

  .status-dot.success {
    background-color: #67c23a;
  }

  .status-dot.danger {
    background-color: #f56c6c;
  }

  .service-tag-container {
    margin: -3px -3px;

    .service-tag {
      margin: 3px 3px;
    }
  }
}
</style>