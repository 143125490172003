<template>
  <div class="tagger-check-tagger">
    <el-form :inline="true" size="small" class="search-form">
      <el-form-item class="search-form-gutter" label="Item">
        <el-select
          style="width: 168px"
          v-model="item"
          @change="handleChangeItem"
        >
          <el-option
            v-for="item in t2List"
            :key="item.value"
            :label="item.label"
            :value="item.value"
            :disabled="item.disabled"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="search-form-gutter" label="Specific Item">
        <el-select
          style="width: 168px"
          v-model="specificItem"
          @change="handleChangeSpecificItem"
        >
          <el-option
            v-for="item in t3List(item)"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="search-form-gutter">
        <el-button
          icon="el-icon-refresh"
          type="primary"
          size="small"
          @click="searchClassifyDetails"
          >Refresh</el-button
        >
      </el-form-item>
      <el-form-item>
        <i class="el-icon-warning-outline"></i
        ><span>&nbsp;Press ⬅️ or ➡️ to switch expanded items.</span>
      </el-form-item>
      <el-form-item style="float: right">
        <el-popover placement="right" title="Help" width="920" trigger="click">
          <el-form :inline="true">
            <el-form-item label="T3 Tip">
              <el-switch v-model="t3TipEnable" @change="t3TipEnableChange">
              </el-switch>
            </el-form-item>
            <el-form-item label="Trend Point Tip">
              <el-switch
                v-model="trendPointEnable"
                @change="trendPointEnableChange"
              >
              </el-switch>
            </el-form-item>
          </el-form>
          <el-tabs>
            <el-tab-pane label="Bottom">
              <el-image
                :src="require('@/assets/tip_tagger/BOTTOM.jpg')"
                fit="contain"
              />
            </el-tab-pane>
            <el-tab-pane label="Dress">
              <el-image
                :src="require('@/assets/tip_tagger/DRESS.jpg')"
                fit="contain"
              />
            </el-tab-pane>
            <el-tab-pane label="Outer">
              <el-image
                :src="require('@/assets/tip_tagger/OUTER.jpg')"
                fit="contain"
              />
            </el-tab-pane>
            <el-tab-pane label="Top">
              <el-image
                :src="require('@/assets/tip_tagger/TOP.jpg')"
                fit="contain"
              />
            </el-tab-pane>
            <el-tab-pane label="Trend Point">
              <el-image
                :src="require('@/assets/tip_tagger/TREND_POINT.jpg')"
                fit="contain"
              />
            </el-tab-pane>
          </el-tabs>
          <el-button icon="el-icon-s-help" slot="reference">Help</el-button>
        </el-popover>
      </el-form-item>
    </el-form>
    <el-table
      v-loading="tableLoading"
      class="tagger-table"
      :expand-row-keys="expandKeys"
      row-key="id"
      :row-class-name="getRowClass"
      :row-style="{ cursor: 'pointer' }"
      @row-click="clickRowHandle"
      @expand-change="clickRowHandle"
      :data="tableData"
      @filter-change="filterChangeHandle"
    >
      <el-table-column type="expand">
        <template slot-scope="scope">
          <div>
            <el-col :span="8">
              <el-popover
                width="200px"
                placement="right"
                :disabled="!trendPointEnable"
                :title="trendPointTip ? trendPointTip.title : ''"
                trigger="manual"
                :value="trendPointTip != null"
                popper-class="trend-point-tip"
                style="top: 0 !important"
              >
                <div class="tip-sub-title">
                  {{ trendPointTip ? trendPointTip.original : "" }}
                </div>
                <img
                  width="200px"
                  :src="trendPointTip ? trendPointTip.url : ''"
                />
                <el-image
                  slot="reference"
                  width="100%"
                  :src="
                    scope.row.image.url
                      | imageUrl(task ? task.isExternalImage : false)
                  "
                  :preview-src-list="[
                    imageUrl(
                      scope.row.image.url,
                      task ? task.isExternalImage : false
                    ),
                  ]"
                  fit="contain"
                />
              </el-popover>
            </el-col>
            <el-col :span="16">
              <el-row class="tagger-table-row">
                <el-col :span="9">
                  <el-form inline>
                    <el-form-item class="tagger-table-form" label="T2">
                      <el-col
                        class="tagger-btn-container"
                        v-for="item in t2List"
                        :key="item.value"
                        :span="12"
                      >
                        <el-button
                          size="mini"
                          style="width: 100%"
                          :type="current.t2 === item.value ? 'primary' : ''"
                          @click="clickT2(item.value)"
                          >{{ item.label }}</el-button
                        >
                      </el-col>
                    </el-form-item>
                  </el-form>
                </el-col>
                <el-col :span="15">
                  <el-form inline>
                    <el-form-item class="tagger-table-form" label="T3">
                      <el-col
                        class="tagger-btn-container"
                        v-for="item in t3List(current.t2)"
                        :key="item.value"
                        :span="8"
                      >
                        <el-popover
                          :disabled="!t3TipEnable"
                          placement="left-start"
                          :title="t3tip ? t3tip.title : ''"
                          trigger="hover"
                          @show="showT3Tip(item.value)"
                        >
                          <div class="tip-sub-title">
                            {{ t3tip ? t3tip.original : "" }}
                          </div>
                          <img :src="t3tip ? t3tip.url : ''" />
                          <el-button
                            slot="reference"
                            size="mini"
                            style="width: 100%"
                            :type="current.t3 === item.value ? 'primary' : ''"
                            @click="clickT3(item.value)"
                            >{{ item.label }}
                          </el-button>
                        </el-popover>
                      </el-col>
                    </el-form-item>
                  </el-form>
                </el-col>
              </el-row>
              <el-row class="tagger-table-row">
                <el-col :span="8" v-if="options.COLOR">
                  <el-form label-position="top">
                    <el-form-item class="tagger-table-form" label="Color">
                      <el-color-picker
                        class="tagger-color-picker"
                        size="mini"
                        color-format="rgb"
                        :show-alpha="false"
                        v-model="currentColors[index]"
                        v-for="(_, index) in currentColors"
                        :key="index"
                      ></el-color-picker>
                      <el-button
                        class="tagger-color-add"
                        icon="el-icon-plus"
                        size="mini"
                        plain
                        @click="clickColorAdd"
                      ></el-button>
                      <el-button
                        class="tagger-color-add"
                        icon="el-icon-minus"
                        size="mini"
                        plain
                        @click="clickColorRemove"
                      ></el-button>
                    </el-form-item>
                  </el-form>
                </el-col>
                <el-col :span="8" v-if="options.PATTERN">
                  <el-form label-position="top">
                    <el-form-item class="tagger-table-form" label="Pattern">
                      <el-select
                        v-model="current.pattern"
                        size="mini"
                        filterable
                      >
                        <el-option
                          v-for="item in options.PATTERN"
                          :key="item"
                          :label="item"
                          :value="item"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-form>
                </el-col>
                <el-col :span="8" v-if="options.STYLE">
                  <el-form label-position="top">
                    <el-form-item class="tagger-table-form" label="Style">
                      <el-select v-model="current.style" size="mini" filterable>
                        <el-option
                          v-for="item in options.STYLE"
                          :key="item"
                          :label="item"
                          :value="item"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-form>
                </el-col>
                <el-col :span="8" v-if="options['COLLAR/NECK']">
                  <el-form label-position="top">
                    <el-form-item class="tagger-table-form" label="Collar">
                      <el-select
                        v-model="current.collarNeck"
                        size="mini"
                        filterable
                      >
                        <el-option
                          v-for="item in options['COLLAR/NECK']"
                          :key="item"
                          :label="item"
                          :value="item"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-form>
                </el-col>
                <el-col :span="8" v-if="options.FG">
                  <el-form label-position="top">
                    <el-form-item class="tagger-table-form" label="FG">
                      <el-select v-model="current.fg" size="mini" filterable>
                        <el-option
                          v-for="item in options.FG"
                          :key="item"
                          :label="item"
                          :value="item"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-form>
                </el-col>
                <el-col :span="8" v-if="options.FIT">
                  <el-form label-position="top">
                    <el-form-item class="tagger-table-form" label="Fit">
                      <el-select v-model="current.fit" size="mini" filterable>
                        <el-option
                          v-for="item in options.FIT"
                          :key="item"
                          :label="item"
                          :value="item"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-form>
                </el-col>
                <el-col :span="8" v-if="options.LENGTH">
                  <el-form label-position="top">
                    <el-form-item class="tagger-table-form" label="Length">
                      <el-select
                        v-model="current['length']"
                        size="mini"
                        filterable
                      >
                        <el-option
                          v-for="item in options.LENGTH"
                          :key="item"
                          :label="item"
                          :value="item"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-form>
                </el-col>
                <el-col
                  :span="8"
                  v-if="trendPointOptions && trendPointOptions.length > 0"
                >
                  <el-form label-position="top">
                    <el-form-item class="tagger-table-form" label="Trend Point">
                      <el-button
                        class="tagger-table-form-button"
                        type="primary"
                        @click="clickCheck"
                        size="mini"
                        >{{
                          "Select (" + currentTrendPointLen + ")"
                        }}</el-button
                      >
                    </el-form-item>
                  </el-form>
                </el-col>
              </el-row>
              <el-row class="tagger-table-row tagger-table-footer">
                <el-button size="mini" type="primary" @click="clickConfirm"
                  >Save</el-button
                >
                <el-button size="mini" @click="clickReset">Reset</el-button>
                <el-popconfirm
                  title="Confirm delete?"
                  @confirm="clickDelete(scope.row.id)"
                >
                  <el-button
                    class="tagger-table-footer-button"
                    type="danger"
                    size="mini"
                    slot="reference"
                    >Delete</el-button
                  >
                </el-popconfirm>
              </el-row>
            </el-col>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="image" label="Image" min-width="60px">
        <template slot-scope="scope">
          <el-avatar
            slot="reference"
            class="table-avatar"
            shape="square"
            :size="80"
            fit="contain"
            :src="
              scope.row.image.url
                | imageThumbnailUrl(task ? task.isExternalImage : false)
            "
            alt="image"
          />
        </template>
      </el-table-column>
      <el-table-column prop="t1" label="T1"></el-table-column>
      <el-table-column prop="t2" label="T2"> </el-table-column>
      <el-table-column prop="t3" label="T3"> </el-table-column>
      <el-table-column
        :filters="[
          { text: 'Checked', value: 'true' },
          { text: 'Not Checked', value: 'false' },
        ]"
        :filter-multiple="false"
        :filtered-value="['false']"
        label="Check By"
        min-width="90px"
        column-key="checkBy"
        key="checkBy"
      >
        <template slot-scope="scope">
          <i v-if="scope.row.checkBy" class="el-icon-success check-icon" />
          <span style="margin-left: 10px">{{ scope.row.checkBy }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="Create At"
        min-width="90"
        column-key="createdAt"
        key="createdAt"
      >
        <template slot-scope="scope">
          <i class="el-icon-time"></i>
          <span style="margin-left: 10px">{{
            scope.row.createdAt | displayTime()
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Update At" min-width="90">
        <template slot-scope="scope">
          <i class="el-icon-time"></i>
          <span style="margin-left: 10px">{{
            scope.row.updatedAt | displayTime()
          }}</span>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-show="total > 0"
      :total="total"
      :offset.sync="listQuery.offset"
      :limit.sync="listQuery.limit"
      layout="sizes, prev, pager, next, total "
      @pagination="searchClassifyDetails"
    >
      <span slot="addition">
        <el-button
          style="vertical-align: bottom"
          icon="el-icon-refresh"
          type="primary"
          size="small"
          @click="searchClassifyDetails"
          >Refresh</el-button
        >
      </span>
    </pagination>
    <TaggerCheckTrendPoint
      :visible="trendPointVisible"
      :current="current"
      @closed="trendPointVisible = false"
      @change="changeTrendPoint"
    />
  </div>
</template>

<script>
import Cookies from "js-cookie";
import tipTaggerT3 from "@/assets/tip_tagger_t3.json";
import Pagination from "@/components/Pagination";
import {
  fetchClassifyByTask,
  fetchItemByTask,
  updateClassify,
  deleteClassify,
} from "@/api/admin";
import { imageUrl } from "@/filters";
import TaggerCheckTrendPoint from "@/components/admin/TaggerCheckTrendPoint";
import { OssSourceURL } from "@/constants";

export default {
  components: { Pagination, TaggerCheckTrendPoint },
  data() {
    return {
      tableData: [],
      listQuery: {
        offset: 0,
        limit: 10,
      },
      total: 0,
      expandKeys: [],
      item: null,
      hasChecked: "false",
      specificItem: null,
      t2List: [],
      current: {},
      currentColors: [null],
      currentIdx: 0,
      currentTrendPointLen: 0,
      t3tip: null,
      trendPointTip: null,
      t3TipEnable: true,
      trendPointEnable: true,
      tableLoading: false,
      trendPointVisible: false,
    };
  },
  props: {
    task: {
      type: Object,
      default: () => {
        return {
          id: 0,
        };
      },
    },
  },
  created() {
    let t2Flatten = this.$store.getters.t2Flatten;
    this.t2List = [];
    t2Flatten &&
      t2Flatten.forEach((t) => {
        t.disabled = true;
        this.t2List.push(t);
      });
    this.fetchItem();

    let that = this;
    this.handler = function () {
      if (!that.tableData || that.tableData.length === 0) {
        return;
      }
      let key = window.event.keyCode;
      if (key === 37) {
        // active last
        if (that.expandKeys && that.expandKeys.length > 0) {
          let currentId = that.expandKeys[0];
          for (let i = 0; i < that.tableData.length; i++) {
            if (that.tableData[i].id === currentId) {
              if (i === 0) {
                that.expandKeys = [];
              } else {
                that.expandKeys = [that.tableData[i - 1].id];
              }
            }
          }
        }
      } else if (key === 39) {
        // active next
        if (that.expandKeys && that.expandKeys.length > 0) {
          let currentId = that.expandKeys[0];
          for (let i = 0; i < that.tableData.length; i++) {
            if (that.tableData[i].id === currentId) {
              if (that.tableData.length > i + 1) {
                that.expandKeys = [that.tableData[i + 1].id];
              } else {
                that.expandKeys = [];
              }
            }
          }
        } else if (that.tableData && that.tableData.length > 0) {
          that.expandKeys = [that.tableData[0].id];
        }
      }
    };
  },
  mounted() {
    window.addEventListener("keydown", this.handler);
    let disableT3Tip = Cookies.get("disable-t3-tip");
    if (disableT3Tip === "true") {
      this.t3TipEnable = false;
    }
    let disableTrendPointTip = Cookies.get("disable-trendpoint-tip");
    if (disableTrendPointTip === "true") {
      this.trendPointEnable = false;
    }
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.handler);
  },
  methods: {
    handleChangeItem() {
      this.listQuery.offset = 0;
      this.searchClassifyDetails();
    },
    handleChangeSpecificItem() {
      this.listQuery.offset = 0;
      this.searchClassifyDetails();
    },
    searchClassifyDetails() {
      this.tableLoading = true;
      fetchClassifyByTask(this.task.id, {
        t2: this.item,
        t3: this.specificItem,
        hasChecked: this.hasChecked,
        ...this.listQuery,
      })
        .then((response) => {
          this.tableLoading = false;
          if (response.success) {
            this.tableData = response.result.items || [];
            this.total = response.result.totalCount;
          }
        })
        .catch(() => {
          this.tableLoading = false;
        });
    },
    fetchItem() {
      this.item = null;
      this.tableData = [];
      this.total = 0;
      fetchItemByTask(this.task.id).then((response) => {
        if (response.success) {
          this.t2List.forEach((e, i) => {
            let disabled = true;
            if (response.result) {
              for (let j = 0; j < response.result.length; j++) {
                const r = response.result[j];
                if (e.value === r) {
                  disabled = false;
                  break;
                }
              }
            }
            e.disabled = disabled;
            this.$set(this.t2List, i, e);
          });
        }
      });
      this.searchClassifyDetails();
    },
    filterChangeHandle(v) {
      if (v.checkBy) {
        if (v.checkBy.length > 0) {
          this.hasChecked = v.checkBy[0];
        } else {
          this.hasChecked = null;
        }
      }
      this.searchClassifyDetails();
    },
    imageUrl(url, isExternal) {
      return imageUrl(url, isExternal);
    },
    clearDetails() {
      this.current.pattern = "";
      this.current.style = "";
      this.current.collarNeck = "";
      this.current.fg = "";
      this.current.fit = "";
      this.current["length"] = "";
      this.current.trendPoints = null;
      this.freshTrendPointLen();
    },
    clickT2(value) {
      this.current.t2 = value;
      this.current.t3 = "";
      this.clearDetails();
    },
    clickT3(value) {
      this.current.t3 = value;
    },
    clickConfirm() {
      let colors = [];
      for (let i = 0; i < this.currentColors.length; i++) {
        const e = this.currentColors[i];
        if (e && e.startsWith("rgb(") && e.endsWith(")")) {
          let rgbs = e.substring(4, e.length - 1).split(",");
          if (!rgbs || rgbs.length !== 3) {
            continue;
          }
          colors.push({
            rgb: [parseInt(rgbs[0]), parseInt(rgbs[1]), parseInt(rgbs[2])],
          });
        }
      }
      this.current.color = JSON.stringify(colors);
      this.tableLoading = true;
      updateClassify(this.current.id, this.current)
        .then((response) => {
          this.tableLoading = false;
          if (response.success) {
            this.$notify({
              title: "Save classify successful",
              type: "success",
            });
            this.tableData[this.currentIdx] = JSON.parse(
              JSON.stringify(this.current)
            );
          }
        })
        .catch(() => {
          this.tableLoading = false;
        });
    },
    clickReset() {
      this.current = JSON.parse(
        JSON.stringify(this.tableData[this.currentIdx])
      );
      this.freshTrendPointLen();
      this.covertColor();
    },
    clickDelete(id) {
      deleteClassify(id).then((response) => {
        if (response.success) {
          this.$notify({
            title: "Delete classify successful",
            type: "success",
          });
          this.tableData.splice(this.currentIdx, 1);
          this.expandKeys = [];
        }
      });
    },
    clickColorAdd() {
      this.currentColors.push(null);
    },
    clickColorRemove() {
      this.currentColors.pop();
    },
    clickRowHandle(row) {
      if (
        this.expandKeys &&
        this.expandKeys.length > 0 &&
        this.expandKeys[0] === row.id
      ) {
        this.expandKeys = [];
      } else {
        this.expandKeys = [row.id];
      }
    },
    getRowClass({ row }) {
      return "row-" + row.id;
    },
    t3List(t2) {
      for (let i = 0; i < this.t2List.length; i++) {
        if (t2 === this.t2List[i].value) {
          return this.t2List[i].children;
        }
      }
      return [];
    },
    covertColor() {
      // 转"rgb": [0, 0, 0] -> rgb(0, 0, 0)格式
      if (this.current && this.current.color) {
        let colors = JSON.parse(this.current.color);
        this.currentColors = colors.map((v) => {
          if (!v.rgb || v.rgb.length !== 3) {
            return null;
          }
          return "rgb(" + v.rgb[0] + "," + v.rgb[1] + "," + v.rgb[2] + ")";
        });
      }
    },
    trendPointTipChange(val) {
      this.trendPointTip = val;
    },
    showT3Tip(t3) {
      if (!t3) {
        this.t3tip = null;
        return;
      }
      for (let i = 0; i < tipTaggerT3.length; i++) {
        const e = tipTaggerT3[i];
        if (e.t2 == this.current.t2 && e.en == t3) {
          this.t3tip = {
            original: e.en,
            title: e.cn,
            url: OssSourceURL + "tip-tagger-t3/" + e.t2 + "/" + e.pic,
          };
          return;
        }
      }
      this.t3tip = null;
    },
    t3TipEnableChange(val) {
      Cookies.set("disable-t3-tip", !val);
    },
    trendPointEnableChange(val) {
      Cookies.set("disable-trendpoint-tip", !val);
    },
    clickCheck() {
      this.trendPointVisible = true;
    },
    changeTrendPoint(list) {
      this.current.trendPoints = list;
      this.freshTrendPointLen();
    },
    freshTrendPointLen() {
      this.currentTrendPointLen = this.current.trendPoints
        ? Object.keys(this.current.trendPoints).length
        : 0;
    },
  },
  computed: {
    options() {
      if (!this.current || !this.current.t2) {
        return {};
      }
      let v = {};
      let mappings = this.$store.getters.taggerMapping[this.current.t2];
      if (mappings) {
        mappings.forEach((t) => {
          v[t] = this.$store.getters.taggerOptions[t];
        });
      }
      return v;
    },
    trendPointOptions() {
      if (!this.current || !this.current.t2) {
        return [];
      }
      return this.$store.getters.taggerTrendPointTagger[this.current.t2]
        ? this.$store.getters.taggerTrendPointTagger[this.current.t2]
        : [];
    },
  },
  watch: {
    task() {
      this.fetchItem();
    },
    expandKeys() {
      // 定位元素
      if (this.expandKeys && this.expandKeys.length > 0) {
        for (let i = 0; i < this.tableData.length; i++) {
          if (this.tableData[i].id === this.expandKeys[0]) {
            this.current = JSON.parse(JSON.stringify(this.tableData[i]));
            this.freshTrendPointLen();
            this.currentIdx = i;
            this.covertColor();
            break;
          }
        }

        let es = document.getElementsByClassName("row-" + this.expandKeys[0]);
        if (es.length > 0) {
          this.$nextTick(() => {
            es[0].scrollIntoView();
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.tagger-check-tagger {
  margin-top: 10px;

  .table-avatar {
    vertical-align: middle;
    border: 1px solid #e8e8e8;
  }

  .check-icon {
    color: #67c23a;
  }

  .search-form {
    width: auto;
    margin-left: 10px;

    .search-form-gutter {
      margin-right: 30px;
    }
  }

  .tagger-table {
    .tagger-table-row {
      padding-left: 5px;

      .tagger-table-form {
        /deep/ .el-form-item__label {
          margin-left: 10px;
          padding-bottom: 0;
        }

        /deep/ .el-form-item__content {
          margin-top: -10px;
        }

        margin-bottom: 5px;
        margin-right: 0;

        .tagger-btn-container {
          padding-left: 5px !important;
          padding-right: 5px !important;
        }

        .el-select {
          margin-left: 5px;
          width: 90%;
        }

        .tagger-table-form-button {
          margin-left: 5px;
          width: 60%;
        }

        .tagger-color-picker {
          vertical-align: middle;
          margin-left: 5px;

          /deep/.el-color-picker__trigger {
            padding: 0;
            border: 0;
          }

          /deep/ .el-color-picker__color {
            border-color: #dcdfe6;
          }
        }

        .tagger-color-add {
          margin-left: 5px;
          padding: 7px 8px;
        }
      }
    }

    .tagger-table-footer {
      padding-top: 10px;
      padding-left: 10px;

      .tagger-table-footer-button {
        margin-left: 10px;
      }
    }

    /deep/ .el-table__expanded-cell {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .info {
    text-align: center;
    margin: 20px 0;
  }
}
</style>