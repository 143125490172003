<template>
  <div>
    <el-table :data="tableData" row-key="id">
      <el-table-column prop="ip" label="IP" width="140"></el-table-column>
      <el-table-column prop="userAgent" label="User Agent"></el-table-column>
      <el-table-column label="Valid" width="80">
        <template slot-scope="scope">
          {{ scope.row.invalid ? "No" : "Yes" }}
        </template>
      </el-table-column>
      <el-table-column label="Active At" width="180">
        <template slot-scope="scope">
          <i class="el-icon-time"></i>
          <span style="margin-left: 10px">{{
            scope.row.activeAt | displayTime()
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Signin At" width="180">
        <template slot-scope="scope">
          <i class="el-icon-time"></i>
          <span style="margin-left: 10px">{{
            scope.row.createdAt | displayTime()
          }}</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { fetchUserSignin } from "@/api/admin";

export default {
  data() {
    return {
      tableData: [],
    };
  },
  props: {
    userId: {
      required: true,
      type: Number,
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.tableData = [];
      fetchUserSignin(this.userId).then((response) => {
        if (response.success) {
          this.tableData = response.result.items || [];
        }
      });
    },
  },
  watch: {
    userId() {
      this.fetchData();
    },
  },
};
</script>

<style>
</style>