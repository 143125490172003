<template>
  <div class="visual-files-admin" v-loading.fullscreen.lock="loading">
    <el-select class="type-select" size="small" v-model="type">
      <el-option
        v-for="item in typeList"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      ></el-option>
    </el-select>
    <el-input
      class="path-input"
      v-model="path"
      size="small"
      placeholder="e.g. oss://tm-dm/STREET FASHION/"
    />
    <el-button type="primary" size="small" @click="syncVisualFiles"
      >Sync</el-button
    >
  </div>
</template>

<script>
import { syncVisualFiles } from "@/api/admin";

export default {
  data() {
    return {
      path: "",
      type: "street",
      typeList: [
        {
          label: "Street Fashion",
          value: "street",
        },
        {
          label: "Window Fashion",
          value: "window",
        },
        {
          label: "Global Trend",
          value: "global",
        },
      ],
      loading: false,
    };
  },
  methods: {
    syncVisualFiles() {
      if (!this.path.startsWith("oss://")) {
        this.$message.error('Please input path start with "oss://"');
        return;
      }
      this.loading = true;
      syncVisualFiles({ path: this.path, type: this.type }).then((response) => {
        const h = this.$createElement;
        if (response.success) {
          let cnt = response.result ? response.result.totalCount : 0;
          this.$msgbox({
            title: "Finish sync",
            message: h("div", null, [
              h("i", { class: "el-icon-success" }),
              h(
                "span",
                { style: "margin-left: 10px" },
                "Successful sync " + cnt + " items"
              ),
            ]),
          });
        }
        this.loading = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.visual-files-admin {
  .path-input {
    width: 368px;
    margin-right: 10px;
  }
  .type-select {
    width: 168px;
    margin-right: 10px;
  }
}
</style>