<template>
  <div class="weekly-board" v-loading.fullscreen.lock="loading">
    <div class="new-btn">
      <el-button
        icon="el-icon-plus"
        size="small"
        type="warning"
        @click="dialogUploadVisible = true"
        >Upload</el-button
      >
    </div>
    <el-table :data="tableData" row-key="id">
      <el-table-column prop="date" label="Date"></el-table-column>
      <el-table-column prop="title" label="Title"></el-table-column>
      <el-table-column prop="subtitle" label="Subtitle"></el-table-column>
      <el-table-column prop="type" label="Type" min-width="60">
        <template slot-scope="scope">
          {{ typeName(scope.row.type) }}
        </template></el-table-column
      >
      <el-table-column label="Create At" min-width="110">
        <template slot-scope="scope">
          <i class="el-icon-time"></i>
          <span style="margin-left: 10px">{{
            scope.row.createdAt | displayTime()
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Update At" min-width="110">
        <template slot-scope="scope">
          <i class="el-icon-time"></i>
          <span style="margin-left: 10px">{{
            scope.row.updatedAt | displayTime()
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Details" min-width="50" align="center">
        <template slot-scope="scope">
          <el-button
            type="primary"
            size="small"
            icon="el-icon-more"
            circle
            @click="openDetailDialog(scope.row)"
          ></el-button>
        </template>
      </el-table-column>
      <el-table-column label="Delete" min-width="50" align="center">
        <template slot-scope="scope">
          <el-popconfirm
            title="Delete Weekly Board?"
            @confirm="deleteSummary(scope.row.id)"
          >
            <el-button
              type="danger"
              icon="el-icon-delete"
              circle
              size="small"
              slot="reference"
            ></el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-show="total > 0"
      :total="total"
      :offset.sync="listQuery.offset"
      :limit.sync="listQuery.limit"
      layout="sizes, prev, pager, next, total "
      @pagination="fetchData"
    />
    <el-dialog
      :visible.sync="dialogVisible"
      @close="closeDetailDialog"
      title="Weekly Board Details"
      width="90%"
    >
      <el-tabs v-model="currentTabName" class="item-tabs" type="card">
        <el-tab-pane
          v-for="d in detailTableData"
          :key="d.id"
          :label="d.category"
          :name="d.category"
        >
          <el-table :data="d.items" row-key="id">
            <el-table-column
              v-if="current && current.type === 'brand'"
              label="Brand"
              ><template slot-scope="scope">
                {{ getBrand(scope.row.brandId) }}</template
              ></el-table-column
            >
            <el-table-column
              v-if="current && current.type === 'brand'"
              prop="salesSum"
              label="SalesSum"
            ></el-table-column>
            <el-table-column
              v-if="current && current.type === 'brand'"
              prop="count"
              label="Count"
            ></el-table-column>
            <el-table-column
              v-if="current && current.type === 'item'"
              prop="item"
              label="Item"
            ></el-table-column>
            <el-table-column
              v-if="current && current.type === 'item'"
              prop="salesSum"
              label="SalesSum"
            ></el-table-column>
            <el-table-column
              v-if="current && current.type === 'item'"
              prop="totalSalesSum"
              label="TotalSalesSum"
            ></el-table-column>
            <el-table-column
              v-if="current && current.type === 'product'"
              prop="id"
              label="Id"
            ></el-table-column>
            <el-table-column
              v-if="current && current.type === 'product'"
              prop="brandId"
              label="BrandId"
            ></el-table-column>
            <el-table-column
              v-if="current && current.type === 'product'"
              prop="title"
              label="Title"
            ></el-table-column>
            <el-table-column
              v-if="current && current.type === 'product'"
              prop="listPrice"
              label="ListPrice"
            ></el-table-column>
            <el-table-column
              v-if="current && current.type === 'product'"
              prop="salePrice"
              label="SalePrice"
            ></el-table-column>
            <el-table-column
              v-if="current && current.type === 'product'"
              prop="price"
              label="Price"
            ></el-table-column>
            <el-table-column
              v-if="current && current.type === 'product'"
              prop="salesQty"
              label="SalesQty"
            ></el-table-column>
            <el-table-column
              v-if="current && current.type === 'product'"
              prop="imageUrl"
              label="ImageUrl"
            ></el-table-column>
            <el-table-column
              v-if="current && current.type === 'color'"
              prop="color"
              label="Color"
            ></el-table-column>
            <el-table-column
              v-if="current && current.type === 'color'"
              prop="count"
              label="Count"
            ></el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
      <span slot="footer">
        <el-button type="primary" size="small" @click="dialogVisible = false"
          >OK</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      :visible.sync="dialogUploadVisible"
      @close="dialogUploadVisible = false"
      title="Upload Weekly Board"
      width="40%"
    >
      <el-upload
        class="upload-btn"
        :action="aiApiRoot + '/v1/admins/summary/import'"
        accept=".csv, .xlsx, .xls"
        :headers="{ Authorization: this.token }"
        ref="upload"
        :auto-upload="true"
        :before-upload="beforeUpload"
        :on-error="onError"
        :on-success="onSuccess"
        :limit="1"
      >
        <el-button size="small" slot="trigger" icon="el-icon-upload2"
          >Click to Upload</el-button
        >
      </el-upload>
      <el-link href="/example-weekly-board.xlsx">Example file</el-link>
      <div class="external-info" v-if="lastUploadLength > 0">
        <i class="el-icon-info"></i>
        Successfully upload {{ lastUploadLength }} data
      </div>
      <span slot="footer">
        <el-button
          type="primary"
          size="small"
          @click="dialogUploadVisible = false"
          >OK</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  fetchSummary,
  fetchSummaryDetails,
  deleteSummary,
  fetchBrand,
} from "@/api/admin";
import Pagination from "@/components/Pagination";
import { aiApiRoot } from "@/utils/server";
import { getToken } from "@/utils/auth";

export default {
  components: { Pagination },
  data() {
    return {
      token: "Bearer " + getToken(),
      aiApiRoot,
      dialogVisible: false,
      dialogUploadVisible: false,
      current: null,
      currentTabName: "",
      tableData: [],
      listQuery: {
        date: "",
        offset: 0,
        limit: 10,
      },
      total: 0,
      detailTableData: [],
      typeList: [
        {
          key: "brand",
          name: "Brand",
        },
        {
          key: "item",
          name: "Item",
        },
        {
          key: "product",
          name: "Product",
        },
        {
          key: "color",
          name: "Color",
        },
      ],
      brandList: [],
      lastUploadLength: 0,
      loading: false,
    };
  },
  methods: {
    fetchData() {
      fetchSummary(this.listQuery).then((response) => {
        if (response.success) {
          this.tableData = response.result.items || [];
          this.total = response.result.totalCount;
        }
      });
    },
    fetchDetailData() {
      this.detailTableData = [];
      fetchSummaryDetails(this.current.id).then((response) => {
        if (response.success) {
          let items = response.result.items || [];
          items.forEach((e) => {
            for (let i = 0; i < this.detailTableData.length; i++) {
              let t = this.detailTableData[i];
              if (t.category === e.category) {
                t.items.push(JSON.parse(e.value));
                return;
              }
            }
            this.detailTableData.push({
              id: this.detailTableData.length,
              category: e.category,
              items: [JSON.parse(e.value)],
            });
          });
          if (this.detailTableData.length > 0) {
            this.currentTabName = this.detailTableData[0].category;
          }
        }
      });
    },
    active() {
      this.fetchData();
      this.fetchBrandList();
    },
    openDetailDialog(row) {
      this.current = row;
      this.dialogVisible = true;
      this.fetchDetailData();
    },
    closeDetailDialog() {
      this.dialogVisible = false;
      this.current = null;
      this.tabIndex = 0;
    },
    fetchBrandList() {
      fetchBrand({ limit: -1 }).then((response) => {
        if (response.success) {
          this.brandList =
            response.result && response.result.items
              ? response.result.items
              : [];
        }
      });
    },
    getBrand(id) {
      for (let i = 0; i < this.brandList.length; i++) {
        const e = this.brandList[i];
        if (e.id === id) {
          return e ? e.displayName : "";
        }
      }
    },
    onSuccess(response) {
      this.loading = false;
      this.$refs.upload.clearFiles();
      if (response.success) {
        this.lastUploadLength = response.result;
      } else {
        this.$message({
          showClose: false,
          message: response && response.error ? response.error.message : "",
          type: "error",
        });
      }
      this.fetchData();
    },
    onError(err) {
      this.loading = false;
      this.lastUploadLength = 0;
      this.$refs.upload.clearFiles();
      if (err && err.message) {
        let res = JSON.parse(err.message);
        if (res && res.error && res.error.message) {
          this.$message({
            showClose: false,
            message: res.error.message,
            type: "error",
          });
          return;
        }
      }
      this.$message({
        showClose: false,
        message: err,
        type: "error",
      });
    },
    beforeUpload() {
      this.lastUploadLength = 0;
      this.loading = true;
      return true;
    },
    deleteSummary(id) {
      deleteSummary(id).then((response) => {
        if (response.success) {
          this.$notify({
            title: "Delete successful",
            type: "success",
          });
          this.fetchData();
        }
      });
    },
    typeName(key) {
      for (let i = 0; i < this.typeList.length; i++) {
        if (this.typeList[i].key === key) {
          return this.typeList[i].name;
        }
      }
    },
  },
  computed: {
    allowItem() {
      return this.form.type !== "" && this.currentTitle !== "";
    },
  },
};
</script>

<style lang="scss" scoped>
.weekly-board {
  .new-btn {
    width: 100%;
    margin-bottom: 10px;
  }
  .dynamic-form {
    margin-top: 10px;
    margin-bottom: -10px;
    .el-divider {
      margin-top: 10px;
    }
    .el-input {
      margin-right: 10px;
      width: 170px;
    }
  }
  .item-tabs {
    margin-top: 10px;
  }

  .upload-btn {
    display: inline-block;
    margin: 10px 20px 10px 0;
  }
}
</style>