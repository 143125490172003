<template>
  <div class="container" v-loading="loading">
    <el-input class="search-input" size="small" v-model="listQuery.q" @keyup.enter.native="fetchData">
      <el-button slot="append" class="el-icon-search" @click="fetchData"></el-button>
    </el-input>
    <el-table ref="multiTable" :data="tableData" row-key="id" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="45"> </el-table-column>
      <el-table-column prop="userName" label="UserName" width="130"></el-table-column>
      <el-table-column prop="email" label="Email"></el-table-column>
    </el-table>
    <pagination v-show="total > 0" :total="total" :offset.sync="listQuery.offset" :limit.sync="listQuery.limit"
      :pagerCount="5" layout="prev, pager, next" @pagination="fetchData" />
  </div>
</template>

<script>
import { fetchUser } from "@/api/admin";
import Pagination from "@/components/Pagination";

export default {
  components: { Pagination },
  data() {
    return {
      tableData: [],
      listQuery: {
        q: "",
        offset: 0,
        limit: 5,
      },
      total: 0,
      loading: false,
    };
  },
  props: {
    ids: {
      required: true,
      type: Array,
    },
  },
  methods: {
    reopen() {
      this.listQuery = {
        q: "",
        offset: 0,
        limit: 5,
      };
      this.total = 0;
      this.fetchData();
    },
    fetchData() {
      this.loading = true;
      fetchUser(this.listQuery).then((response) => {
        if (response.success) {
          let ids = [...this.ids];
          this.tableData = response.result.items || [];
          this.total = response.result.totalCount;
          this.$nextTick(() => {
            this.tableData.forEach((e) => {
              this.$refs.multiTable.toggleRowSelection(
                e,
                ids.some((t) => e.id === t)
              );
            });
          });
        }
      }).finally(() => {
        this.loading = false;
      });
    },
    handleSelectionChange(val) {
      let ids = [...this.ids];
      this.tableData &&
        this.tableData.forEach((e) => {
          for (let i = 0; i < ids.length; i++) {
            if (ids[i] === e.id) {
              ids.splice(i, 1);
            }
          }
        });
      val &&
        val.forEach((e) => {
          if (!ids.includes(e.id)) {
            ids.push(e.id);
          }
        });
      this.$emit("select", ids);
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  /deep/ .el-radio__label {
    display: none;
  }

  .search-input {
    float: right;
    width: 50%;
  }

  .status-dot {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    display: inline-block;
  }

  .status-dot.success {
    background-color: #67c23a;
  }

  .status-dot.danger {
    background-color: #f56c6c;
  }
}
</style>