<template>
  <div
    class="nlp-task"
    v-loading.fullscreen.lock="loading"
    v-loading="dataLoading"
  >
    <div class="toolbar">
      <div class="new-btn">
        <el-button
          icon="el-icon-plus"
          size="small"
          type="primary"
          @click="dialogNewVisible = true"
          >New</el-button
        >
        <el-button
          icon="el-icon-notebook-2"
          size="small"
          type="primary"
          @click="dialogDictVisible = true"
          >Dict</el-button
        >
        <el-button icon="el-icon-refresh" size="small" @click="fetchData"
          >Refresh</el-button
        >
      </div>
    </div>
    <el-table :data="tableData" row-key="id" key="nlp-task-table">
      <el-table-column
        prop="desc"
        label="Desc"
        min-width="120"
      ></el-table-column>
      <el-table-column label="ExecType" min-width="40"
        ><template slot-scope="scope">
          {{ scope.row.execType === "title" ? "Title" : "Comment" }}
        </template>
      </el-table-column>
      <el-table-column label="Status" min-width="40">
        <template slot-scope="scope">
          <el-tag
            :type="scope.row.status | statusFilter"
            effect="dark"
            size="small"
            >{{ scope.row.status }}</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column label="ExecAt" min-width="70">
        <template slot-scope="scope">
          <i class="el-icon-time"></i>
          <span style="margin-left: 10px">{{
            scope.row.execAt | displayTime()
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="FinishAt" min-width="70">
        <template slot-scope="scope">
          <i class="el-icon-time"></i>
          <span style="margin-left: 10px">{{
            scope.row.finishAt | displayTime()
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Download" min-width="40">
        <template slot-scope="scope">
          <a :href="scope.row.fileUrl | ossUrl">
            <el-button
              class="excel-dl-btn"
              :disabled="!scope.row.fileUrl"
              icon="el-icon-download"
              circle
              size="small"
            ></el-button>
          </a>
        </template>
      </el-table-column>
      <el-table-column label="Delete" min-width="40">
        <template slot-scope="scope">
          <el-popconfirm
            title="Confirm delete?"
            @confirm="deleteTask(scope.row.id)"
          >
            <el-button
              type="danger"
              :disabled="
                scope.row.status === 'waiting' || scope.row.status === 'running'
              "
              icon="el-icon-delete"
              circle
              size="small"
              slot="reference"
            ></el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-show="total > 0"
      :total="total"
      :offset.sync="listQuery.offset"
      :limit.sync="listQuery.limit"
      layout="sizes, prev, pager, next, total "
      @pagination="fetchData"
    />
    <el-dialog
      :visible.sync="dialogNewVisible"
      @close="closeDialog"
      title="Create NLP Task"
      custom-class="nlp-task-dialog"
    >
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        size="small"
        label-width="120px"
      >
        <el-form-item label="Desc" prop="desc" required>
          <el-input v-model="form.desc"></el-input>
        </el-form-item>
        <el-form-item label="Exec Type" prop="execType" required>
          <el-radio-group v-model="form.execType">
            <el-radio label="title">Title</el-radio>
            <el-radio label="comment">Comment</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="Source File" required>
          <el-upload
            ref="upload"
            :action="aiApiRoot + '/v1/admins/nlp-task'"
            accept=".csv, .xlsx, .xls"
            :headers="{ Authorization: this.token }"
            :data="form"
            :on-success="onSuccess"
            :on-error="onError"
            :auto-upload="false"
            :multiple="false"
          >
            <el-button size="small" slot="trigger" icon="el-icon-upload2"
              >Click to Upload</el-button
            >
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button size="small" @click="closeDialog">Cancel</el-button>
        <el-button size="small" type="primary" @click="submitTask"
          >OK</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      :visible.sync="dialogDictVisible"
      @close="closeDictDialog"
      title="NLP Dict"
      custom-class="nlp-task-dialog"
    >
      <el-form
        ref="dictform"
        :model="dictform"
        :rules="rules"
        size="small"
        label-width="120px"
      >
        <el-form-item label="Type" prop="dictType" required>
          <el-radio-group v-model="dictform.dictType">
            <el-radio label="add">Add</el-radio>
            <el-radio label="cmw">CMW(Add)</el-radio>
            <el-radio label="remove">Remove</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="File" required>
          <el-upload
            ref="dictupload"
            :action="
              aiApiRoot +
              '/v1/admins/nlp-task-dict/' +
              dictform.dictType +
              '/import'
            "
            accept=".csv, .xlsx, .xls"
            :headers="{ Authorization: this.token }"
            :data="form"
            :before-upload="onDictBeforeUpload"
            :on-success="onDictSuccess"
            :on-error="onDictError"
            :auto-upload="true"
            :multiple="false"
          >
            <el-button
              type="primary"
              size="small"
              slot="trigger"
              icon="el-icon-upload2"
              style="margin-right: 10px"
              >Upload</el-button
            >
            <el-button
              type="primary"
              icon="el-icon-download"
              @click="clickDictDownload"
              >Download</el-button
            >
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button type="primary" size="small" @click="closeDictDialog"
          >Close</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { fetchNLPTask, deleteNLPTask } from "@/api/admin";
import Pagination from "@/components/Pagination";
import { getToken } from "@/utils/auth";
import { downloadFileAuth } from "@/utils/download-file";
import { aiApiRoot } from "@/utils/server";

export default {
  components: { Pagination },
  data() {
    return {
      token: "Bearer " + getToken(),
      aiApiRoot,
      dialogNewVisible: false,
      dialogDictVisible: false,
      form: null,
      dictform: null,
      rules: {
        desc: [{ required: true }],
        execType: [{ required: true }],
      },
      tableData: [],
      listQuery: {
        offset: 0,
        limit: 10,
      },
      total: 0,
      loading: false,
      dataLoading: false,
    };
  },
  filters: {
    statusFilter(status) {
      const statusMap = {
        waiting: "warning",
        running: "",
        done: "success",
        abort: "info",
        error: "danger",
      };
      return statusMap[status];
    },
  },
  created() {
    this.initForm();
  },
  methods: {
    initForm() {
      this.form = {
        desc: "",
        execType: "title",
      };
      this.dictform = {
        dictType: "add",
      };
    },
    active() {
      this.fetchData();
      this.initForm();
    },
    closeDialog() {
      this.dialogNewVisible = false;
    },
    closeDictDialog() {
      this.dialogDictVisible = false;
    },
    fetchData() {
      this.dataLoading = true;
      fetchNLPTask(this.listQuery)
        .then((response) => {
          if (response.success) {
            this.tableData =
              response.result && response.result.items
                ? response.result.items
                : [];
            this.total = response.result.totalCount;
          }
        })
        .finally(() => {
          this.dataLoading = false;
        });
    },
    submitTask() {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }
        this.$refs.upload.submit();
        this.loading = true;
      });
    },
    deleteTask(id) {
      this.dataLoading = true;
      deleteNLPTask(id)
        .then((response) => {
          this.dataLoading = false;
          if (response.success) {
            this.$notify({
              title: "Delete successful",
              type: "success",
            });
            this.fetchData();
          }
        })
        .catch(() => {
          this.dataLoading = false;
        });
    },
    onSuccess(response) {
      this.loading = false;
      if (response.success) {
        this.$refs.form.resetFields();
        this.$refs.upload.clearFiles();
        this.dialogNewVisible = false;
        this.$notify({
          title: "Create successful",
          type: "success",
        });
        this.fetchData();
      } else {
        this.$message({
          showClose: false,
          message: response && response.error ? response.error.message : "",
          type: "error",
        });
      }
    },
    onError(err) {
      this.loading = false;
      this.$refs.upload.clearFiles();
      if (err && err.message) {
        let res = JSON.parse(err.message);
        if (res && res.error && res.error.message) {
          this.$message({
            showClose: false,
            message: res.error.message,
            type: "error",
          });
          return;
        }
      }
      this.$message({
        showClose: false,
        message: err,
        type: "error",
      });
    },
    onDictBeforeUpload() {
      this.loading = true;
    },
    onDictSuccess(response) {
      this.loading = false;
      if (response.success) {
        this.$refs.dictupload.clearFiles();
        this.$notify({
          title: "Upload successful",
          type: "success",
        });
      } else {
        this.$message({
          showClose: false,
          message: response && response.error ? response.error.message : "",
          type: "error",
        });
      }
    },
    onDictError(err) {
      this.loading = false;
      this.$refs.dictupload.clearFiles();
      if (err && err.message) {
        let res = JSON.parse(err.message);
        if (res && res.error && res.error.message) {
          this.$message({
            showClose: false,
            message: res.error.message,
            type: "error",
          });
          return;
        }
      }
      this.$message({
        showClose: false,
        message: err,
        type: "error",
      });
    },
    clickDictDownload() {
      const loading = this.$loading({
        lock: true,
        text: this.$t("common.preparingData"),
      });
      downloadFileAuth(
        aiApiRoot +
          "/v1/admins/nlp-task-dict/" +
          this.dictform.dictType +
          "/export",
        "nlp-dict-" + this.dictform.dictType + ".xlsx",
        getToken(),
        () => {
          loading.close();
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.nlp-task {
  .new-btn {
    width: 100%;
    margin-bottom: 10px;
  }

  /deep/ .nlp-task-dialog .el-form {
    margin-top: 10px;
    margin-bottom: -10px;
  }
}
</style>