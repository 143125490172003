<template>
  <div class="gpt" v-loading="dataLoading">
    <el-card class="gpt-card" shadow="never">
      <div slot="header" class="clearfix">
        <span>Prompt</span>
        <el-button class="top-botton" type="primary" size="small" icon="el-icon-check"
          @click="setSettings">Save</el-button>
        <el-button class="top-botton top-next-botton" type="" size="small" icon="el-icon-refresh"
          @click="fetchSettings">Fresh</el-button>
      </div>
      <el-form class="gpt-form" ref="form" label-width="120px" :model="form" size="mini">
        <el-form-item label="Basic">
          <el-input type="textarea" v-model="form.basic.value" autosize></el-input>
        </el-form-item>
        <el-form-item label="Negative">
          <el-input type="textarea" v-model="form.negative.value" autosize></el-input>
        </el-form-item>
        <el-form-item class="no-bottom-margin" label="Pose">
          <el-button icon="el-icon-plus" size="mini" type="primary" plain circle class="add-botton"
            @click="addKey('pose')">
          </el-button>
          <el-form-item v-for="item in form.pose" :key="item.key">
            <el-col :span="1">
              <el-button icon="el-icon-minus" size="mini" type="danger" plain circle style="display: block"
                @click="removeOption({ 'type': 'pose', key: item.key, subKey: item.subKey, thirdKey: item.thirdKey, fourthKey: item.fourthKey })">
              </el-button>
            </el-col>
            <el-col class="row-input" :span="23">
              <el-input v-model="item.value">
                <template slot="prepend">{{ item.key }}</template>
                <el-button slot="append" icon="el-icon-user" @click="openUsersDialog(item)">
                  {{ item.userIds ? item.userIds.length : '' }}
                </el-button>
              </el-input>
            </el-col>
          </el-form-item>
        </el-form-item>
        <el-form-item class="no-bottom-margin" label="Sex">
          <el-button icon="el-icon-plus" size="mini" type="primary" plain circle class="add-botton"
            @click="addKey('sex')">
          </el-button>
          <el-form-item v-for="item in form.sex" :key="item.key + item.subKey">
            <el-col :span="1">
              <el-button icon="el-icon-minus" size="mini" type="danger" plain circle style="display: block"
                @click="removeOption({ 'type': 'sex', key: item.key, subKey: item.subKey, thirdKey: item.thirdKey, fourthKey: item.fourthKey })">
              </el-button>
            </el-col>
            <el-col class="row-input" :span="23">
              <el-input v-model="item.value">
                <template slot="prepend">{{ item.key }}</template>
                <el-button slot="append" icon="el-icon-user" @click="openUsersDialog(item)">
                  {{ item.userIds ? item.userIds.length : '' }}
                </el-button>
              </el-input>
            </el-col>
          </el-form-item>
        </el-form-item>
        <el-form-item class="no-bottom-margin" label="Age">
          <el-button icon="el-icon-plus" size="mini" type="primary" plain circle class="add-botton"
            @click="addKey('age')">
          </el-button>
          <el-form-item v-for="item in form.age" :key="item.key + item.subKey">
            <el-col :span="1">
              <el-button icon="el-icon-minus" size="mini" type="danger" plain circle style="display: block"
                @click="removeOption({ 'type': 'age', key: item.key, subKey: item.subKey, thirdKey: item.thirdKey, fourthKey: item.fourthKey })">
              </el-button>
            </el-col>
            <el-col class="row-input" :span="23">
              <el-input v-model="item.value">
                <template slot="prepend">{{ item.key + " - " + item.subKey }}</template>
                <el-button slot="append" icon="el-icon-user" @click="openUsersDialog(item)">
                  {{ item.userIds ? item.userIds.length : '' }}
                </el-button>
              </el-input>
            </el-col>
          </el-form-item>
        </el-form-item>
        <el-form-item class="no-bottom-margin" label="Style">
          <el-button icon="el-icon-plus" size="mini" type="primary" plain circle class="add-botton"
            @click="addKey('style')">
          </el-button>
          <el-form-item v-for="item in form.style" :key="item.key + item.subKey">
            <el-col :span="1">
              <el-button icon="el-icon-minus" size="mini" type="danger" plain circle style="display: block"
                @click="removeOption({ 'type': 'style', key: item.key, subKey: item.subKey, thirdKey: item.thirdKey, fourthKey: item.fourthKey })">
              </el-button>
            </el-col>
            <el-col class="row-input" :span="23">
              <el-input v-model="item.value">
                <template slot="prepend">{{ item.key + " - " + item.subKey }}</template>
                <el-button slot="append" icon="el-icon-user" @click="openUsersDialog(item)">
                  {{ item.userIds ? item.userIds.length : '' }}
                </el-button>
              </el-input>
            </el-col>
          </el-form-item>
        </el-form-item>
        <el-form-item class="no-bottom-margin" label="TrendPoint">
          <el-button icon="el-icon-plus" size="mini" type="primary" plain circle class="add-botton"
            @click="addKey('trendPoint')">
          </el-button>
          <el-form-item v-for="item in form.trendPoint" :key="item.key + item.subKey + item.thirdKey">
            <el-col :span="1">
              <el-button icon="el-icon-minus" size="mini" type="danger" plain circle style="display: block"
                @click="removeOption({ 'type': 'trendPoint', key: item.key, subKey: item.subKey, thirdKey: item.thirdKey, fourthKey: item.fourthKey })">
              </el-button>
            </el-col>
            <el-col class="row-input" :span="23">
              <el-input v-model="item.value">
                <template slot="prepend">{{ item.key + " - " + item.subKey + " - " + item.thirdKey }}</template>
                <el-button slot="append" icon="el-icon-user" @click="openUsersDialog(item)">
                  {{ item.userIds ? item.userIds.length : '' }}
                </el-button>
              </el-input>
            </el-col>
          </el-form-item>
        </el-form-item>
        <el-form-item class="no-bottom-margin" label="Place">
          <el-button icon="el-icon-plus" size="mini" type="primary" plain circle class="add-botton"
            @click="addKey('place')">
          </el-button>
          <el-form-item v-for="item in form.place" :key="item.key">
            <el-col :span="1">
              <el-button icon="el-icon-minus" size="mini" type="danger" plain circle style="display: block"
                @click="removeOption({ 'type': 'place', key: item.key, subKey: item.subKey, thirdKey: item.thirdKey, fourthKey: item.fourthKey })">
              </el-button>
            </el-col>
            <el-col class="row-input" :span="23">
              <el-input v-model="item.value">
                <template slot="prepend">{{ item.key }}
                </template>
                <el-button slot="append" icon="el-icon-user" @click="openUsersDialog(item)">
                  {{ item.userIds ? item.userIds.length : '' }}
                </el-button>
              </el-input>
            </el-col>
          </el-form-item>
        </el-form-item>
        <el-form-item class="no-bottom-margin" label="Season">
          <el-button icon="el-icon-plus" size="mini" type="primary" plain circle class="add-botton"
            @click="addKey('season')">
          </el-button>
          <el-form-item v-for="item in form.season" :key="item.key">
            <el-col :span="1">
              <el-button icon="el-icon-minus" size="mini" type="danger" plain circle style="display: block"
                @click="removeOption({ 'type': 'season', key: item.key, subKey: item.subKey, thirdKey: item.thirdKey, fourthKey: item.fourthKey })">
              </el-button>
            </el-col>
            <el-col class="row-input" :span="23">
              <el-input v-model="item.value">
                <template slot="prepend">{{ item.key }}
                </template>
                <el-button slot="append" icon="el-icon-user" @click="openUsersDialog(item)">
                  {{ item.userIds ? item.userIds.length : '' }}
                </el-button>
              </el-input>
            </el-col>
          </el-form-item>
        </el-form-item>
      </el-form>
    </el-card>
    <el-dialog :visible.sync="dialogVisible" @close="closeUsersDialog" title="Select Users" custom-class="add-dialog">
      <div class="user-container">
        <user-selection-multi ref="userSelection" :ids="currentUserIds" @select="selectUserId" />
      </div>
      <span slot="footer">
        <el-button size="small" @click="closeUsersDialog">Cancel</el-button>
        <el-button type="primary" size="small" @click="confirmUsers">OK</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  fetchGptSettings,
  setGptSettings,
  addGptSettingsType,
  deleteGptSettingsType,
  setGptSettingUsers
} from "@/api/admin";
import UserSelectionMulti from "./UserSelectionMulti";

export default {
  components: { UserSelectionMulti },
  data() {
    return {
      form: null,
      dataLoading: false,
      dialogVisible: false,
      currentId: 0,
      currentType: "",
      currentUserIds: []
    };
  },
  created() {
    this.clearForm();
  },
  methods: {
    clearForm() {
      this.form = {
        basic: {},
        negative: {},
        pose: [],
        sex: [],
        age: [],
        style: [],
        trendPoint: [],
        place: [],
        season: [],
      };
    },
    fetchSettings() {
      this.dataLoading = true;
      fetchGptSettings()
        .then((response) => {
          if (response.success) {
            this.clearForm();
            response.result.forEach((t) => {
              if (t.type === "basic") {
                this.form.basic = t;
              } else if (t.type === "negative") {
                this.form.negative = t;
              } else if (t.type === "pose") {
                this.form.pose.push(t);
              } else if (t.type === "sex") {
                this.form.sex.push(t);
              } else if (t.type === "age") {
                this.form.age.push(t);
              } else if (t.type === "style") {
                this.form.style.push(t);
              } else if (t.type === "trendPoint") {
                this.form.trendPoint.push(t);
              } else if (t.type === "place") {
                this.form.place.push(t);
              } else if (t.type === "season") {
                this.form.season.push(t);
              }
            });
          }
        })
        .finally(() => {
          this.dataLoading = false;
        });
    },
    setSettings() {
      let v = [];
      v.push(this.form.basic);
      v.push(this.form.negative);
      v = v.concat(this.form.pose);
      v = v.concat(this.form.sex);
      v = v.concat(this.form.age);
      v = v.concat(this.form.style);
      v = v.concat(this.form.trendPoint);
      v = v.concat(this.form.place);
      v = v.concat(this.form.season);
      this.dataLoading = true;
      setGptSettings({ data: v })
        .then((response) => {
          if (response.success) {
            this.$notify({
              title: "Update successful",
              type: "success",
            });
            this.fetchSettings();
          }
        })
        .finally(() => {
          this.dataLoading = false;
        });
    },
    active() {
      this.fetchSettings();
    },
    addKey(type) {
      this.$prompt('It takes effect immediately after confirm', 'Create Key', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning',
        inputValidator: (value) => {
          if (!value) {
            return "key can not be empty"
          }
        }
      }).then(({ value }) => {
        addGptSettingsType({ "type": type, "key": value }).then(response => {
          if (response.success) {
            this.$notify({
              title: "Create successful",
              type: "success",
            });
            this.fetchSettings();
          }
        })
      }).catch(() => {
      });
    },
    removeOption(data) {
      this.$confirm('It takes effect immediately after confirm', 'Delete this key?', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        deleteGptSettingsType(data).then(response => {
          if (response.success) {
            this.$notify({
              title: "Delete successful",
              type: "success",
            });
            this.fetchSettings();
          }
        })
      }).catch(() => {
      });
    },
    openUsersDialog(row) {
      this.currentId = row.id;
      this.currentType = row.type;
      this.currentUserIds = row.userIds ? row.userIds : [];
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.userSelection && this.$refs.userSelection.reopen();
      });
    },
    closeUsersDialog() {
      this.dialogVisible = false;
    },
    selectUserId(ids) {
      this.currentUserIds = ids;
    },
    confirmUsers() {
      this.dataLoading = true;
      setGptSettingUsers({ id: this.currentId, userIds: this.currentUserIds }).
        then(response => {
          if (response.success) {
            this.$notify({
              title: "Save successful",
              type: "success",
            });
            this.form[this.currentType].map((t, idx) => {
              if (t.id === this.currentId) {
                this.form[this.currentType][idx].userIds = this.currentUserIds && this.currentUserIds.length > 0 ? this.currentUserIds : null;
              }
            })
            this.dialogVisible = false;
          }
        }).finally(() => {
          this.dataLoading = false;
        });
    }
  },
};
</script>

<style lang="scss" scoped>
.gpt {
  .gpt-card {
    margin-bottom: 10px;

    .top-botton {
      float: right;
      margin-top: -8px;
      margin-bottom: -8px
    }

    .top-next-botton {
      margin-right: 10px;
    }


    .gpt-form {
      margin-bottom: -10px;

      .add-botton {
        margin-bottom: 18px;
        display: block
      }

      .row-input {
        margin-top: 1px
      }

      .no-bottom-margin {
        margin-bottom: 0;
      }
    }
  }
}
</style>